@import "infrastructure/styles/theme";

.pl-grid .checkbox-style-wrapper {

  .checkbox {
    z-index:0;
    position: absolute;
    cursor: pointer;
    background-color: white;
    border: 1px solid $BorderColor13;
    width: 18px;
    height: 18px;
    text-align: center;
    margin-top: 4px;
    i {
      margin-top: 1px;
      display: block;
      color: $FontColor10;
    }
    &.disabled{
      margin-top: 10px;
      background-color: lightgray;
      cursor: default;
    }
  }

  .ag-selection-checkbox {
    opacity:0;
    position:absolute;
    z-index:1;
    width: 100%;
    height: 100%;
    left: 0;
    vertical-align: top;
    top:0;

    img {
      vertical-align: top;
      width:inherit;
      height:inherit;
    }

    .ag-checkbox.ag-input-field {
      height: 100%;
      width: 100%;
      .ag-checkbox-input-wrapper {
        flex: 1 1 auto;
        height: 100%;
        .ag-input-field-input.ag-checkbox-input {
          height: 100%;
        }
      }
    }
  }

  .ag-cell-wrapper {
    align-items: start;
    .ag-selection-checkbox {
      height: 100% ;
    }
  }


  .ag-cell-value {
    width: 18px;
    height: 18px;
    display: inline-block;
    .ag-react-container {
      position: absolute;
      margin-top: -5px;
      width: 18px;
      height: 18px;
    }
  }
}

