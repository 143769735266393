@import "infrastructure/styles/theme";

.change-password-page{
  .headerMessage{
    text-align: left;
  }
  .change-password-error {
    text-align: left;
    height: 0;
    opacity: 0;
    color: $FontColor20;
    transition: margin .3s, height .3s, opacity .3s;

  }

  .login-row{
    display: inline-block;
  }

  .change-password-error {
    text-align: left;
    height: 0;
    opacity: 0;
    color: $FontColor20;
    transition: margin .3s, height .3s, opacity .3s;
    margin-left: 46px;

  }
  .btn.btn-primary{
    transition: margin .3s;
    margin-top: 30px;
  }
  &.has-error {
    .btn.btn-primary{margin-top:0px; }
    .change-password-error {
      margin-top: 17px;
      height: 43px;
      opacity: 1;
    }
  }
}


