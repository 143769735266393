@import "infrastructure/styles/theme";


.time-value-wrapper{
  &.disabled{
    background: $BGColor11;
  }
  border: 1px solid $BorderColor8;
  background: $BGColor2;
  display: inline-block;
  vertical-align: middle;

  span, input {
    height: 34px;
  }

  .time-separator{
    line-height: 34px;
  }

  .inner {
    border: none;
    input {
      padding: 6px 5px;
    }
  }

  $innerHoursWidth: 40px;
  $innerMinutesWidth: 35px;
  .days {
    width: 40px;
    text-align: center;
  }
  .hours {
    width:  $innerHoursWidth;
    text-align: center;
  }
  .minutes {
    width:  $innerMinutesWidth;
    text-align: center;
    &.count {
      width: 47px;
    }
  }
  width: calc(#{$innerHoursWidth} + #{$innerMinutesWidth} + 10px);
}
