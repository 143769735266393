@import 'infrastructure/styles/theme';

.dialog {

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: unset;       //NOTE: workaround for bootstrap 5.3.3 to open dialogs with specified width
      width: var(--bs-modal-width);
    }
  }
  @media (min-width: 768px) {
    .modal-dialog {
      width: 600px;
    }
  }

  &.modal {
    text-align: center;

    z-index: 40000;

    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -4px;
    }
  }

  @media (hover:none), (pointer:coarse) {  //For Tablet (touch screen) - move all dialogs left
    .modal-dialog {
      margin-left: 66px;
    }
    &.modal {
      text-align: start;
      &.message-dialog {    //The confirmation dialogs open in the center
        text-align: center;
      }
    }

  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;

    .modal-dialog-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0.35;
      z-index: 99;
      background: #fbfbfb;
      display: none;
      &.active {
        display: block;
      }
    }

  }


  .modal-header {
    display: flex;
    justify-content: space-between;
    border-radius: 0;
    background-color: $BGColor1;
    padding:0 10px 0 20px;
    .more-info-text{
      font-size:14px;
      margin-left:18px;
    }
    .additional-text-header{
      font-size:15px;
      margin-right: 5px;
      line-height: 53px;
      float: right;
    }

    .close {
      color: $FontColor3;
      font-weight: normal;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
      text-align: center;
      width: 30px;
      border: 1px solid transparent;

      &:hover{
        border: 1px solid $BorderColor11;
      }
      &:active{
        color : $Color2;
        border: 1px solid $Color2;
        background: #202020;
      }
      &:focus{outline: none;}
    }
  }
  .modal-title{
    color:  $FontColor3;
    font-size: $Dialog_FontSize1;
    font-weight: 500;
    line-height:51px;
  }

  .modal-content{
    border-color: $BorderColor6;
    background-color:  $BGColor10;
    border-width: 2px;
    box-shadow:none;
    border-radius:0;
  }

  .validation-area {
    padding: 10px 0 15px 15px;
    border-top: 1px solid #4ea5b5;

    .validation-area-title {
      padding-left: 14px;
      font-weight: bold;
      span:first-child {
        margin-right: 5px;
      }
    }
    .validation-area-content {
      padding: 5px 25px 0 15px;
      max-height: 95px;
      overflow: auto;
      > div {
        overflow-wrap: break-word;
      }
      > div:not(:last-child) {
        margin-bottom: 5px;
      }
    }
    .error-message{
      padding-left: 5px;
    }

    .error-color {
      color: $IconColor3;
    }
    .warning-color {
      color: $IconColor4;
    }
  }

  .modal-footer{
    border-top:1px solid $BorderColor6;
    height:59px;
    padding-top:13px;
    box-sizing: border-box;
    padding-bottom:12px;
    text-align:left;
    background-color:$BGColor10;

    .model-footer-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0;
    }

    .left-section{
      line-height:32px;
    }
    .right-section{
      button{margin-left:7px;}

      .checkbox-right-section {
        display: inline-block;

        .popover-target span{
          margin: 0 10px;
          font-size: 16px;
        }

        >label>span{
          display: inline-block;
          margin-left: 10px;
          color:$FontColor4;
          line-height: 16px;
          height: 15px;
          vertical-align: middle;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .popover-target {
        .alert-icon {
          font-size: 20px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    .btn.btn-primary{
      height:32px;
      line-height:32px;
    }

    .floating-center-text{
      >label{
        color:$FontColor4;
        max-width:240px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: bottom;
      }

      &.disabled {
        pointer-events: none;
      }
    }

    .footer-validation-checkbox {
      display: inline-block;
      margin-left: 20px;
      .checkbox-label {
        margin-left: 5px;
      }
    }
  }


  /************************************************/
  // Footer with support for double-line buttons.
  &.double-footer-dialog {
    .modal-dialog {
      .modal-footer {
        height: 60px;
        padding: 10px 9px 9px 14px;

        .left-section {
          line-height: 40px;
        }

        .floating-center-text label {
         /* top: -29.5px;*/
        }

        button.btn.btn-primary {
          height: 40px;
        }

        // Double Liner button.
        .right-section .btn-primary.multiline {
          white-space: pre;
          line-height: 1.1;
          margin-left: 8px;
        }
      }
    }
  } // END &.double-footer-dialog





  ////////////////////////////////reusable classes



  .modal-body{
    $headingColor: $FontColor17;
    padding: 29px 29px 29px 29px;
    .title{
      color: $headingColor;
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      text-align: left;
      margin:0;
    }

    &.modal-body-overflow {
      overflow-y: auto;
      overflow-x: hidden;
    }



    .input-section{
      width:100%;
      display:inline-block;
      vertical-align:top;

      //Margins
      margin-top:18px; //default
      &.no-margin{margin-top:0;}


      //Add this when input section have 2 fields that need to display on the same row.
      &.two-columns{
        >*{
          width:47.5%;
        }
        &:first-child{margin-right:5%;}
      }

      //Input Customization Classes
      &.inline{
        //default
        width:50%;
        &.small{
          width:33%;
        }
        &.big{
          width:67%;
        }
        //add margin & remove size from inputs
        &.right-side, &.left-side{
          width:calc(50% - 15px);
          &.small{
            width:calc(33% - 15px);
          }
          &.big{
            width:calc(67% - 15px);
          }
        }
        &.right-side {
          margin-left: 30px;
        }

      }

      // Empty invisible section which takes up standard height.
      &.spacer {
        height: 58px;
      }

      .full-width{
        display:block;
        width:100%;
      }
    }
  }



  //Global declaration, will be pass to the relevant component on the future.
  .PL-textField, .PL-textAreaField {width: 100%; >input{width:100%;}}
  .pl-combobox, .pl-text-field-auto-complete, .pl-datepicker , .PL-phoneField {width:100%;}

  //When there is more than 1 form section in the dialog.
  .dialog-item-section{
    border-bottom: 1px solid $BorderColor18;
    padding-bottom: 25px;
    margin-top: 25px;
    &:first-child{margin-top:0;}
    &:last-child {
      border-bottom: none;

      padding-bottom:0;
    }
  }
}



