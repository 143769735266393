.live-map-overlay-level-chooser {
  display: block;
  // background-color: aqua;
  background-color: transparent;
  // width: 200px;
  // height: 300px;
  
  // position: absolute;
  // top: 90px;
  // right: 24px;
  // z-index: 2;

  
  .live-map-overlay-level-chooser-buttons {
    display: flex;
    flex-direction: column;

    button {

      width: 112px;
      height: 38px;
      padding: 12px 34px 12px 24px;
      border-radius: 5px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px #707070;
      background-color: #403f40;

      margin-bottom: 16px;



      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #fff;


      &:active {
        background-color: #4da5b5;
      }

      &.current {
        background-color: #4da5b5;

        &:hover {
          background-color: #000000;
        }

        // &:focus {
        //   background-color: #80ff80;
        // }

      }

      &:hover {
        background-color: #000000;

        &:active {
          background-color: #4da5b5;
        }

      }

      // &:focus {
      //   background-color: #a9ffa9;
      // }

    }
  }

}


