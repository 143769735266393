@import "mat/styles/includes";

.report-maintenance-dialog {

  .modal-dialog {
    width: $dialog2ColumnsWidth;

    .modal-body {
      padding-bottom: 10px;
      .input-section {
        margin-top: 30px;
      }
      .input-section:first-child {
        margin-top: 0;
      }
      .comment-textField {
        width: 100%;
      }

      .pl-combobox .pl-select__control .pl-select__input > input {
        max-width: $comboboxInputWidth9;
      }

      .pl-combobox.pl-multi-select-field.entity-value-select {
        width: 544px;
        .select-value-row .extra-data{
          width: 320px;
        }
      }
      .time-value-wrapper{
        width:100%;
      }
      .padding-top {
        padding-top: 26px;
      }
    }
  }


}
