@import "mat/styles/includes";

.edit-assets-status-dialog {

  .modal-dialog {
    width: $dialog2ColumnsWidth;

    .modal-body {
      padding-bottom: 10px;
      .input-section {
        margin-top: 30px;
      }
      .input-section:first-child {
        margin-top: 0;
      }
      .comment-textField {
        width: 100%;
      }
      .edit-note {
        font-weight: bold;
        font-size: 14px;
        color: #707070;
        margin-top: 10px;
      }

      //to limit the input expanding when typing
      .pl-combobox .pl-select__control .pl-select__input > input {
        max-width: $comboboxInputWidth9;
      }
      .entities-multi-select .entity-type-select {
        width: 135px;
      }

      .pl-combobox.pl-multi-select-field.entity-value-select {
        .select-value-row {
          .extra-data, .icon{
            width: 0;
          }
        }
      }
    }
  }


}
