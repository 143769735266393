@import 'infrastructure/styles/theme';

.modal-open {
  .modal-backdrop {
    background-color: transparent;

    + .dialog {
      background-color: $BGColor21;
    }

    + .message-dialog {
      background-color: transparent;
    }
    + .message-dialog.mobile-message-dialog {
      background-color: $BGColor21;
    }
  }

  @media (hover:none), (pointer:coarse) {
    .modal-backdrop {
      height: 100dvh;
      width: 100dvw;
    }
  }


}

.message-dialog {

  @media (min-width: 768px) {
    .modal-dialog {
      width: 600px;    //NOTE: workaround for bootstrap 5.3.3 to open dialogs with specified width
    }
  }

  .modal-content {
    //min-height: 125px;
    max-height: 600px;
    background-color: white;
  }

  .modal-header {
    border: none;
    background-color: $BGColor2;
    height:66px;
    .modal-title{line-height:66px;}
    .close {
      color: $BGColor5;
      line-height:28px;
      &:hover {
        border: 1px solid $BGColor5;
      }
      &:active {
        color: $BGColor6;
        border: 1px solid $BGColor6;
        background: transparent;
      }
    }

    .header-icon {
      line-height:66px;
      margin-right: 10px;
      display: inline-block;
      color: $IconColor5;

      &.pl-error-icon {
        color: $IconColor3;
      }
      &.pl-warning-icon {
        color: $IconColor4;
      }
    }

    label {
      color: $FontColor15;
      font-size: $Dialog_FontSize1;
      font-weight: 700;
    }
  }

  .modal-body {
    background-color: $BGColor16;
    padding:28px 15px 18px 25px;
    >label{
      line-height:30px;
    }
    .buttons-container{
      position: relative;
      width: 100%;
      height:auto;
      display: block;
      bottom: 24px;
      text-align: right;
      margin-top: 25px;

      .btn{
        margin-bottom: -40px;
      }

      .btn-link{
        color: $Color2;
        text-transform: none;
        font-size: 16px;
        font-weight: 400;

      }

      .btn-default {
        margin-right: 20px;
      }
    }


    .line{
      margin-bottom:9px;
      .label{
        display: inline-block;
        width: 150px;
        vertical-align: top;
        font-size: 15px;
        font-weight: 400;
        color: $FontColor4;
        text-align:left;
      }
      .value{
        display: inline-block;
        width: 405px;
        vertical-align: top;
        font-size: 14px;
        font-weight: 500;
        color: $FontColor13;
        word-wrap: break-word;

        a {
          margin-right: 4px;
          font-size: 14px;
          font-weight: 500;
          color: $FontColor5;
        }
      }
    }
  }

  &.mobile-message-dialog {
    .modal-content {
      border: none;
    }
    .modal-dialog {
      margin: 15px;
    }

    .modal-header{
      min-height: 66px;
      height:auto;
      padding: 30px 20px;
    }
    .title {
      vertical-align: middle;
      line-height: 35px;
    }
    .close {
      display: none;
    }
    .modal-body{
      background: $BGColor2;
      padding: 0;
      border-top: 1px solid #F3F3F3;
      .buttons-container {
        text-align: center;
        bottom: 0;
        .btn {
          margin: 0;
          width: 50%;
          height: 100%;
          padding: 15px 0;
          border-right: 1px solid #F3F3F3;
        }
      }
    }
  }

  &.oneBackground{
    .modal-body{
      background: $BGColor2;
      padding: 0px;
    }
    .modal-header{
      min-height: 66px;
      height:auto;
    }
    .title{
      vertical-align: middle;
      line-height: 30px;
      max-width: calc(100% - 30px);
      padding-top: 10px;
      padding-bottom: 10px;

      a {
        font-size: 18px;
        line-height: 1px;
      }
    }
    .btn-link {
       margin: -10px 5px 0 5px;
    }
  }


  .globalMessageDialog{
    .modal-body {
      background-color: $BGColor16;
      padding: 28px 15px 18px 28px;
    }
  }


  .multistatus-line {
    margin-bottom: 10px;
    margin-left: 20px;
    &:last-child{margin-bottom:0;}

    .line-icon, .line-count, .line-explanation{line-height:22px;}
    .line-icon {
      display: inline-block;
      width:  23px;
      // height: 23px;
      font-size: 18px;
      line-height: 23px;
    }

    .line-count {
      display: inline-block;
      font-size: 15px; font-weight: bold;
      vertical-align: top;
      margin-left: 20px;
    }

    .line-explanation {
      display: inline-block;
      font-size: 15px; font-weight: bold;
      vertical-align: top;
      margin-left: 5px;
    }


    &.failed {
      .line-icon { color: $BGColor19; }
    }
    &.warning {
      .line-icon { color: $IconColor4; }
    }
    &.succeeded {
      .line-icon { color: $FontColor5; }
    }
    &.reportedCuts {
      .line-icon { color: $FontColor5; }
    }


  }

  .message-row {
    margin: 0 0 20px 20px;

    span {
      display: inline-block;
      line-height: 22px;
      font-size: 16px; font-weight: bold;
      margin-right: 10px;

      &.pl-check-circle-icon-blue {
        color: $FontColor5;
        font-size: 18px;
      }
      &.pl-error-icon {
        color: $BGColor19;
        font-size: 18px;
      }
    }
  }

  .message-area {
    padding: 0 25px 0 50px;
    max-height: 200px;
    margin-bottom: 30px;
    overflow: auto;
    > div {
      overflow-wrap: break-word;
    }
    > div:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}


// TODO: Maybe move this to Message Dialog styles.
.message-dialog {



}
