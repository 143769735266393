@import "mat/styles/includes";

.create-sensor-dialog{
  .modal-dialog {
    width: $dialog2ColumnsWidth;
    .time-value-wrapper{
      width: 105px;
    }
    .alert-icon.info{
     //color:black;
    }
  }
}
