@import "mat/styles/includes";

.last-operation  {
  .title{
    span:first-child {
      margin-right: 10px;
      line-height: 30px;
    }
    .description{
     max-width: calc(100% - 25px);
    }
  }

  .info{
    color: $FontColor14;
    font-size: 14px;
    line-height: 14px;
  }
}
