@import "infrastructure/styles/generic";
@import "infrastructure/styles/theme";

.selectAll {
  margin-top: 10px;
  color:  $FontColor5;

  label {
    font-family: $FontFamily1;
    font-size: 14px;/* Approximation due to font substitution */
    font-weight: 700;
    line-height: 30px;/* Approximation due to font substitution */
    text-align: left;
  }

  .selectAllLabel {
    padding-left: 14px;
  }

  input[type="checkbox"] + label{
    border-color: $FontColor5 !important;
  }
}
