@import 'mat/styles/includes';

.defrosting-range-list-item {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1/3;

  .defrosting-range-list-item_quantities {
    display: flex;
  }

  .pl-combobox {
    width: 180px;
  }

  .time-value-wrapper {
    width: 105px;
  }
}
