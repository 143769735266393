@import "mat/styles/includes";

.related-asset-cell  {
  height: 30px;
  @include flex-row();
  align-items: center;

  span:not(:last-child) {
    margin-right: 10px;
  }

  span.pl {
    flex: 0 0 15px;
  }

  .popover-target {
    height: 15px;
    margin-right: 10px;
  }

  .inactive {
    color: $FontColor2;
  }
}
