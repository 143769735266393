@import "mat/styles/includes";

.asset-page {
  height:100%;

  .common-header {
    float: right;
    margin: 11px 35px 0 0;
  }
}
