@import "infrastructure/styles/theme";

.pl-combobox {
  display:inline-block;
  vertical-align:top;
  height:36px;

  .pl-select__control {
    min-height:36px;
    height:36px;
    border-style: solid;
    border-width: 1px;
    border-color:  $BorderColor8;
    box-sizing: border-box;
    background-color: $BGColor2;
    width: 100%;
    border-radius:0;
    box-shadow:none;
    font-size: 14px;
    color: $FontColor11;

    &.pl-select__control--is-focused {
      border-color:$BorderColor6;
    }

    &.pl-select__control--is-disabled {
      .pl-select__placeholder {
        color: $FontColor2;
      }
      .pl-select__dropdown-indicator svg {
        color: $FontColor2;
      }
      .pl-select__single-value--is-disabled {
        pointer-events: auto;
      }
    }

    &:hover {
      border-color:  $BorderColor6;
      .pl-select__dropdown-indicator svg {
        background: $BGColor9;
      }

      &.pl-select__control--is-disabled {
        border-color:  $BorderColor8;

        .pl-select__dropdown-indicator svg {
          background: $BGColor2;
        }
      }
    }

    .pl-select__indicator.pl-select__dropdown-indicator {
      color: $FontColor10;
    }

  }

  &.hide-clear-indicator {
    .pl-select__indicators {
      .pl-select__clear-indicator {
        display: none;
      }
    }
  }

  .pl-select__value-container {
    max-height: 150px;
    overflow-x: hidden;
    overflow-y: auto;

    .pl-select__single-value {
      color: $FontColor11;

      &.pl-select__single-value--is-disabled {
        color: $FontColor2;
      }
    }

    .pl-select__multi-value {
      border: 1px solid hsla(200,5%,56%,.26);
      box-sizing: border-box;
      background-color: $BGColor10;
      border-radius: 0;
      color: #707070;
      height: 23px;


      .pl-select__multi-value__label {
        font-size: 100%;
        color: $FontColor11;
      }

      &.pl-select__multi-value--is-disabled {
        .pl-select__multi-value__label {
          color: $FontColor2;
        }
      }
    }
  }

  .pl-select__menu {
    z-index: 1000;
    margin-top: 0;
    margin-bottom: 0;

    .pl-select__menu-list {
      max-height: 130px;
      padding-left: 5px ;
      padding-right: 5px;
      overflow-x: hidden;

      .pl-select__option {
        color:$BGColor3;
        padding: 5px 10px;

        &.pl-select__option--is-selected {
          background-color: transparent;
        }
        &.pl-select__option--is-focused {
          background-color: $BGColor10;
        }
        &.pl-select__option--is-disabled {
          color:$FontColor2;
        }

      }
    }
  }

  //&.invalid {
  //
  //  .pl-select__control {
  //    &, &:focus {
  //      border-color: $BorderColor7;
  //    }
  //  }
  //
  //}

}

//to limit the input expanding when typing
.pl-combobox .pl-select__control .pl-select__input > input {
  max-width: $comboboxInputWidth4;
}











