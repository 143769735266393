// @import "infrastructure/styles/theme";
@import "../../../../../../infrastructure/styles/theme.scss";




/////////////////////////////////////////
// Map Tooltips default overrides
//
.livemap-page {

  // Built in tooltip wrapper override
  .mappedin-tooltip>div {
    border-radius: 4px;
    border: 1px solid #CFCFCF;

    // box-shadow: 0 2px 11px #0003;
    box-shadow: 0 2px 11px rgba(0, 0, 0, 0.1);

  }

  // Built in tooltip anchor override
  .mappedin-tooltip:before {
    z-index: 3;
  }

}



////////////////////////////
// Map Message Tooltip
//
.livemap-page {

  .messageTooltip {
    display: inline-block;
    padding: 2px 10px;

    font-size: 15px;
    font-weight: normal;
    font-family: Roboto;

  }

}



/////////////////////////////////////////
// Map Station Tooltip
//
.livemap-page {

  .stationTooltip {
    padding: 0;
    font-size: 15px;
    font-weight: normal;
    font-family: Roboto;

    background-color: #FFFFFF;



    .tooltip-header {
      // padding-bottom: 5px;

      padding-top: 5px;
      padding-left: 20px;
      line-height: 36px;


      i.tooltip-header-icon {
        // display: inline-block;
        // font-size: 16px;
        // // width: 20px;
        // // height: 20px;

        display: inline-block;
        line-height: inherit;
        font-size: 18px;

      }


      .tooltip-header-icon.pl.pl-section-sm {
        font-size: 24px;
        line-height: 1;

        display: inline-block;

        position: relative;
        top: 2px;
        left: -3px;

        width: 20px;

      }

      .tooltip-header-title {
        display: inline-block;
        // max-width: 190px;

        margin-left: 10px;
        // margin-right: 10px;


        font-family: Roboto;
        line-height: inherit;
        font-size: 18px;
        font-weight: 700;

        vertical-align: middle;

        a {
          color: $FontColor17;
        }

      }

      i.tooltip-header-alert-icon {
        display: block;
        float: right;

        margin-top: 2px;
        margin-right: 10px;
        margin-left: 15px;

        font-size: 16px;
        line-height: inherit;


      }


      i.pl-error-icon {
        color: $IconColor3;
      }

      i.pl-warning-icon {
        color: $IconColor4;
      }

    }





    .tooltip-header-hr {
      margin: 0;
      padding: 0;

      border: none;
      border-top: 1px solid #eee;

      background-color: $Color2;
      height: 2px;

      // position: absolute;
      // left: 0px;
      width: 100%;

    }

    .tooltip-body {
      font-weight: normal;
      // padding: 20px 20px 0 20px;
      padding: 20px 30px 0 20px;


      .tooltip-data-line {


        height: 37px;

        .tooltip-data-line-icon {
          display: inline-block;
          line-height: 36px;
          vertical-align: middle;

          width: 17px;
          text-align: center;

          // line-height: 24px;
          // vertical-align: bottom;
        }

        .tooltip-data-line-value {
          display: inline-block;

          color: $FontColor17;
          font-size: 24px;
          font-weight: 400;
          line-height: 18px;

          text-align: center;
          min-width: 40px;

          vertical-align: middle;
          // vertical-align: bottom;
          margin-left: 5px;

          // line-height: 24px;

        }

        .tooltip-data-line-label {
          display: inline-block;

          color: $FontColor17;
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          // line-height: 18px;

          display: inline-block;
          // width: 110px;

          vertical-align: middle;
          // vertical-align: bottom;
          margin-left: 5px;
          margin-top: 5px;



        }


      }

    }


    .tooltip-footer-hr {
      margin: 0;
      padding: 0;

      border: none;
      border-top: 1px solid #eee;
      // border-top: 1px solid #eee;

      height: 1px;

      // position: absolute;
      // left: 0px;
      width: 100%;

    }

    .tooltip-footer {
      margin-top: 10px;
      padding: 0 20px 0px 20px;
      height: 50px;

      .activity-type {
        display: flex;
        flex-direction: row;
        align-items: center;

        font-size: 14px;
        font-weight: normal;

        .icon {
          display: inline-block;
          margin-right: 5px;
          width: 12px;
          font-size: 12px;
          line-height: 17px;
          vertical-align: top;
        }

      }

      .second-row {
        display: flex;
        flex-direction: row;
        flex: 1;
        margin-left: 15px;
        margin-top: 5px;
        height: 20px;
        color: #707070;
        font-size: 12px;
        line-height: 20px;

        .asset-with-icon {
          display: inline-block;
          border-left: 1px solid #cfcfcf;
          height: 18px;
          margin-left: 10px;

          .pl {
            display: inline-block;
            margin-right: 6px;
            margin-left: 10px;
            width: 12px;
            font-size: 12px;
            line-height: 21px;
            vertical-align: top;
          }
        }
      }




    }

    // END .tooltip-body

  }


}

