@import "infrastructure/styles/theme.scss";

div.mat-settings-page > div.administration-page > div.ip-whitelist-view-container {
  .common-header {
    margin: 15px 0;
  }
  div.pl-checkbox {
    margin-top: 20px;

    label.checkbox-label {
      font-weight: bold;
    }
  }

  div.ip-whitelist-view-content {
    margin: 0 30px;

    div.ip-list-container {
      margin-top: 20px;

      div.list-header {
        //background-color: $BGColor15;
        background-color: #ddd;
        width: calc(100% - 15px);
        height: 37px;
        padding-left: 10%;
        padding-top: 8px;
        margin-bottom: 10px;
      }

      div.ip-list-item {
        width: 100%;
        //margin-top: 5px;

        .ip-list-item-input {
          width: 100%;
        }
      }
    }
  }
}
