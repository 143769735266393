

.week-nav-container {
  display: flex;
  flex-direction: column;

  .week-dates {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
  }

  .week-nav-buttons {
    display: flex;
    justify-content: space-between;
  }
}
