@import "../../../../styles/includes";

.pick-list-assets {
  height: calc(100% - 162px);
  padding: 20px 0 0;

  .picklist-details-section{
    background-color: $BGColor2  !important ;
    display: table;
    width: 100%;
    padding: 20px 0px;
  }


  hr{
    margin-top: 20px;
    border-top: 1px dashed $BGColor29;
  }

  .value {
    >span:not(:last-child) {
      margin-right: 15px;
    }
  }
  .popover-target {
    display: inline-block;
  }

}












