@import "mat/styles/includes";

.top-indication-bar-wrapper {
  position: fixed;
  width: 100%;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  height:100%;
  background:rgba(255,255,255, 0);
  z-index:9999;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  .top-indication-bar{
    color: $FontColor1;
    height: 0px;
    background: $BGColor36;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    line-height: 25px;
    label {
      margin-left: 7px;
      font-weight: bold;
    }

    i, label{
      line-height: 24px;
      vertical-align: middle;
    }
    i{font-size:13px;}
  }

  &.on {
    visibility: visible;
    opacity: 1;
    background:$BGColor34;
    .top-indication-bar{
      height: 25px;

      .btn-default {
        margin-left: 15px;
      }
    }
    label {
      -webkit-animation: breathing 2s ease-out infinite normal;
      animation: breathing 2s ease-out infinite normal;
    }
  }

  &.bar-only {
    height: auto;
    .top-indication-bar {
      background: $BGColor19;
    }
  }

  .upgrade-link{
    color: white;
  }
}

@-webkit-keyframes breathing {
  $scaleMin: 0.95;
  $scaleMax: 1;
  0% {
    -webkit-transform: scale($scaleMin);
    transform: scale($scaleMin);
  }

  25% {
    -webkit-transform: scale($scaleMax);
    transform: scale($scaleMax);
  }

  60% {
    -webkit-transform: scale($scaleMin);
    transform: scale($scaleMin);
  }

  100% {
    -webkit-transform: scale($scaleMin);
    transform: scale($scaleMin);
  }
}

@keyframes breathing {
  $scaleMin: 1;
  $scaleMax: 1.03;
  0% {
    -webkit-transform: scale($scaleMin);
    -ms-transform: scale(0.5);
    transform: scale($scaleMin);
  }

  25% {
    -webkit-transform: scale($scaleMax);
    -ms-transform: scale($scaleMax);
    transform: scale($scaleMax);
  }

  60% {
    -webkit-transform: scale($scaleMin);
    -ms-transform: scale($scaleMin);
    transform: scale($scaleMin);
  }

  100% {
    -webkit-transform: scale($scaleMin);
    -ms-transform: scale($scaleMin);
    transform: scale($scaleMin);
  }
}
