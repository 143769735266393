@import "infrastructure/styles/theme";

.pl-grid {
  height: calc(100% - 50px);
  width: 100%;
  font-family: $FontFamily1;
  position: relative;

  .ag-cell-wrapper {
    height: 100%;
  }

  .ag-tabs.ag-menu.ag-focus-managed.ag-ltr.ag-popup-child {
    min-width: 2px;  //NOTE: workaround - fix the grid's filters position (ag-grid ver 24.1.0)
  }

  .ag-filter .ag-react-container {
    width: 12px;  //NOTE: workaround - fix the grid's filters position (ag-grid ver 24.1.0)

    //height: 1px;  //workaround: fix the grid's filters position
    //height: 10px;   //for test only
    //background-color: red; //for test only
  }

  //.ag-header-icon.ag-header-cell-menu-button { //for test only
  //  width: 15px;
  //  height: 15px;   //for test only
  //  background-color: green; //for test only
  //}

  //workaround: to avoid the grid's filters clipping
  .ag-root-wrapper.ag-layout-normal {
    overflow: visible;
  }
  .ag-root-wrapper {
    border: 1px solid $BorderColor1;
  }

  >div{
    vertical-align: top;
    display:inline-block;
    width:inherit;
  }

  .text-overlay{
    height: calc(100% - 54px);
    top: 47px;
  }

  .ag-root {

    .ag-header {
      .ag-header-row {
        .ag-header-cell {
          display: inline-block;
          .ag-header-cell-comp-wrapper {
            display: block;
          }
        }
      }
    }

    .ag-row {
      .ag-cell {
        user-select: text;
        border-top: none;
        border-left: none;
        border-right: 1px solid $BorderColor2;
        border-bottom: 1px solid $BorderColor2;

        padding-left: 20px;
        font-size: $h4;
        line-height: 64px;
        /*&:last-child{border-right:0;}*/
        .ag-react-container span {
          vertical-align: middle;
        }

        &.editableMultiCell .ag-react-container{
          position: relative;
          z-index: unset;
        }

        &.editableCell .ag-react-container{
          position: relative;
          z-index: -1;
        }

        &.checkbox-style-wrapper  {
          //align-items: start;
          line-height: 24px !important;
          padding: 20px;
        }
        &.ag-cell-focus {
          border-color: $BorderColor2 !important;
        }
        &.ag-cell-inline-editing {
          border-color: $BorderColor2 !important;
        }
      }
    }
  }
  .ag-header{
    line-height:46px;
    border-bottom:3px solid $BorderColor6;
  }

  .ag-body-container{
    height:100%;
  }
  .ag-header-row{
    &:last-child{
      border-bottom:0;
    }
  }

  .ag-header-cell{
    @include gradient($BGColor17, $BGColor18);
    font-size:$h4;
    text-transform:uppercase;
    letter-spacing: 0.25px;
    text-align: left;
    border-right: 1px solid $BorderColor19;
    outline: none;

    &:hover {
      @include gradient($BGColor23, $BGColor24);
    }

    .pl-checkbox, .ag-header-cell-text {
      margin-left: 20px;
    }
  }

  .ag-icon-tree-closed {
    background: transparent
    url(../../../assets/svg/arrow-right.svg)
    center no-repeat;
    background-size: 16px 16px;
    height: 12px;
    width: 12px;
    margin-left: -20px;
    margin-top: 26px;
    padding-right: 20px;
    &:before {
      display: none;
    }
  }

  .ag-icon-tree-open{
    background: transparent
    url(../../../assets/svg/arrow-down.svg)
    center no-repeat;
    background-size: 16px 16px;
    height: 12px;
    opacity: 0.87;
    width: 12px;
    margin-left: -20px;
    margin-top: 26px;
    padding-right: 20px;
    &:before {
      visibility: hidden;
    }
  }

  .ag-body-viewport{

    .more-info-row {
      font-size:$h4;
      border-right: 1px solid $BorderColor2;
      border-bottom: 1px solid $BorderColor2;
      background-color: $BGColor40;
      .more-info-row-cell{
        display: inline-block;
        overflow: hidden;
        width: 275px;
        margin-left: 20px;
        vertical-align: top;
        .pl-link {
           padding-left:0;
        }
      }

      .title{
        margin-top: 12px;
        font-size: 14px;
        display: block;
        line-height: 20px;
        color: $FontColor2;
      }

      .description {
        display: inline-block;
        line-height: 20px;
      }
     }

    .ag-row-odd, .ag-row-even{
      background-color:white ;
    }

    .ag-selection-checkbox:hover {
      cursor:pointer;
    }
  }

  .ag-row-odd, .ag-row-even{
    &.ag-row-selected{
      background-color: $BGColor16 ;
    }
  }

 .ag-icon-checkbox-unchecked, .ag-icon-checkbox-checked{
      width: 100%;
      height: 100%;
    }


  .ag-cell-focus{
    outline: none;
  }

  .pagination-panel{
    margin-top: 10px;
    margin-bottom: 20px;
    width: auto;
    float: right;

    >:not(:last-child) {
      margin-right: 15px;
    }

    button {
      width: 36px;
      text-align: center;

      .pre-button{
        min-width: 0 !important;
        width: inherit;
      }

      span{
        font-size: 14px !important;
      }
    }

    span{
      font-size: 16px;
    }
  }

  .ag-side-bar {
    .ag-tool-panel-wrapper {
      width: 260px;
      background: $BGColor16;
      padding: 10px;

      .ag-column-select-checkbox, .ag-column-select-column-drag-handle {
        margin-right: 10px;
      }

      .ag-column-select-column-label {
        text-transform: uppercase;
      }

      .ag-checkbox-input-wrapper.ag-checked::after {
        color: $IconColor5;
      }
    }
    .ag-side-buttons {
      background: $BGColor16;
      .ag-side-button {
        background-color: #4da5b5;
        color: #ffffff;
        .ag-button {
          font-size: 14px;
          padding: 20px 0;
          .ag-icon.ag-icon-columns {
            display: none;
          }
        }
      }
    }
  }
}

.ag-dnd-ghost {
  padding: 12px;
  border: 1px solid #cccccc;
  text-transform: uppercase;
  .ag-dnd-ghost-icon {
    display: none;
  }
}



