@import "../../../../styles/includes";

.create-work-order-dialog{
  .modal-dialog {width: $dialog2ColumnsWidth;}
  .modal-body{
    max-height: $modalBodyMaxHeight;
    overflow: auto;
    position: static;
    padding-bottom: 40px;

  }
  .PL-textAreaField {
    height: 85px;
  }

  .pl-combobox .pl-select__value-container {
    max-height: 90px;
  }

  .description-section {
    .PL-textAreaField {
      height: 54px;
    }
  }

  .pl-select__menu .pl-select__option {
    .mark-as-related-option {
      font-weight: bold;
    }
  }
}


