@import "mat/styles/includes";


.work-order-dialog {
    .modal-dialog {
      width: 800px;
  }

  .modal-body {
    max-height: $modalBodyMaxHeight;
    padding: 30px;

    hr {
      border-color: $BGColor29;
    }
    label {
      text-transform: uppercase;
      color: $FontColor4;
      font-size: 12px;
      margin: 0 0 4px 0px;
      font-weight: bold;
    }

    .instructions-title{
      display: inline-block;
      font-weight: bold;
      color: $FontColor4;
    }
    .instructions-text{
      display: inline-block;
      margin-left: 10px;
      color: $FontColor4;
    }
  }
}

.report-operation-single-confirmation-dialog {

  &.message-dialog .title {
    vertical-align: middle;
    line-height: 30px;
    max-width: calc(100% - 66px);
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .message-item {
    margin-bottom: 30px;

    .message-item-line {
      .line {
        margin-bottom: 10px;
        .value {
          max-height: 195px;
          overflow-y: auto;
          overflow-x: hidden;

          .message-item-value {
            width: 200px;
          }
        }
      }
    }

    .related-asset-data {
      padding-top: 1px;

      .message-item-value {
        .related-asset-type {
          width: 40px;
        }
        .related-asset-id {
          width: 150px;
          margin: 0 5px;
        }
        .related-asset-metadata {
          width: 200px;
        }
      }
    }
  }
}


.report-operation-multiple-confirmation-dialog {
  .multistatus-line {
    .line-count {
      margin-left: 5px;
    }
  }
}
