@import "mat/styles/includes";

.entities-multi-select-ex {

  .selection-row {
    @include flex-row();
    margin-top: 10px;
  }
  .selection-row:first-child {
    margin-top: 0;
  }
  .selection-row {
    margin-top: 10px;
  }

  button.add-row {
    margin-top: 14px;
    line-height: 18px;
    margin-left: 0;
    vertical-align:middle;
    .pl {
      margin-right:2px;
      font-size:13px;
    }
  }

  .remove-row{
    color: $IconColor2;
    line-height:24px;
    padding: 7px;
    cursor: pointer;
    display: inline-block;
    height: 38px;
  }


  //to limit the input expanding when typing
  .pl-combobox .pl-select__control .pl-select__input > input {
    max-width: $comboboxInputWidth6;
  }
}
