@import 'infrastructure/styles/theme';

.pl-datepicker-mobile {
  position: relative;

  .buttons-container {
    position: absolute;
    top:0;
    right: 0;
    button {
      height: 36px !important;
    }
  }

  .pl-datepicker {
    height: 36px;
    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          background: none;
          pointer-events: none;
        }
        .react-datepicker__close-icon {
          margin-right: 85px;
        }
      }
    }

    .react-datepicker-popper {
      margin-top: 0;

      .react-datepicker {
        padding: 0 5px;

        .react-datepicker__triangle {
          display: none;
        }

        &.react-datepicker--time-only {
          padding: 5px;

          .react-datepicker__time-container {
            width: 65px;
            .react-datepicker__header--time {
              .react-datepicker-time__header {
                font-size: 16px;
              }
            }
            .react-datepicker__time-box {
              width: 64px;
            }

            .react-datepicker__time-list {
              padding: 0;
            }
          }
        }

        .react-datepicker__navigation {
          &.react-datepicker__navigation--next, &.react-datepicker__navigation--previous {
            margin-top: 5px;
          }
        }

        .react-datepicker__month-container {
          .react-datepicker__current-month {
            font-size: 16px;
            margin-top: 10px;
          }
        }

        .react-datepicker__day-name, .react-datepicker__day {
          margin: 3px;
        }
      }
    }
  }
}








