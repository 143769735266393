.promotions-preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 20;
  background-color: white;

  .title {
    font-size: 14px;
    text-transform: uppercase;
  }
}
