@import "../../../../../../../../infrastructure/styles/theme";

.barcode-settings-view {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;

  .common-header {
    margin: 15px 0;
  }
  .common-csv-settings-form {
    padding: 20px 0 ;
  }
}
