@import "infrastructure/styles/generic";

.filter-footer {
  .selectedCount{
    vertical-align: middle;
    margin-left: calc(50% - 100px);
    margin-top: 9px;
    display: inline-block;
  }

  .filterButton {
    float: right;
  }

  .cancelButton {
    vertical-align: middle;
    margin-top: 9px;
  }
}
