@import "infrastructure/styles/generic";

.label-with-title-cell  {
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .label-with-title-cell__title {
    font-weight: bold;
    height: 15px;
    line-height: 15px;
  }
  .label-with-title-cell__label {
  }

}
