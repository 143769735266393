@import "mat/styles/includes";

.entities-multi-select {
  @include flex-row();
  .entity-type-select {
    width: $assetTypeWidth1;
  }

  &.hide-entities-types {
    .entity-type-select {
      display: none;
    }
    .entity-value-select {
      width: 100%;
    }
  }

  .entities-multi-select-field {
    //width: calc(100% - 115px);
    width: calc(100% - 95px);
  }

  .pl-combobox.pl-multi-select-field .pl-select__placeholder {
    position: absolute;
    top: 5px;
  }

}
