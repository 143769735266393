@import '../TextField/textField';

.PL-textAreaField {
  @extend .PL-textField;
  height: 54px;
  textarea{
    resize: none;
  }

  textarea {
    &:focus {
      border-color: $BorderColor6;
      box-shadow: none;
    }
  }
}
