@import 'mat/styles/includes';

.administration-page .administration-view {

  .mqtt-sensors-view-header {
    display: flex;
    align-items: flex-end;
    margin: 20px 0;

    .pl-combobox {
      width: 360px;
    }

    .common-header {
      margin: 0;
    }
  }
}

