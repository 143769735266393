.progress-breakdown-cell {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 20px;

  .progress-breakdown-bar {
    display: flex;
    width: 100%;
    height: 15px;
    border-radius: 15px;
    overflow: hidden;
  }
}
