@import "infrastructure/styles/theme";

.pl-grid {
  .default-header {
    margin-left: 20px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    flex: 1 1 auto;

    .custom-header-label {
      flex: 1 1 auto;
      overflow: hidden;
    }

    .custom-header-icon {
      color: $FontColor8;
      margin-left: 5px;
      flex: 0 0 auto;
      i {
        line-height: inherit;
        &:before {
          vertical-align: baseline;
        }
      }
    }

    .sort-container {
      flex: 0 0 auto;
      position: relative;
      width: 16px;
      height: 8px;
      margin-left: 5px;
      .custom-sort-down-label, .custom-sort-up-label {
        color: transparent;
        position:absolute;
        top: -4px;
        font-size: 8px;
        z-index: 0;
      }

      .custom-sort-down-label.active, .custom-sort-up-label.active {
        color: $FontColor4;
        z-index: 1;

        &:hover {
          color: $FontColor10;
        }
      }
    }

    .custom-header-menu-button {
      cursor: pointer;
      flex: 0 0 auto;
      margin-left: 3px;
      visibility: hidden;
      bottom: 8px;
      width: 30px;
      height: 30px;
      padding: 5px;
      border: 1px solid transparent;
      color: $FontColor4;

      &:hover {
        color: $FontColor10;
        border: 1px solid $FontColor10;
        background-color: $BGColor22;
      }

      &:active {
        background-color: $BGColor25;
      }
      &.activated {
        visibility: visible;
      }
    }

    &:hover {
      .custom-header-menu-button {
        visibility: visible;
      }
    }
  }

  .ag-menu {
    overflow-y:visible;
    polygon{
      opacity: 0;
    }
    .ag-icon-filter{
      opacity: 0;
    }
  }
}


