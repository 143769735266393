@import 'mat/styles/includes';

.app-topbar {
  width: 100%;
  background-color: #403f40;
  display: flex;

  &.sandbox {
    .left-section, .middle-section, .right-section {
      background-color: #d9385c;
    }
  }

  .middle-section {
    flex: 1;
  }

  .right-section {
    display: flex;
    align-items: center;
    padding-right: 30px;

    & > *:not(:last-child) {
      margin-right: 8px;
    }


    .help-menu-container,
    .scheduling-triggers-container,
    .user-menu-container {
      position: relative;
    }

    .full-screen-container {
      .top-bar-icon {
        &.full-screen-mode path {
          fill: #4da5b5;
        }
      }
    }

    .top-bar-icon {
      width: 26px;
      height: 26px;
      cursor: pointer;

      &:hover path {
        fill: #888;
      }
      &:active path {
        fill: #4da5b5;
      }
      &.active path {
        fill: #4da5b5;
      }
    }
  }

  .left-section {
    display: flex;
    color: $FontColor3;
    font-size: 17px;
    font-weight: bold;
    padding-left: 15px;

    .sandbox-section {
      font-size: 17px;
    }
    .location-section {
      display: flex;
      align-items: center;
      & > *:not(:last-child) {
        margin-right: 8px;
      }

      .location-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;

        path {
          fill: #fff;
        }
      }

      .location-title {
        display: flex;
        flex-flow: column;

        h1 {
          font-size: 17px;
          text-transform: uppercase;
          margin: 0;
          cursor: default;
        }

        h2 {
          font-size: 11px;
          margin: 0;
          cursor: default;
        }
      }
      .location-status {
        margin-left: 30px;
      }

    }
  }
}
