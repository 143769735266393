@import "mat/styles/includes";

.notification-bar-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  z-index: 9999;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  visibility: visible;
  opacity: 1;
  background: $BGColor34;

  .separator {
    height: 5px;
  }

  .read-notification-bar {
    min-height: 25px;
    border: 2px solid red;
    background: $FontColor1;
    line-height: 25px;

    label {
      margin-left: 7px;
    }

    i, label {
      line-height: 24px;
      vertical-align: middle;
    }

    i {
      padding-left: 50px;
      vertical-align: middle;
      font-size: 13px;
      color: $BGColor19;
    }
  }

  .unread-notification-bar {
    min-height: 60px;
    border: 2px solid red;
    background: white;
    line-height: 25px;

    .content {
      margin-top: 15px;
      width: 100%;
      height: 30px;

      label {
        margin-left: 7px;
      }

      i, label {
        line-height: 24px;
        vertical-align: middle;
        font-size: 18px;
      }

      i {
        vertical-align: middle;
        padding-left: 50px;
        color: $BGColor19;
      }
    }
  }

  &.bar-only {
    height: auto;
    position: unset;
  }

  .agree-link {
    margin-left: 15px;
    color: blue;
  }
}
