@import "mat/styles/includes";

.subroll-list-item {
  @include flex-row();

  .input-with-generate-button {
    flex:1;
  }

  .short-textfield {
    max-width: 150px;
  }
}






