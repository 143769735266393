@import "infrastructure/styles/theme";

.success-icon {
  $duration: 600ms;
  $line-size: 6px;
  $circle-color: $IconColor7;
  $circle-highlight-color: $IconColor3;
  $check-mark-color: $IconColor3;

  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;

  &.in {
    animation: success-enter 200ms;

    .border-highlight {
      animation: success-border-highlight-enter $duration * 2.5 ease;
    }

    .checkmark-box {
      opacity: 1;

      .bar-1 {
        animation: success-bar-1-enter $duration * 4 ease-out;
      }

      .bar-2 {
        animation: success-bar-2-enter $duration * 4 ease-out;
      }
    }
  }

  .border {
    position: absolute;
    width: 100%;
    height: 100%;
    border: $line-size solid $circle-color;
    border-radius: 50%;
  }

  .border-highlight {
    position: absolute;
    width: 100%;
    height: 100%;
    border: $line-size solid transparent;
    border-radius: 50%;
  }

  .checkmark-box {
    position: absolute;
    top: 36px;
    left: 43px;
    width: 60px;
    height: 40px;
    transform: rotate(-45deg);
    transform-origin: bottom left;
    opacity: 0;

    .bar-1 {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: $line-size;

      background: $check-mark-color;
      border-radius: $line-size / 2;
    }

    .bar-2 {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: $line-size;

      background: $check-mark-color;
      border-radius: $line-size / 2;
    }
  }

  @keyframes success-enter {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes success-bar-1-enter {
    0% {
      top: -20px;
      bottom: 80px;
    }
    37% {
      top: -20px;
      bottom: 80px;
    }
    60% {
      top: 0;
      bottom: 0;
    }
    100% {
      top: 0;
      bottom: 0;
    }
  }

  @keyframes success-bar-2-enter {
    0% {
      right: 60px;
    }
    68% {
      right: 60px;
    }
    100% {
      right: 0;
    }
  }


  @keyframes success-border-highlight-enter {
    0% {
      border-left-color: $circle-highlight-color;
      opacity: 0;
    }
    25% {
      border-left-color: $circle-highlight-color;
      opacity: .8;
    }
    50% {
      border-left-color: $circle-highlight-color;
      opacity: .8;
    }
    75% {
      border-left-color: $circle-highlight-color;
    }
    85% {
      opacity: 0;
    }
    100% {
      transform: rotate(340deg);
      opacity: 0;
    }
  }
}
