@import "infrastructure/styles/generic";

.filterTableRow {
  line-height: 30px;
  vertical-align: middle;

  label {
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width:inherit;
  }

  .main-label {
    label {
      width: 220px;
      margin-left: 14px;
    }
  }

  .additional-data-label {
    //need to fix Lengths if in the future we'll use additional data column
    width: 0px;
  }
}
