.raw-materials-view {
  padding: 0 0 10px 0;
  height:100%;

  .top-filters-section{
    height:100%;
    .tab-content{
      padding-top:18px;
      margin-left:0;
      margin-right:0;
      height:calc(100% - 45px);
    }
  }
}










