@import "mat/styles/includes";

.kit-materials-details {
  position: relative;
  padding: 0 4px 4px 4px;
  margin-top: 20px;
  border: 1px solid transparent;
  font-size: 14px;
  line-height: 20px;

  hr {
    margin-top: 0;
  }

  .header {
    border-bottom: 2px solid $BorderColor6;
    color: $FontColor2;
  }
  .asset-materials-table-content {
    height: 140px;
    overflow: auto;
    .asset-materials-row {
      &:not(:last-child) {
        border-bottom: 1px solid $BGColor29;
      }
    }
  }

  .asset-materials-row {
    padding: 8px 0 5px 0;
    .cell{
      display: inline-block;
      vertical-align: middle;
      &:first-child {
         width: calc(60% - 7.5px);
        @include ellipsis(197px);
       }
      &:not(:first-child):not(:last-child) {
         width: 12%;
       }
      &:not(:last-child) {
        margin-right: 15px;
      }
      .tooltip-container {
        display: inline;
        text-overflow: ellipsis;
        max-width: 100%;

      }
      .pl-substitute-material-mark {
        margin-right: 5px;
        line-height: inherit;
      }
    }
  }
}














