@import "infrastructure/styles/theme";

.tasks-assignments-grid{
  height: 535px;

  .pagination-panel {
    margin-bottom: 10px;
  }

  .ag-cell {
    line-height:30px !important;
    padding: 17px;
  }
}








