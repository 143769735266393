@import "mat/styles/includes";

.tool-category-operation-list-item {
  @include flex-row();

  .pl-combobox .pl-select__menu {
    .pl-select__menu-list {
      max-height: 150px;
    }
  }
}
