@import "infrastructure/styles/theme";

.pl-toggle-button {
  display: inline-block;
  line-height: 36px;

  .toggle-button-input {
    position:relative;
    input[type="checkbox"] {
      opacity:0;
      margin:0;
      top:0;
      width:0;
      height:0;
      position:absolute;
    }
  }

  input[type="checkbox"] + label {
    padding: 0 8px;
    cursor: pointer;
    &:hover {
      background: $BGColor9;
    }
    i {
      color: $FontColor11;
    }
  }

  input[type="checkbox"]:checked + label {
    background-color: $BGColor12;
    i {
      color: $FontColor10;
    }
  }

  .toggle-button-label {
    margin-left: 5px;
  }

}


