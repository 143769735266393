@import "mat/styles/includes";

.scheduler-operations-grid {
  height: 100%;
  margin-top: 10px;

  .ag-sticky-top {
    background-color: #fff;
  }


  //workaround: to avoid the DatePicker clipping (called from the grid's date filters )
  .pl-grid .ag-root-wrapper {
    overflow: visible;

    //workaround for the column with 'agGroupCellRenderer'
    .ag-header {
      .group-invisible-header {
        border-right-color: transparent;
      }
    }

    //workaround: to avoid the grid's filters clipping
    // .ag-body-viewport {
    //   min-height: 410px;
    // }
  }

  .ag-header-cell {
    &[col-id="name"] {
      .default-header {
        margin-left: 0;
      }
    }
  }
  //workaround for the grid resizing to 0
  .pl-grid {
    //height: calc(100% - 55px);
    //height: 100%;
    > div {
      display: block;
      vertical-align: baseline;
    }
    .pagination-panel {
      text-align: end;
      margin-bottom: 10px;
      float: none;
    }

    //.ag-header-cell {
    //  &[col-id="m_priority"] {
    //    .default-header {
    //      margin-left: 0;
    //      .custom-header-icon {
    //        text-align: center;
    //      }
    //    }
    //  }
    //}
    .ag-cell {
      &[col-id="valid"] {
        padding-right: 20px;
        .alert-icon-cell {
          .alert-icon {
            margin-right: 5px;
          }
        }
      }
      
      .priority-icon{
        width: 18px;
        height: 18px;
      }

      &.child-indent {
        padding-left: 40px;
      }

      .partial-lock, .partial-split {
        opacity: 0.4;
      }
    }
  }

  .more-info-row {
    display: flex;

    .more-info-row-cell {
      width: 265px !important;
    }

    .shifts-cell {
      display: flex;
      flex-flow: column;
    }
  }
}
