@import 'infrastructure/styles/theme';

.filterLayout {
  z-index: 5;
  width: 350px;
  padding: 20px;
  background: $BGColor2;
  border-style: solid;
  border-width: 1px 0px 0px 1px;
  border-color:$BorderColor13;
  box-shadow:$BoxShadow;
  top: 28px;
  position: absolute;

  .pl-filter{
    //right: 11px;
    //top: -33px;
    top: -36px;
    //left:calc(100% - 41px);

    z-index: 3;
    position: absolute;

      width: 30px;
      height: 30px;
      padding: 7px;
      //padding-top: 4px;

      color: White;
      background-color: $FontColor10;

  }
}

.filterLayout .border-triangle:after {
  z-index: 4;
  content:'';
  border-style: solid;
  border-width: 1px 0px 0px 1px;
  border-color:$BorderColor13;
  width:10px;
  height:10px;
  background:$BGColor2;
  position:absolute;
  transform:rotate(45deg);
  -moz-transform:rotate(45deg);
  -webkit-transform:rotate(45deg);
  top:-6px;
  right: inherit;

}
