@import "mat/styles/includes";

.text-cell  {
  border: none;
  background-color: white;
  line-height: 25px;
  font-family: $FontFamily1;
  font-size: 15px;
  padding: 0;
  margin: 0;
}
