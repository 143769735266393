@import "../../../../../../styles/includes";

.filters-parameters-view {
  position: relative;
  .filters-view-enable-section {
    padding: 18px 0;

    span {
      vertical-align: middle;
      margin-left: 13px;
      font-size: 16px;
      line-height: 18px;
      font-weight: 700;
    }
  }

  .filters-view-parameters-section {
    .input-section {
      margin-top: 18px;
    }
  }
}









