@import 'infrastructure/styles/theme';

.file-picker {
  display: inline-block;

  .input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .file-picker-selection {
    display: inline-block;

    .file-picker-text {
      width: 290px;
      background-color: white;
      &.disabled {
        color: $FontColor14;
      }
    }

    .file-picker-browse-btn {
      width: 140px;
      height: 36px;
      //white-space: nowrap;
      //overflow: hidden;
      //text-overflow: ellipsis;
      text-align: center;
      vertical-align: middle;
      border: 1px solid #ccc;
      font-size: 14px;
      line-height: 36px;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      color: $FontColor10;
      font-weight: 700;
      display: inline-block;
      //padding: 7px 12px;
      cursor: pointer;

      margin-bottom: 0;
      &.disabled {
        color: $FontColor14;
        cursor: default;
      }
    }
  }
}
