@import '../../../../../../styles/includes';

.section-settings-form {
  .common-header {
    margin: 0 !important;
    height: 60px;
    border-bottom: 2px solid #eeeeee;
    .title {
      border: none;
    }
  }

  .section {
    @include flex-row();
    margin-top: 18px;

    .input-section {
      flex: 1 0 0;

      .PL-textField, .pl-combobox {
        width: 100%;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

    .PL-textField > input {
      padding-right: 30px;
    }
  //}
}
