@import "mat/styles/includes";

.workorder-details {
  padding: 20px 30px;

  .workorder-details-section{
    background-color: $BGColor2  !important ;
    display: flex;
    width: 100%;
    padding: 20px 0;
  }


  hr {
    margin-top: 20px;
    border-top: 1px dashed $BGColor29;
    opacity: 1;
  }

  .value {
    >span:not(:last-child) {
      margin-right: 15px;
    }
  }
  .popover-target {
    display: inline-block;
  }

}












