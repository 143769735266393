@import "mat/styles/includes";

.panel-container {
  display: inline-block;

  .notifications-wrapper {

    $zIndex: 10000;
    $width: 463px;

    width:$width;
    margin-left: -$width;
    margin-top: 20px;

    border: 0px solid transparent;
    border-radius: 0px;

    z-index: $zIndex;
    position: absolute;
    height: 0;
    &.show {
      height: unset;
    }
  }
}
