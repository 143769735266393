@import "../../../../../../styles/includes";

.select-attributes-step {
  display: flex;
  flex-direction: column;
  padding: 15px 30px;
  height: 100%;

  .note-section-line {
    margin-top: 10px;
  }

  .csv-properties-list-header {
    font-size: 15px;
    background-color: #ddd;
    margin-bottom: 10px;

    display: flex;
    flex-direction: row;

    .column {
      line-height: 35px;
      width: 210px;
      &:first-child {
        padding-left: 10px;
        width: 150px;
      }
    }
  }

  .add-remove-list-container-ext {
    .add-remove-list {
      height: 325px;
      overflow-y: auto;
      overflow-x: hidden;

      .column.column-1 {
        text-align: start;
        padding-left: 30px;
      }
    }

    //.add-row {
    //  display: none;
    //}
  }
}
