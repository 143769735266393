.color-picker-with-text {
  .color-holder-wrapper {
    display: inline-flex;
    height: 40px;
    border: 1px solid #707070;
    border-radius: 3px;
    padding: 5px;
    &:hover {
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
    .color-holder {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 5px;
      box-sizing: border-box;
      border: 1px solid #707070;

      span {
        background-image: linear-gradient(to right, black 50%, white 50%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .color-input {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}
