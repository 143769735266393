.edit-password-field-button {
  display: inline-block;
  margin-left: 2px;
  width: 35px;
  border-radius: 1.3px;
  > span.fa {
    color: white;
  }
  > span.pl {
    color: white;
  }
}

.edit-password-field-input {
  display: inline-block;
  //using more than one className to increase specificity
  &.edit-password-input-width.with-one-button:not(input) {
    width: calc(100% - 37px);
  }
  &.edit-password-input-width.with-two-buttons:not(input) {
    width: calc(100% - 74px);
  }
}
