@import 'infrastructure/styles/theme';

.weekly-shifts-template-view {
  position: relative;
  display: flex !important;
  flex-flow: column;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    .btn {
      padding-left: 13px;

      &:not(:first-child) {
        margin-left: 8px;
      }
    }

    .flex-grow-2 {
      flex: auto;
      flex-grow: 2;
    }
  }

  .shift-scheduler-wrapper {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
