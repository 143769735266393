@import "mat/styles/includes";
.quantity{
  border: 3px solid;
  min-width: 150px;
  border-radius: 10px;
  background-color: #403f40;
  color: white;
  height: 36px;
  display: inline-block;
  line-height: 35px;
  text-align: center;
  margin-left: 27px;
  padding-right: 10px;

  &.warning{
    border-color: $IconColor4;
  }
  &.success{
    border-color: #4da5b5;
  }

  .pl-info-icon-blue{

    display: inline-block;
    color: $IconColor4;
    font-size: 20px;
    float: left;
    padding-left: 10px;
    padding-top: 5px;
    margin-right: 10px;
  }
  .pl-check-circle-icon-blue{
    display: inline-block;
    color: #4da5b5;
    font-size: 20px;
    float: left;
    padding-left: 10px;
    padding-top: 5px;
    margin-right: 10px;
  }
}

