@import "mat/styles/includes";


.asset-overview-view{
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;

  &.asset-archived {
      background: repeating-linear-gradient(
          -45deg,
          $BGColor11,
          $BGColor11 4px,
          $BGColor37 4px,
          $BGColor37 6px
      );
  }
  &.asset-active {
      background-color: $BGColor11;;
  }

  .column {
    width: 33%;
    min-height: 100%;
    vertical-align: top;
    display: table-cell;
    padding: 0px 17px;

    &:not(:last-child) {
      border-right: solid 1px $BGColor29;
    }
  }

}


