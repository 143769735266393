@import "infrastructure/styles/theme";

.dropdown {
  $paddingLi: 11px;

  .dropdown-menu {
    padding: 5px 0;
    border: 1px solid $BorderColor17;
    border-radius: 0;
    background-color: $BGColor2;
    box-shadow: $BoxShadow;

    .dropdown-item {
      color: $FontColor15;
      font-size: $FontSize1;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      margin: 3px 7px;
      padding: 3px $paddingLi;
      text-transform: uppercase;

      &:not(.disabled) {
        &:hover, &:focus {
          background: $BGColor11;
          color: $FontColor10;
          border: 0 solid transparent;
          outline: none;
          outline-offset: 0;
        }
      }

      &.disabled {
        color: #adb5bd;
        &:hover, &:focus {
          outline: none;
        }
      }

      &.dropdown-header, &.dropdown-header:hover {
        padding: 0 $paddingLi;
        background-color: $BGColor2;
        color: $FontColor2;
        margin-top: 0;
        margin-bottom: 0;
      }

      &.divider {
        background: $BorderColor2;
      }
    }
  }

  .dropdown-toggle {
    font-size: 19px;
    background-color: $BGColor2;
    border: 1px solid $BorderColor17;
    &.btn.btn-default.border-btn.show {
      color: $FontColor3;
      background-color: $BGColor13 !important;
      border-color: $BorderColor15;
    }
    &:hover {
      background-color: $BGColor11;
      border-color: $BorderColor15;
    }

    span {
      line-height: 33px;
    }

    &::after {
      display: none !important;
    }

    .pre-dropdown-button {
      float: left;
      margin-right: 4px;
      font-size: 15px;
    }
  }
}

