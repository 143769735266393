.cause-grid {
  height: calc(100% - 45px);
  margin-top: 18px;
  .pl-grid {
    height: 100%;
  }

  .overlay.text-overlay {
    left: 0;
  }
}




