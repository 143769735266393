.color-picker-tile-editor {
  display: flex;
  padding: 5px 0;
  width: 50%;
  height: 50px;

  .color-picker-tile {
    height: 100%;
    width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    color: #fff;
    font-weight: bold;
    margin-right: 8%;
    border-radius: 10px;
    font-size: 17px;

    .color-picker-tile-icons {
      display: flex;
      justify-content: right;
      align-items: center;
      color: #f00;
      img {
        margin: 0 7%;
        width: 20px;
      }
    }
  }

  .color-picker-tile-actions {
    font-size: 22px;
    display: flex;
    align-items: center;
    .popover-target {
      margin-right: 10%;
    }
  }

  .color-picker-tile-picker {
    visibility: hidden;
  }
}
