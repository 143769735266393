@import "mat/styles/includes";


.user-management-dialog{
  .modal-dialog {
    width: $dialogBig1ColumnWidth;

  .pl-combobox .pl-select__multi-value .pl-select__multi-value__label {
      max-width: 96px;
  }


  .user-homepage-container {
      display: flex;
      flex-direction: row;
      flex: 1;

    .input-section.user-homepage-section {
      width: 25%;
    }

    .input-section.user-homepage-details-section {
      width: 75%;
      padding-top: 18px;
    }

  }
  }
}

.user-management-confirm-message{
  .modal-body .line .label{width:150px;}
}


