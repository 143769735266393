@import "mat/styles/includes";

.pl-grid {
  .weight-cell {
  }
}








