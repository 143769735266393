@import 'mat/styles/includes';

.sandbox-page {
  position: relative;
  height: 100%;
  padding: 23px 30px 10px 30px;

  .common-header {
    .title {
      font-size: 18px;
      font-weight: normal;
      text-transform: none;
    }
  }
}
