@import '../../../../../../styles/includes';

.pl-grid {

  .input-cell {
    height: 30px;
    &.multi-row-cell{
      margin-bottom: 30px;
      height: 30px;
    }

    span {
      border: 1px solid rgba(136, 144, 148, 0.30);
      width: 100%;
      padding-left: 5px;
      height: 30px;
      display: inline-block;
      line-height: 30px;
      overflow: hidden;
    }
  }

  .input-cell-disabled {
    border: 1px solid $BGColor3;
    background-color: #eee;
    padding-left: 5px;
    overflow: hidden;
    height: 30px;
    //margin-bottom: 27px;  //TODO: L PLIES - check if it is Ok to remove it from here
  }
}





