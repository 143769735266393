@import 'infrastructure/styles/theme';

.editor {
  position: relative;
  height: 100%;
  &.active {
    border: 1px solid $FontColor5;
  }
  .buttons-container {
    position: absolute;
    display: flex;
    right: 3px;
    top: 0;
    height: 100%;
    align-items: center;
    #save {
      background-color: $BGColor5;
      color: $FontColor1;
      height: 25px;
      width: 25px;
      outline: none;
      border: none;
      &:hover {
        background-color: $BGColor6;
      }
    }
  }
}
