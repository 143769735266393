@import 'mat/styles/includes';

.scan-workorder-page {
  background-color: $BGColor2;
  position: relative;

  .content {
    font-size: 20px;
    min-height: calc(100% - 120px);
    height: calc(100% - 60px);
    overflow-x: hidden;
    overflow-y: auto;

    .scan-input-section {
      display: flex;
      flex-direction: column;
      margin: 30px 20px 0;

      .station-select .pl-select__control{
        height: 45px;
        font-size: 18px;
      }
    }

    .prompt-text {
      margin: 0 60px 30px;
      text-align: center;
    }

    .barcode-image {
      @media screen and (max-height: 550px) {
        display: none;
      }
      text-align: center;
      img {
        height: 290px;
      }
    }
  }

  .scan-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
