@import "mat/styles/includes";

.scan-details-section {
  background-color: $BGColor2;
  padding: 0 25px;
  font-size: 15px;
  height: calc( 100% - 120px);
  overflow-x: hidden;
  overflow-y: auto;

  .section {
    padding: 10px 10px;

    &:not(:last-child) {
      border-bottom: 1px solid $BorderColor24;
    }

    label:first-child {
      display: block;
      text-transform: uppercase;
    }

    label:not(:first-child) {
      font-size: 19px;
      font-weight: bold;
    }

    span.pl {
      line-height: inherit;
    }
    &.two-columns div {
      display: inline-block;
      width: 50%;
      vertical-align: top;
    }
  }

  .error {
    color: $IconColor3;
  }
  .warning {
    color: $IconColor4;
  }
  .alert-icon {
    margin-left: 5px;
  }

}





