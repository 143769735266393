
.parts-grid{
    height: calc(100% - 53px);
    margin-top:18px;

  .ag-cell {
    line-height:30px !important;
    padding: 20px;
  }

  .pl-resulting-assets{
    font-size: 18px;
  }
  .ag-header-cell {
    &[col-id="expirationDate"] {
      .default-header {
        .custom-header-icon {
          display: inline;
          .pl {
            margin-left: 10px;
          }
        }
        .custom-header-with-tooltip {
          width: calc(100% - 80px);
          float: left;
        }
      }
    }
  }
}







