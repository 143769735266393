@import 'infrastructure/styles/theme';

.etl-log-grid {
  height: 100%;

  .value-with-state-cell {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
}
