@import "mat/styles/includes";

.edit-attributes-dialog{
  .modal-dialog {width: $dialog2ColumnsWidth;}

  .modal-body {
    max-height: $modalBodyMaxHeight;
    position: static;

    .measurements-section {
      width: calc(50% - 15px);
      display: inline-flex;
      >div {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    label {
      text-transform: uppercase;
    }
    .label-extra-component {
      text-transform: none;
    }

    .modal-dialog-overlay {
      height: calc(100% - 60px);
    }
  }

  //to limit the input expanding when typing
  .pl-combobox .pl-select__control .pl-select__input > input {
    max-width: $comboboxInputWidth9;
  }

  .pl-combobox.pl-multi-select-field.entity-value-select {
    width: 544px;
    .select-value-row .extra-data{
      width: 320px;
    }
  }

  .react-datepicker-wrapper{
    width: 100%;
  }
  .time-value-wrapper{
    width:100%;
  }
  .padding-top {
    padding-top: 26px;
  }
}
