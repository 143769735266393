@import "mat/styles/includes";

.pdf-label-property-list-item {
  @include flex-row();

  .column {
    width: 300px;

    &.column-2 {
      text-align: center;
      width: 120px;
      margin-left: 20px;
      .pl-combobox {
        width: 70px;
        margin-right: 20px;
      }
    }

    &.column-3 {
      margin-left: 20px;
      .pl-toggle-button{
        height: 100%;
        margin-right: 5px;

        .toggle-button-input{
          height: 100%;
          display: flex;

          label {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }


  .pl-combobox .pl-select__menu {
    .pl-select__menu-list {
      max-height: 150px;
    }
  }
}

.barcode-icon {
  background: url('../../../../../../../assets/ico/barcode-icon.png') no-repeat center;
  width: 50px;
  height: 29px;
}




