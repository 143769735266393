@import "infrastructure/styles/theme.scss";

@keyframes duplicate-row {
  0% {
    background-color: white;
  }
  100% {
    background-color: #a2dee8;
  }
}

//dialog selector
div#importAssetsViaBarcodeWizard.modal-dialog {
  height: 650px;

  .modal-content > .wizard-dialog-body {
    .tab-content {
      div.height-100 {
        height: 100%;
        padding: 15px;

        > div.scan-assets-header {
          font-weight: bold;
          margin: 0 0 10px 0;

        }
        > div.scan-assets-grid {
          height: calc(100% - 30px);

          > div.pl-grid {
            height: 95%;
            .duplicate-row {
              animation: 350ms infinite alternate duplicate-row;
            }
          }
          > textarea.barcode-hidden-input {
            //height: 20px;
            //width: 600px;
            height: 0;
            width: 0;
            border: 0;
            padding: 0;
          }
        }
      }
      //second tab
      div#importAssetsViaBarcodeWizard_tabs-pane-1 > div.height-100 {
        > div.validate-assets-header {
          height: 15%;
          margin: 0 12px;

          :first-child {
            font-weight: bold;
          }
          :not(:first-child) {
            font-size: 13px;
          }
        }
        > div.validate-assets-grid {
          height: calc(85% - 45px);
          margin: 0 12px;

          > div.pl-grid {
            height: 86%;
          }

          > div.popover-target {
            position: relative;
            left: 0.5%;
            top: 1%;
            max-height: 13%;
          }
        }
      }
      //confirm import tabs
      div#importRollsViaBarcodeConfirmImportTabs {
        > ul.nav-tabs[role=tablist] {
          > li > a {
            > div.ellipsis-with-tooltip {
              width: 75%;
            }
            > button.only-icon {
              transform: translate(10px,-3px) scale(0.8);
            }
          }
        }
        > div.tab-content {
          div.confirm-import-row {
            display: block;
            > div {
              &.confirm-import-label {
                min-width: 40%;
                font-weight: bold;
                font-size: 15px;
              }

              &.confirm-import-value {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
