.color-cell {
  display: flex;
  justify-content: center;

    .color-box {
        width: 28px;
        height: 28px;
        border-radius: 4px;
    }
}
