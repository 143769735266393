@import 'infrastructure/styles/theme';

.file-chooser-wrapper {
  display: inline-block;

  .file-chooser-selection {
    display: inline-block;

    .PL-textField {
      width: 286px;
    }

    .custom-file-chooser {
      width: 142px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      border: 1px solid #ccc;
      font-size: 14px;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      color: $FontColor10;
      font-weight: 700;
      display: inline-block;
      padding: 7px 12px;
      cursor: pointer;
    }
  }
}
