@import "mat/styles/includes";

.kit-details {
  &.asset-details-section {

    .column {
      &:first-child {
        width: 40%;
      }

      &:not(:first-child){
        width: 30%;
      }
    }
  }
}













