@import "../../../../../../../../styles/includes";

.create-tool-type-dialog {

  .modal-dialog {width: $dialog2ColumnsWidth;}

  .modal-body {
    .input-section:first-child {
      margin-top: 0;
    }
    .time-value-wrapper{
      width:100%;
    }

    .kit-types-list-container {
      position: relative;
      .kit-types-list-title {
        display: flex;
        height: 35px;
        background: #ddd;
        .column {
          flex: 1;
          line-height: 35px;
          padding-left: 15px;
          &:first-child {
            flex: 0 1 calc(50%);
          }
          &:nth-child(2) {
            flex: 0 1 115px;
          }
        }
      }

      .kit-types-list {
        &.list-ext-overflow {
          .add-remove-list {
            max-height: 230px;
            overflow-y: auto;
            overflow-x: hidden;
          }
        }

        padding-right: 0;

        .add-remove-list {
          padding-right: 0;
        }
        .add-row {
          margin-top: 0;
        }
      }

      .total-token-value-container {
        position: absolute;
        display: inline-block;
        left: 450px;
        bottom: -5px;
      }
    }
  }
}
