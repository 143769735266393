.location-tasks-view {
    display: flex;
    flex-direction: column;
    padding: 23px 0 10px 0;
    height: 100%;
    width: 100%;

    .tasks-view-header {
        margin-bottom: 26px;
        flex: 0 1 auto;
    }

    .tasks-date-nav-container {
        display: flex;
        align-items: center;

        button {
            margin-right: 14px;
            & > * {
                pointer-events: none;
            }
        }
       .pl-datepicker {
         input {
           width: 0;
           padding: 0 35px 0 0;
         }
         margin-right: 14px;
       }

      //.date-range-wrapper {
      .date-range-popover-container {
        display: inline-block;
        position: relative;
        width: 33px;
        margin-right: 14px;


        .popover-target {
          background: white url('infrastructure/assets/images/calendar.png') no-repeat center;
          width: 26px;
          height: 26px;
          top: 50%;
          right: 6px;
          transform: translateY(-50%);
          position: absolute;
        }
      }
    }
}
