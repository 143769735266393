@import "../../../../../../styles/includes";

.add-to-shipment-dialog.dialog {

  .modal-dialog {width: 990px;}

  .modal-body {
    max-height: $modalBodyMaxHeight;
    position: static;

    .add-to-asset-container {
      @include flex-row();
      align-items: flex-start;

      .add-to-asset-section {
        width: 45%;
        .add-to-asset-label {
          text-transform: uppercase;
        }

        .entities-multi-select {
          .entity-type-select {
            width: 120px;
          }
          .entities-multi-select-field .select-value-row .column.extra-data {
            width: 95px;
            //width: 57px;
          }
          .pl-select__value-container {
            max-height: 110px;
          }
        }
      }
      .add-to-asset-arrow {
        display: inline-block;
        width: 10%;
        text-align: center;
        font-size: 15px;
        margin-top: 30px;
      }

      .assets-to-asset-selection {
        .pl-select__menu .pl-select__option {
          .column:first-child {
            width: 0;
          }
          .column:nth-child(2) {
            width: 250px;
          }
        }
      }
    }
  }
}

.add-to-cont-confirmation-dialog {
  .message-item {
    margin-bottom: 30px;

    .message-item-line {
      .line {
        margin-bottom: 10px;

        .value {
          max-height: 195px;
          overflow-y: auto;
          overflow-x: hidden;

          .message-item-value {
            width: 200px;
          }
        }
      }
    }

    .related-asset-data {
      padding-top: 1px;

      .message-item-value {
        .related-asset-type {
          width: 60px;
        }

        .related-asset-id {
          width: 150px;
          margin-right: 5px;
        }

        .related-asset-metadata {
          width: 190px;
        }
      }
    }
  }
}

