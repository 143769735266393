@import "mat/styles/includes";

.SortableHelper {
  box-shadow: rgba(0, 0, 0, 0.075) 0 1px 6px, rgba(0, 0, 0, 0.075) 0 1px 4px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 15px 0 15px 40px;
  z-index: 60000;
}

.pl-sortable-list {
  .items-wrapper {
    background-color: #f3f3f3;
  }
}


