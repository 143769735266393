@import "mat/styles/includes";

.cancel-cut-report-dialog{
  z-index: 40001 !important;
  .modal-dialog {width: $dialog2ColumnsWidth;}
}

.cancel-cut-report-confirm-message{
  .modal-body .line .label{width:125px;}
}

.cancel-report-cut-body-text {
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: #707070;
  margin-bottom: 20px;
}

