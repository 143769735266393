@import "mat/styles/includes";


.wo-overview-view{
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background-color: $BGColor11;

  &.wo-archived {
    background: repeating-linear-gradient(
        -45deg,
        $BGColor11,
        $BGColor11 4px,
        $BGColor37 4px,
        $BGColor37 6px
    );
  }
  &.wo-active {
    background-color: $BGColor11;;
  }

  .column {
    width: 33%;
    height: 100%;
    vertical-align: top;
    display: table-cell;
    padding: 0px 17px;

    &:not(:last-child) {
      border-right: solid 1px $BGColor29;
    }
  }

}


