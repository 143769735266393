@import "mat/styles/includes";

.pl-grid {

  .activity-details {
    .title{
      font-size: 14px;
      display: block;
      top: 12px;
      line-height: 20px;
      position: relative;
      color: $FontColor2;
    }

    .description {
      top: -14px;
      position: relative;
    }

    .activity-details-item:not(:first-child){
      margin-top: -40px;
    }
  }
}






