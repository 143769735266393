@import "../../../../../../styles/includes";

.file-upload-step {
  padding: 20px 20px 20px 60px;

  .top-section {
    margin-bottom: 20px;
    .top-section-line {
      margin-bottom: 10px;
      .column-name {
        display: inline-block;
        font-weight: bold;
      }
      .attr-name {
        display: inline-block;
        margin-left: 10px;
      }
    }
    .top-section-line:first-child {
      margin-bottom: 30px;
    }
  }

  .bottom-section {
    margin-top: 70px;
  }

  .error-section {
    margin-top: 40px;
    display: flex;
    align-items: center;
    >span {
      margin-right: 10px;
    }
    .error {
      color: $IconColor3;
    }
    .warning {
      color: $IconColor4;
    }
  }


}
