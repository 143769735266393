@import "mat/styles/includes";

.length-chart {
  float:left;
  width:500px;
  height: 439px;
  margin-left: 20px;
  margin-top: 10px;
  background-color: white;
  .chart-title {
    text-align: center;
    font-size: 17px;
    padding-top: 15px;

  }
  .empty-chart-section{
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .recharts-surface {
    overflow: hidden;
  }

  .recharts-wrapper svg{
    overflow: inherit !important;
  }
  .chart-content{
    display: inline-block;


    .chart-section{
      display: inline-block;
      width: 300px;

      .recharts-legend-wrapper {
        position: fixed !important;
        left: auto !important;
        bottom: auto !important;
        margin: 0 auto !important;
      }

      &.legend-bottom {
        .recharts-legend-wrapper {
          bottom: 40px !important;
        }
      }
    }
  }

  .custom-legend {
    font-size: 11px;
    text-transform: uppercase;
    &.pl:before {
      margin-right: 5px;
      color: #367A87;
      font-size: 16px;
    }
  }
}
