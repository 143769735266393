.tasks-view-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 1 auto;
  user-select: none;

  .action-buttons {
    display: flex;
    margin-right: 12px;

    .pl-select-button-wrapper {
      &:first-child {
        margin-right: 32px;
      }
      .pl-select-button-icon {
        svg {
          display: block;
          width: 37px;
          height: 37px;
        }
      }
    }

    .mark-started-select-button {
      .pl-select-button {
        min-width: 170px;
      }
    }

    .mark-completed-select-button {
      .pl-select-button {
        min-width: 180px;
      }
    }
  }

  .task-counter {
    font-size: 16px;
    font-weight: bold;
  }
}
