@import "infrastructure/styles/theme";

.activity-log-dialog-grid{
  height: 535px;
  .pagination-panel {
    margin-bottom: 10px;
  }
}








