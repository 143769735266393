@import "infrastructure/styles/theme";

.scheduling-preferences-cell {

  .scheduling-preferences-cell-row {
    @include flex-row();
    color: $FontColor16;

    >:not(:first-child) {
      color: $FontColor17;
      margin-left: 10px;
    }
  }
}
