@import "infrastructure/styles/generic";
@import "infrastructure/styles/theme";

.input {
  border:thin solid $BorderColor13;

  input[type="text"]{
    width: 92% !important;
    padding: 8px;
    border: none;
    box-shadow: none;
    display: inline;
  }

  i {
    color: $FontColor10;
  }
}





