@import 'mat/styles/includes';

.process-temperature-log-dialog {
  .modal-dialog {
    width: 620px;
  }

  .body-title {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .modal-body {
    .input-section {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .inputs-section {
    display: flex;
    margin: 10px 0;

    .input-file {
      display: none;
    }

    .file-section-wrapper {
      flex: 1 0 auto;
      margin-right: 5%;

      .file-select-label {
        margin-bottom: 5px;
      }
    }

    .etls-section-wrapper {
      display: flex;
      flex-flow: column;
      justify-content: center;
      margin-top: 16px;
    }
  }

  .processed-file-message {
    font-weight: bold;
  }
}
