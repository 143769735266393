@import "mat/styles/includes";

.asset-cell {
  @include flex-row();
  .pl {
    line-height: inherit;
    font-size: 16px;
  }

  .entity-id {
    margin-left: 10px;
  }
}
