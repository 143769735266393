@import "mat/styles/includes";

.create-device-app-dialog {

  .modal-dialog {
    width: $dialog2ColumnsWidth;
  }

  .modal-body {
    .device-app-time-frame {

    }
  }
}
