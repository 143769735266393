@import 'mat/styles/includes';

.common-additional-fields-view {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;

  .common-header {
    margin: 15px 0;
  }

}
