.scanned-work-order-tasks-page {
  .scanned-work-order-tasks {
    height: calc(100% - 120px);
    padding: 10px 20px;
    overflow-y: auto;
    position: relative;
    .scanned-work-order-no-tasks {
      font-size: 20px;
      height: 100%;
      text-align: center;
      width: 80%;
      display: flex;
      margin: auto;
      align-items: center;
      white-space: pre-line;
      div {
        margin-bottom: 10px;
      }
    }
  }
}
