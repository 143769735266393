@import "mat/styles/includes";

.scanned-asset-page {

}





