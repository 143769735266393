@import 'infrastructure/styles/theme';

.pl-pagination-panel{
  @include flex-row();
  justify-content: flex-end;
  align-items: center;

  margin-top: 10px;
  margin-bottom: 20px;
  //text-align: right;
  //width: auto;
  //float: right;

  >:not(:last-child) {
    margin-right: 15px;
  }

  button {
    width: 36px;
    text-align: center;

    .pre-button{
      min-width: 0px !important;
      width: inherit;
    }

    span{
      font-size: 14px !important;
    }
  }

  span{
    font-size: 16px;
  }
}

