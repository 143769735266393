@import "mat/styles/includes";

.active-tools-page {
  height: 100%;
  padding: 18px 0 10px;

  &:has(.tab-content) {
    padding: 0 0 10px;
  }

  .tab-content {
    padding-top: 18px;
  }
}
