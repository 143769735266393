@import 'mat/styles/includes';

.common-additional-fields-form {
    padding: 0 20px 0 20px;
    height: calc(100% - 65px);

    .common-header {
        width: 100px;
        position: absolute;
        top: 0;
        right: 0;
    }

    .additional-fields-columns-header {
        margin-bottom: 10px;
        height: 35px;
        background: #ddd;
        font-size: 15px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .column {
            width: 250px;
            text-align: center;
        }
    }
}
