@import "mat/styles/includes";

.create-asset-type-step-two-dialog {
  .modal-dialog {
    width: $dialog2ColumnsWidth;

    .modal-body {
      .tab-container {
        @include flex-row();
        min-height: 350px;
        padding-top: 10px;
      }
      .list-wrapper {
        max-height: 340px;
        overflow: auto;
        width: 200px;
        background-color: white;
      }
      .property-item-details {
        flex: 1;
        padding: 10px 0 10px 20px;
        .property-name {
          font-weight: bold;
          margin-bottom: 10px;
        }
        .input-section {
          width: calc(50% - 30px);
          margin-right: 10px;
        }
      }
    }
  }
}



