@import 'mat/styles/includes';

.scheduler-tasks-page {
  position: relative;
  padding: 0 15px;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title,
    .gantt-link {
      font-size: 24px;
      font-weight: 700;
      padding: 17px 0;
      text-transform: uppercase;
      color: #000;
    }

    .gantt-link {
      display: flex;
      align-items: center;
    }
    
    .gantt-link:hover {
      text-decoration: none;
    }
    
    .arrow-right {
      height: 15px;
      margin-left: 5px;
      
      path {
        stroke: black;
        stroke-width: 0.5;
        fill: #000 !important;
      }
    }
  }

  .scheduler-grid-wrapper {
    flex: 1;
    margin-top: 20px;
    display: flex;
    flex-flow: column;
    align-items: stretch;
  }
}
