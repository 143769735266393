@import 'infrastructure/styles/theme';

.manager-view {
  height: 100%;

  .manager-view__kpis-container {
    .common-kpi {
      margin: 5px 20px 5px 0;
      min-width: 150px;
      &:first-child {
        border-left: none;
      }
      .content .item {
        vertical-align: baseline;
      }
      .left-side {
        font-weight: bold;
        display: inline-flex;
        margin-right: 10px;
        .pl {
          font-size: 16px;
          &:before {
            vertical-align: baseline;
          }
        }

        span:first-child {
          order: 2;
        }
        span:last-child {
          order: 1;
          margin-right: 5px;
        }
      }
      .right-side {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }

  .manager-view__title {
    padding: 10px 30px;
    font-size: 18px;
    font-weight: bold;
    background-color: $BGColor30;
  }

  .manager-view__items {
    height: calc(100% - 100px);
    padding: 0 15px;
    overflow: auto;
    background-color: $BGColor30;
  }
}
