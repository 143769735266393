@import 'mat/styles/includes';

.inventory-dashboard-page {
  position: relative;
  padding: 0 0 0 0;
  overflow: scroll;

  .pl-content-tabs {
    height: 47px;
    + .tab-content {
      height: calc(100% - 47px);
    }
  }

  .kpis-container {
    background-color: #EEEEEE;
    .common-kpi {
      margin-right: 20px;
    }
    .common-kpi:first-child {
      border-left: none;
    }
  }

  .charts-container {
    height: 470px;
    background-color: #EEEEEE;
    display: flex;
  }

  .bottom-comment {
    margin: 40px 0 0 20px;
  }
}
