@import "infrastructure/styles/theme";

.edit-in-place {
  $EditorInputWidth : 209px;

  &.button{
    width : 120px;
  }

  min-height: 36px;
  line-height: 35px;
  width: $EditorInputWidth;

  //input {
  //  //padding-right: 70px;
  //  //vertical-align: top;
  //}

  > label {
    //background-color: #c8fdff;
    display: inline-block;

    min-height: 36px;
    width: 95%;
    //line-height: 36px;
    padding-left: 12px;

    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;


    border: 1px solid transparent;
    line-height: 36px;
    height: 36px;

    &.editable:hover {
      border: 1px solid #E0E2E3;
    }
    .label-tooltip{
      width:100%;
      display:inline-block;
    }
    .popover-target{
      margin-left: 7px;
      padding-top: 11px;
      position: absolute;
    }
  }


  &:active .btn-save,
  &:active .btn-cancel {
    display: inline;
  }

  .buttons-container {
    height: 0;
    display: inline-block;
    position: relative;
    float: right;
    width: inherit;
    vertical-align: top;

    z-index: 2; // fix for comboboxes.


    > div {
      position: absolute;
      width: 100px;
      left: 100%;
      height: 0;
    }

    .btn {
      letter-spacing: normal;
      width: 25px;
      height: 25px;
      margin-bottom: 1px;
      line-height: 100%;
      position: relative;
      left: -60px;
      font-size: 11px;
      background-color: $BGColor11;
    }

    .btn-save {
      margin-left: 5px;
      color: $FontColor1;
      background-color: $FontColor5;

      &:hover {
        color: $FontColor1;
        background-color: $BGColor6;
      }

      &:disabled {
        opacity: 1;
        color: $FontColor14;
        background: $BGColor11;
      }
    }

    .btn-cancel {
      background-color: $BGColor11;

      &:hover {
        color: $FontColor6;
        background-color: $BGColor11;
        border: 1px solid $BorderColor9;
      }
    }
  }


    .inner-component-wrap {

      //display: inline-block;
      //width: 100%;

      z-index: 1;

      & > .PL-textField {
        //background-color: yellow;
        width: $EditorInputWidth;
        &.button{
          width : 120px;
        }


        input {
          padding-right: 70px;
          //text-overflow: ellipsis;
        }

      }

      & > .PL-textAreaField{
        width: $EditorInputWidth;
        textarea {
          padding-right: 70px;
        }
      }

      & > .pl-combobox {
        //display: flex;
        width: $EditorInputWidth;

        .pl-select__input > input {
          max-width: 100px;
        }

        .pl-select__control .pl-select__value-container {
          line-height: 24px;
          padding-left: 10px;
          //padding-right: 32px;
          //height: 36px;
        }

        .pl-select__menu .pl-select__menu-list  .pl-select__option {
          padding: 0 5px;
        }
      }


      & > .pl-datepicker {
        //width: 150px;

        .react-datepicker__input-container input {
          background: none;
          padding-right: 90px;
          padding-left: 12px;
        }

      }


      & > .time-value-wrapper {
        width: $EditorInputWidth;
        height: 36px;

        // sub-inputs.
        input {
          height: 34px;
        }
      }

    }

}









