@import "infrastructure/styles/generic";
@import "infrastructure/styles/theme";

.pl-checkbox {
  display: inline-block;
  position:relative;
  line-height:16px;
  height:18px;
  vertical-align: middle;
  .checkbox-input {
    width:0;
    height:0;
    display:inline;
    vertical-align:middle;

    input[type="checkbox"] {
      opacity:0;
      margin:0;
      top:0;
      width:18px;
      position:absolute;
      height:18px;
      vertical-align:middle;
    }
  }

  input[type="checkbox"] + label {
    cursor: pointer;
    vertical-align: middle !important;
    display: inline-block;
    background-color: white;
    border:1px solid $BorderColor13;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    text-align:center;
    i{
      margin-top: 1px;
      font-size:14px;
      display:none;
    }
  }

  input[type="checkbox"]:checked + label i{
    color: $FontColor10;
    display:block;
  }

  input[type="checkbox"]:disabled + label {
    background-color: $FontColor14;
  }
  input[type="checkbox"]:focus + label {
    border-color: $BorderColor9;
  }

  .checkbox-label{
    margin-left: 14px;
    cursor: pointer;
  }
}


