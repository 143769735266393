@import 'mat/styles/includes';

.scheduling-automation-view {
  height: 100%;
  display: flex;
  flex-flow: column;

  .common-header {
    margin: 15px 0;
  }
}
