@import 'mat/styles/includes';

.smart-selection-params-section {
  border-bottom: 2px solid #eee;
  padding: 20px 30px;
  background-color: $BGColor11;
  display: flex;

  .input-section.inline {
    max-height: 120px;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    width: 250px;

    > label {
      white-space: nowrap;
      overflow-x: hidden;
    }
    > span {
      width: 100%;
    }

    &:nth-child(1) {
      margin-left: 0;
      width: 170px;
    }
    &:nth-child(2) {
      position: relative;
      width: 200px;
    }
    &:nth-child(4) {
      width: 150px;
    }

    &.double {
      width: 440px;
    }

    .wo-loading-overlay {
      top: 23px;
      left: 100px;
      transform: scale(0.75);
    }
  }

  & > .btn {
    margin: 22px 0 0 10px;
  }

  //#smartSearch.btn {
  //  margin-top: 22px !important;
  //  margin-left: 20px;
  //  position: absolute;
  //}

  .react-datepicker-popper {
    z-index: 3;
  }
  .multi-select-field {
    min-height: 36px;
    max-height: 85px;

    .select-row.select-option-row.bold {
      font-weight: bold;
    }
  }

  .select-material-label {
    display: flex;
    span {
      margin: 0;
    }
    span:nth-child(1) {
      margin-right: auto;
    }
    .label-extra-component {
      text-transform: none;
      margin-left: 5px;
    }
  }

  .pl-combobox .pl-select__value-container {
    max-height: 86px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .pl-select__multi-value__label {
    width: 100%;
  }
  .pl-select__multi-value {
    width: 90%;
  }
  .pl-substitute-material-mark {
    font-size: 12px;
    display: inline-block;
    margin-right: 5px;
  }
}
