@import "mat/styles/includes";


.create-printer-dialog{
  .modal-dialog {
    width: $dialogBig1ColumnWidth;

    .pl-combobox .pl-select__multi-value .pl-select__multi-value__label {
      max-width: 125px;
    }

    .padding-top {
      padding-top: 26px;
    }
  }
}


