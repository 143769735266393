@import 'mat/styles/includes';

.progress-dashboard-page {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  position: relative;

  .pl-content-tabs {
    height: 50px;
  }

  .tab-content {
    //height: calc(100% - 47px);
    > div {
      height: 100%;
      > div {
        height: 100%;
      }
    }
  }

  .common-kpi.total-wos-kpi {
    position: absolute;
    top: 53px;
    right: 0;
    min-width: 250px;
  }

  .kpis-container {
    margin-top: 10px;
    .common-kpi {
      margin-right: 20px;
    }
    .common-kpi:first-child {
      border-left: none;
    }
  }

  .progress-overlay {
    position: relative;
    height: calc(100% - 115px);
  }
  .progress-chart {
    position: relative;
    height: calc(100% - 115px);
    margin-left: 20px;
  }

  .cause-table-section {
    padding: 20px 0 10px 0;
    height: calc(100% - (93px + 93px));
    margin-left: 20px;
    //.title {
    //  font-weight: 700;
    //  font-size: 18px;
    //  text-transform: uppercase;
    //}
    .title {
      font-weight: 700;
      font-size: 18px;
      text-transform: uppercase;
    }
  }

  .bottom-comment {
    margin: 20px 0 0 20px;

  }

}
