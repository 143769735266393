@import "mat/styles/includes";

.pl-grid {
  .length-cell {
  }
}








