.shift-type {
  position: absolute;
  cursor: move;
  border-radius: 4px;
  z-index: 1;
  height: 100%;

  &.shift-started {
    opacity: 0.5;
  }

  &.shift-changed {
    border: 2px solid;
  }

  &.shift-selected {
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.48), inset 0 0 99px 0 rgba(255, 255, 255, 0.4);
    border: solid 1px #000;
  }

  &.shift-from-last-week {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
