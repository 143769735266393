@import "mat/styles/includes";

.kit-type-operation-list-item {
  @include flex-column();

  .column.time-field-mandatory {
    .time-value-wrapper {
      width: 110px;
    }
  }
  .column:not(:last-child) {
    margin-right: 10px;
  }

  .main-area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .column:first-child {
      flex: 0 1 50px;
      text-align: center;
      font-weight: bold;
      color: #4ea5b5;
    }
    .column:nth-child(2) {
      flex: 0 0 calc(50% - 40px);
    }
    .tool-required {
      flex: 0 0 110px;
    }
    .pl {
      color: $IconColor7;
      width: 16px;
      font-size: 16px;
      &.pl-arrow-down {
        font-size: 10px;
      }
      &.error-icon {
        color: $IconColor3;
        font-size: 21px;
        margin-left: 10px;
      }
    }
  }

  .expanded-area {
    display: none;
    flex-direction: column;
    padding: 10px 0;
    margin: 10px 0 0 60px;
    background-color: #ffffff;
    border: 1px solid #4ea5b5;

    .expanded-area-row {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      background-color: #ffffff;

      .column {
        @include flex-column();
        justify-content: flex-start;

        .short-text-field {
          width: 110px;
        }

        &.wide-2 {
          flex: 0 1 calc( (100% - 40px) / 5 * 2 + 10px);
        }
      }
    }
    &.expanded {
      display: flex;
    }
  }

  .pl-combobox .pl-select__menu {
    .pl-select__menu-list {
      max-height: 150px;
    }
  }
}
