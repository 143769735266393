@import "mat/styles/includes";

.change-location-page {
  background-color: $BGColor2;

  .content {
    padding: 30px 20px 0 20px;
    font-size: 20px;

    .location-input {
      label {
        margin-bottom: 10px;
      }
      .pl-combobox.select-location {
        width: 100%;
        height: 47px;
        border: 1px solid $BorderColor6;
        .pl-select__control {
          height: 45px;
          font-size: 18px;
        }
      }
      .barcode-input-error {
        height: 30px;
        text-align: center;
        color: $FontColor20;;
      }
    }

    button {
      width: 100%;
      height: 50px;
      font-size: 20px;
      color: white;
      text-transform: none;
      background-color: $BGColor13;
    }
    .location-image {
      display: inline-block;
      position: absolute;
      bottom: 50px;
      left: calc(50% - 30px);

      span {
        font-size: 80px;
        color: #56CADC;
        margin: 0 auto;
      }
    }
  }
}





