@import "mat/styles/includes";

.about-dialog{
  .modal-dialog {
    width: $dialogBig1ColumnWidth;
  }

  .top-margin {
    margin-top: 20px;
  }
}


