.misplaced-material-form {
  height: 100%;

    & > label {
      margin-bottom: 20px;
    }

  .input-section {
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-bottom: 20px;

    &:nth-child(2), &:nth-child(3) {
      label:first-child {
        display: none;
      }
    }

    label {
      flex: 0 0 auto;
      margin-right: 10px;
    }

    label span {
      vertical-align: middle;
      margin-left: 13px;
      font-size: 16px;
      line-height: 18px;
      font-weight: 700;
      color: #000000;
      text-transform: none;
    }

    .PL-textField {
      width: 60px
    }

    .entities-multi-select {
      min-width: 200px;
      flex: unset;

      .select-option-row {
        .icon {
          display: none;
        }
        .column {
          width: 100%;
        }
      }
    }

    .PL-textAreaField {
      width: 500px;
    }

    .additional-info {
      display: inline-block;
      color: #707070;
      font-size: 14px;
      line-height: 35px;
      font-weight: bold;
      margin-left: 6px;
    }
  }
}
