// @import "infrastructure/styles/theme";
@import "../../../../../../infrastructure/styles/theme.scss";




/////////////////////////
// Markers - debug.
//
.livemap-page {
  .mappedin-marker {
    
    // background-color: aqua;
    
  }
}





/////////////////////////
// Marker - Full Map.
//
.livemap-page {

  .marker-full-map {
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    
    
    // width: 100px;
    height: 37px;
    background-color: #403f40;

    opacity: 0.9;
    border-radius: 20px 20px 20px 0px;
    padding: 1px 14px 1px 14px ;

    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);


    position: relative;
    top: -17px;
    
    
    .marker-white-dot {
      
      // background-color: aqua;
      
      margin: 0;
      padding: 0;
      
      line-height: 1px;
      font-size: 1px;
      
      width: 5px;
      height: 5px;
      
      position: absolute;
      left: 2px;
      bottom: 1px;
      
    }


    .marker-errors {

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;


      .marker-errors__icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        
        padding-top: 4px;
        
      }

      .marker-errors__count {
        display: inline-block;

        font-family: Roboto;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: start;
        color: #ff476d;
        
        padding-top: 5px;
        padding-left: 1px;
        
      }

    }

    .marker-warnings {
      
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;

      
      .marker-warnings__icon {
        display: inline-block;
        width: 23px;
        height: 23px;

        padding-top: 5px;
        
      }

      .marker-warnings__count {
        display: inline-block;
        // background-color: yellow;
        
        margin: 0 0 0 0px;
        font-family: Roboto;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: start;
        color: #fbae2f;

        padding-top: 5px;
        padding-left: 3px;
        
      }

    }
    
    
    .marker-error-warnings-spacer {
      display: inline-block;
      width: 8px;
    }


    // END .marker-full-map
  }

}




/////////////////////////
// Marker - Close Up.
//
.livemap-page {


  .marker-close-up-layout {
    position: relative;
  }


  .marker-close-up {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;


    // width: 100px;
    max-width: 300px;
    height: 32px;
    // height: 48px; // 32 * 1.5
    
    
    background-color: #403f40;
    

    opacity: 0.9;
    border-radius: 15px 15px 15px 0px;
    padding: 1px 10px 1px 14px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    position: relative;
    top: -16px;


    
    .marker-white-dot {
      // background-color: aqua;
      margin: 0;
      padding: 0;
      
      line-height: 1px;
      font-size: 1px;
      
      width: 5px;
      height: 5px;
      
      position: absolute;
      left: 2px;
      bottom: 1px;
      
    }
    

    .marker-title {
      display: inline-block;

      font-family: Roboto;
      // height: 17px;
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      
      padding-top: 9px;

      &.freezer {
        color: #A0CEEF;
      }

    }


    .marker-errors {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      
      padding-left: 7px;
  
      // background-color: green;

      .marker-errors__icon {
        display: inline-block;
        width: 14px;
        height: 14px;
        
        padding-top: 2px;
        
      }

      .marker-errors__count {
        display: inline-block;
        // background-color: yellow;

        margin: 0;
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #ff476d;
        
        padding-top: 7px;
        padding-left: 4px;

      }

    }

    .marker-warnings {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
  
      padding-left: 6px;


      // background-color: green;

      .marker-warnings__icon {
        display: inline-block;
        width: 14px;
        height: 14px;

        padding-top: 2px;
        
      }

      .marker-warnings__count {
        display: inline-block;
        // background-color: yellow;

        // width: 14px;
        margin: 0 0 0 2px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #fbae2f;
        
        padding-top: 7px;
        padding-left: 3px;


      }

    }
    
    
    .marker-error-warnings-spacer {
      // background-color: aqua;
      display: inline-block;
      width: 0px;
    }



    // END .marker-close-up
  }
  
  
  // Makes marker 2x the size for anchor positioning.
  .marker-close-up-v-spacer {
    line-height: 0;
    height: 0px;
    // height: 32px;
    // background-color: yellow;
  }

}



//////////////////////////////////
// Marker - Close Up Section.
//
.livemap-page {

  .marker-close-up-section {
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    padding-left: 6px;

    
    
    // width: 115px;
    max-width: 300px;
    height: 32px;
    background-color: #fff;

    opacity: 0.9;
    border-radius: 15px 15px 15px 0px;
    padding: 1px 12px 1px 14px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    position: relative;
    top: -16px;


    
    .marker-black-dot {
      // background-color: aqua;
      
      display: block;
      
      margin: 0;
      padding: 0;
      
      line-height: 1px;
      font-size: 1px;
      
      width: 5px;
      height: 5px;
      
      position: absolute;
      left: 1px;
      bottom: 1px;
      
      // opacity: 0.8;
      
    }

    
    
    .marker-title {
      display: inline-block;

      font-family: Roboto;
      font-size: 14px;
      // font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #181717;
      
      padding-top: 9px;

    }

    // END .marker-close-up-section
  }

}



//////////////////////////////////
// Marker - Search result for
// single asset.
//
.livemap-page {
  
  .marker-search-result-pin-single-asset {
    
    // border: 1px solid aqua;
    
    // visibility: hidden; // Hide the marker until we reposition it to proper anchor.
    opacity: 0.01;
    
    // Height = (pin height + pin top offset) * 2 = (52 + 4) * 2 = 116px
    // That makes HTML data-anchor-top = 0;
    height: 116px;
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    
    // position: relative;
    // left: -20px;
    // top: -26px;
    // position: absolute;
    // left: -20px;
    // top: -26px;
    
    
    .marker-icon {
      // background-color: aqua;
      margin-top: 4px;
      width: 40px;
      height: 53px;
    }
    
    
    .marker-bubble-single {
      
      // background-color: yellow;
      
      
      align-self: flex-start;
      margin-left: 7px;
      
      // width: 115px;
      max-width: 300px;
      
      // height: 23px;
      background-color: #403f40;
      
      opacity: 0.9;
      border-radius: 16px;
      padding: 7px 24px 7px 24px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      
      // position: relative;
      // top: -4px;
      
      // border: 5px solid #FFFFFF;
      // top: -9px;
  
  
      .marker-title {
        display: block;
  
        font-family: Roboto;
        font-size: 16px;
        line-height: 19px;
        // font-weight: 500;
        // font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #FFFFFF;
        
        text-transform: uppercase;
  
      }
      
      .marker-asset-info {
        display: block;
        
        margin-top: 1px;
  
        font-family: Roboto;
        font-size: 16px;
        // font-weight: 500;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: left;
        color: #FFFFFF;
        
      }
      
    }
    // END .marker-info
    
  }
  
  // END .marker-search-result-pin-single-asset
}



//////////////////////////////////
// Marker - Search result for
// multi assets.
//
.livemap-page {
  
  .marker-search-result-pin-multi-asset {
    
    // border: 1px solid aqua;
    
    opacity: 0.01;
    
    // Height is twice the needed, to position pin in the vertical center of the marker.
    // 
    // height: 120px;
    
    
    // Height = (pin height + pin top offset) * 2 = (52 + 10) * 2 = 124px
    // That makes HTML data-anchor-top = 0;
    height: 124px;
    
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    
    
    
    
    
    .marker-icon {
      // background-color: aqua;
      margin-top: 10px;
      width: 40px;
      height: 53px;
    }
    
    
    .marker-bubble-multi-asset {
      
      align-self: flex-start;
      margin-left: 8px;
      
      max-width: 300px;
      
      background-color: #403f40;
      
      opacity: 0.9;
      // border-radius: 16px;
      border-radius: 16px;
      padding: 8px 24px 8px 24px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      
      // position: relative;
      // top: -5px;
      
      // border: 6px solid #FFFFFF;
  
  
      .marker-title {
        display: block;
        
        padding-bottom: 6px;
        
        font-family: Roboto;
        font-size: 16px;
        line-height: 19px;
        // font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #FFFFFF;
        
        text-transform: uppercase;
        
      }
      
      .marker-info-count {
        display: inline-block;
        
  
        font-family: Roboto;
        font-size: 26px;
        // font-weight: 500;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: left;
        color: #FFFFFF;
        
      }
      
      .marker-info-type {
        display: inline-block;
        
        margin-top: 1px;
  
        font-family: Roboto;
        font-size: 16px;
        // font-weight: 500;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: left;
        color: #FFFFFF;
        
      }
      
    }
    // END .marker-info
    
  }
  
  // END .marker-search-result-pin-multi-asset
}


//////////////////////////////////
// Marker - Search result for
// single location.
//
.livemap-page {
  
  .marker-search-result-pin-single-location {
    
    // border: 1px solid aqua;
    
    // visibility: hidden; // Hide the marker until we reposition it to proper anchor.
    opacity: 0.01;
    
    // Height = (pin height + pin top offset) * 2 = (52 + 0) * 2 = 104px
    // That makes HTML data-anchor-top = 0;
    height: 104px; 
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    
    
    
    .marker-icon {
      // background-color: aqua;
      // margin-top: 10px;
      width: 40px;
      height: 53px;
    }
    
    
    .marker-bubble-single-location {
      
      // background-color: yellow;
      
      
      align-self: flex-start;
      margin-left: 7px;
      
      max-width: 300px;
      
      background-color: #403f40;
      
      opacity: 0.9;
      border-radius: 16px;
      padding: 7px 24px 7px 24px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      
      // position: relative;
      // top: 5px;
      
      // border: 5px solid #FFFFFF;
  
  
      .marker-title {
        display: block;
  
        font-family: Roboto;
        font-size: 16px;
        line-height: 19px;
        // font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #FFFFFF;
        
        text-transform: uppercase;
  
      }
      
      .marker-asset-info {
        display: block;
        
        margin-top: 1px;
  
        font-family: Roboto;
        font-size: 16px;
        // font-weight: 500;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: left;
        color: #FFFFFF;
        
      }
      
    }
    // END .marker-info
    
  }
  
  // END .marker-search-result-pin-single-asset
}



//////////////////////////////////
// Marker - Search result for
// single section.
//
.livemap-page {
  
  .marker-search-result-pin-single-section {
    
    // border: 1px solid aqua;
    
    // visibility: hidden; // Hide the marker until we reposition it to proper anchor.
    opacity: 0.01;
    
    // Height = (pin height + pin top offset) * 2 = (52 + 0) * 2 = 104px
    // That makes HTML data-anchor-top = 0;
    height: 104px;
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    
    // position: relative;
    // left: -20px;
    // top: -26px;
    // position: absolute;
    // left: -20px;
    // top: -26px;
    
    
    .marker-icon {
      // background-color: aqua;
      // margin-top: 10px;
      width: 40px;
      height: 53px;
    }
    
    
    .marker-bubble-single-section {
      
      // background-color: yellow;
      
      
      align-self: flex-start;
      margin-left: 7px;
      
      // width: 115px;
      max-width: 300px;
      
      // height: 23px;
      // background-color: #403f40;
      background-color: #FFFFFF;
      
      opacity: 0.9;
      border-radius: 16px;
      padding: 7px 24px 7px 24px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      
      // position: relative;
      // top: 5px;
      
      // border: 5px solid #FFFFFF;
  
  
      .marker-title {
        display: block;
  
        font-family: Roboto;
        font-size: 16px;
        line-height: 19px;
        // font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        // color: #FFFFFF;
        color: #403f40;
        
        text-transform: uppercase;
  
      }
      
    }
    // END .marker-info
    
  }
  
  // END .marker-search-result-pin-single-asset
}




