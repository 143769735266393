@import "infrastructure/styles/theme";

.attachments-grid{
  height: calc(100% - 38px);
}








