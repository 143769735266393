@import "mat/styles/includes";

.scan-bottom {
    //background-color: $BGColor13 !important;
    height: 60px;
    width: 100%;

    button.single-btn {
      background-color: $BGColor13;
      color: white;
      font-size: 23px;
      width: 100%;
      height: 100%;
      text-transform: none;
      text-align: center;

      &:focus, &:hover {
        background-color: $BGColor13;
      }
    }
}





