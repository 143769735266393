@import "mat/styles/includes";

.delete-organization-dialog {

  .modal-dialog {width: $dialogBig1ColumnWidth;}

  .delete-confirmation {
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    color: #707070;
    margin-bottom: 20px;
  }

  .comment-textField {
    width: 100%;
  }
}
