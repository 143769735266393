@import "mat/styles/includes";

.scanned-asset-list-item {
  background-color: $BGColor2;
  padding: 15px 20px 15px 60px;
  font-size: 16px;
  border-bottom: 1px solid $BorderColor24;

  label {
    display: inline-block;
    margin-right: 10px;
  }
  .asset-id, .material-type {
    label {
      font-weight: bold;
    }
  }
}





