
.defrosting-label-cell{

  vertical-align: middle;
  display: inline-block;

   label{
     line-height: 20px;
     display: block;
   }
  span{
    display: block;
    line-height: 18px;
    font-size: 12px;
  }

}

