@import "mat/styles/includes";

.pl-grid {

  .common-related-assets-cell {

    position: relative;
    left: -20px;
    width: 60px;
    padding-left: 20px;

    .assets-count {
      margin-right: 8px;
    }

    .pl-info-icon-blue.visible {
      font-weight: bold;
      display: inline-block;
    }

    .pl-info-icon-blue{
      display: none;
      padding-bottom: 1px;
    }

    &:hover {
      font-weight: bold;

      .pl-info-icon-blue{
        display: inline-block;
      }
    }
  }
}

.common-related-assets-popover{
  .asset-id{
    display: inline-block;
    color : $FontColor13;
    //@include ellipsis(50%);
    width:calc(50% - 7.5px);
    min-width: 130px;
  }
  .separator{
    width:5px;
    vertical-align:top;
    line-height:14px;
    display:inline-block;
    margin-left: 5px;
    margin-right: 5px;
  }
  .asset-metadata{
    display: inline-block;
    //color: $FontColor5;
    @include ellipsis(50%);
    width:calc(50% - 7.5px);
    min-width: 130px;
  }

  .operation-date{
    font-size: 12px;
    //color: $FontColor2;
    display: block;
  }

  .related-asset-header{

    line-height: 20px;
    span {
      //text-align: center;
      vertical-align: middle;
    }
  }

  .related-asset-title {
    font-size: 18px;
    margin: 13px 0 7px;
    padding: 0;
    &:first-child {
      margin: 0 0 7px;
    }
  }

  .asset-data{
    margin-top: 5px;

    &.no-details {
      .asset-id {
        width: 100%;
      }
    }
  }
}







