@import "infrastructure/styles/generic";
@import "mat/styles/includes";

.assets-merge-section {

  .using-asset-section {
    .entities-multi-select {
       flex-direction: row !important;
       .entity-type-select {
         margin: 0 !important;
       }
     }
  }


  .assets-to-asset-selection {
    width: 100%;
  }
  .assets-to-asset-selection, .using-asset-selection {
    .select-row.select-value-row {
      .column {
        height: 20px;
        line-height: 20px;
      }
    }
  }
  .assets-to-asset-selection {
    .select-value-row .column.extra-data {
        max-width: 130px;
    }
  }
  .using-asset-selection {
    .select-value-row .column.extra-data {
      max-width: 60px;
    }
  }

  .add-rolls-to-asset-container {
    width: 100%;
    position: relative;
    margin-top: 20px;
    max-height: 270px;

    .rolls-list-title {
      @include flex-row();
      align-items: center;
      margin-bottom: 2px;
      width: calc(100% - 50px);
      justify-content: space-between;

      .column > * {
        margin-right: 10px;
      }

      .column:first-child {
        @include flex-row();
        align-items: center;
        margin-bottom: 2px;

        .add-rolls-selection {
          width: 94px;
        }
      }
      .column {
        .units {
          margin-left: -7px;
          margin-right: 0;
          text-transform: none;
        }
      }

      label {
        margin-bottom: 0;
      }

      .pl-checkbox {
        label.checkbox-label {
          margin-left: 5px;
        }
      }
    }
  }

}
