@import 'mat/styles/includes';

.pm-terminology-settings-view {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;

  .common-header {
    margin: 15px 0;
  }

  .filter-section {
    background-color: #f2f2f2;
    padding: 10px 0;
    .pl-checkbox .checkbox-input {
      top: 7px;
    }
    .pl-checkbox {
      margin-left: 25px;
      margin-top: 3px;
      .checkbox-label {
        margin-left: 5px;
      }
    }
    .pl-combobox {
      margin-left: 10px;
      margin-right: 30px;
      width: 230px;
    }
  }
  .note-section {
    margin-top: 10px;
    visibility: hidden;
    &.on {
      visibility: visible;
    }
  }

}

.terminology-confirm-message{
    white-space: pre-wrap;
    font-size: 15px;
    font-weight: 400;
    color: $FontColor4;
    margin-bottom: 20px;
}

