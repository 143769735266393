@import '../../../../../../../styles/includes';

.tool-type-list-item {
  @include flex-row();

  .pl-combobox .pl-select__menu {
    .pl-select__menu-list {
      max-height: 150px;
    }
  }

  .column {
    flex: 1;
    &:first-child {
      flex: 0 1 332px;
    }
  }
}
