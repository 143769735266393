@import "mat/styles/includes";

.pl-grid {

  .activity-type-cell {

    display: flex;
    align-items: center;

    .icon {
      line-height: 64px;
      vertical-align: middle;
      margin-top: 2px;
      margin-right: 10px;
      width: 15px;
      font-size: 16px;
    }

    .activity-type__icon {
      height: 24px;
      width: 24px;
      margin-right: 5px;
    }

    .activity-type__description {
      display: inline-block;
      width: calc(100% - 32px);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}






