@import "../../../../../../styles/includes";

.file-validate-step {
  padding: 10px 20px;
 @include flex-column();
  height: 100%;

  .import-files-common-grid {
    height: 100% !important;
    margin-top: 0;
  }

  .validation-info-section {
    .validation-info-item {
      margin: 10px 0;

      .validation-info-msg {
        margin-left: 10px;
        display: inline-block;
      }

      .error {
        color: $IconColor3;
      }
      .warning {
        color: $IconColor4;
      }
      .blue-color {
        color: $FontColor5
      }
    }
  }

}
