@import "infrastructure/styles/theme";

$DateBoxMargin: 5px;

.horizon-filter-datepicker-wrapper{
  display: inline-block;
  width: 46%;
  background-color: $BGColor12;
  span {
    color: $FontColor13;
    font-family: $FontFamily1;
    text-transform: uppercase;
    font-size: $FontSize3;
    font-weight: bold;
    margin-left: $DateBoxMargin;
  }
  .horizon-filter-datepicker {
    margin: $DateBoxMargin;
  }
}

.horizon-filter-datepicker-wrapper:not(:last-child) {
  margin-right: 8%;
}

.datepicker-container-with-select {
  display: inline-block;
  width: 66.66%;
}

.filter-name-select-container {
  display: inline-block;
  width: 30%;
  margin-right: 3.33%;

  .pl-combobox {
    width: 100%;
    position: relative;
    top: -22px;
  }
}
