@import "mat/styles/includes";


.edit-wo-attributes-dialog{
  .modal-dialog {width: $dialog2ColumnsWidth;}

  .modal-body{
    max-height: 740px;
    position: static;
    label {
      text-transform: uppercase;
    }

    .modal-dialog-overlay {
      height: calc(100% - 60px);
    }
  }
  .entities-multi-select .entity-type-select {
    width: 135px;
  }

  //to limit the input expanding when typing
  .pl-combobox .pl-select__control .pl-select__input > input {
    max-width: $comboboxInputWidth9;
  }

  .pl-combobox.pl-multi-select-field.entity-value-select {
    .select-value-row {
      .extra-data, .icon{
        width: 0px;
      }
    }
  }

  .react-datepicker-wrapper{
    width: 100%;
  }

  .wo-attributes-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
  }
}
