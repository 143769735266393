@import "mat/styles/includes";

.progress-chart {
  height: 100%;
  .chart-header {
    width: 100%;
    padding: 10px 0;
    //margin-top: 20px;
    //padding-bottom: 10px;
    border-bottom: solid 1px #ccc;

    .chart-header-column {
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      margin-right: 10px;
    }
    .chart-header-column:first-child {
      width: 200px;
    }
    .chart-header-column:nth-child(2) {
      width: 240px;
    }
  }

  .chart-content {
    //height: 500px;
    height: calc(100% - 43px);
    overflow-y: auto;
    overflow-x: hidden;
    @include flex-row();
    align-items: flex-start;

    .table-section {
      display: inline-block;
      margin-top: 28px;
      margin-right: -20px;
      position: relative;
      .item-row {
        //height: 40px;
        height: 30px;

        .entity-id-cell {
          width: 200px;
          white-space: nowrap;
          font-size: 15px;
          display: inline-block;
          padding: 5px 10px 4px 10px;
        }
        //&:hover {
        //  background-color: $BGColor4;
        //}
      }
      .item-row:first-child {
        font-weight: bold;
        color: #4EA5B5;
        > div {
          background-color: #f2f2f2;
          width: 400px;
        }
      }

      .row-planned-indicator {
        height: 30px;
        position: absolute;
        top: 0;
        padding: 5px 10px 4px 10px;
        background-color: #f2f2f2;
        font-weight: bold;
        color: #4EA5B5;
        text-transform: uppercase;
      }
    }
    .chart-section {
      display: inline-block;

      .recharts-legend-wrapper {
        position: fixed !important;
        width: 900px !important;
        left: auto !important;
        bottom: auto !important;
        margin: 0 auto !important;
      }

      &.legend-bottom {
        .recharts-legend-wrapper {
          bottom: 40px !important;
        }
      }

    }
  }

  .custom-tooltip {
    padding-bottom: 10px;
    background: $BGColor2;
    border: solid 1px #F3F3F3;
    box-shadow: $BoxShadow;

    .tooltip-title {
      padding: 15px 20px 10px 20px;
      color: #4EA5B5;
      border-bottom: solid 1px #F3F3F3;
    }
    .tooltip-item {
      padding: 10px 20px 10px 20px;
      color: #737373;
      span {
        margin-right: 5px;
      }
      span:nth-child(2) {
        font-weight: bold;
        margin-right: 3px;
      }
    }
    .tooltip-bottom {
      padding: 15px 20px 10px 20px;
      border-top: solid 1px #F3F3F3;
    }
  }

}
