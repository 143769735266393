@import 'mat/styles/includes';

.production-dashboard-page {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 0 0 0;
  overflow: scroll;

  .kpis-container {
    margin-top: 10px;
    .common-kpi {
      margin-right: 20px;
    }
    .common-kpi:first-child {
      border-left: none;
    }
  }

  .production-overlay {
    position: relative;
    height: calc(100% - (155px + 93px));
  }

  .production-chart {
    position: relative;
    height: calc(100% - (155px + 93px));
    margin-left: 20px;
  }

  .bottom-comment {
    margin: 40px 0 0 20px;
  }
}
