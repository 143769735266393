@import "mat/styles/includes";

.component-with-error-cell {
  @include flex-row();
  align-items: center;

  .alert-icon {
    display: inline-block;
    //margin-right: 10px;
    width: 15px;
    &.pl:before {
      vertical-align: baseline;
    }
  }
  .error-message {
    margin-left: 10px;
  }
}

.pl-grid .ag-root .ag-row .ag-cell .ag-react-container:has(.component-with-error-cell) {
  z-index: unset;
}








