@import "mat/styles/includes";

.right-top-component-container {
  color: $FontColor8;

  .separator {
    background-image: linear-gradient(115deg, #fff 0px, #fff 13px, $BGColor13 14px, #fff 15px, #fff 16px);
    background-size: 40px auto, 40px auto;
    height: 30px;
    width: 16px;
    vertical-align: text-bottom;
  }

  .count {
    font-size: 28px;
    color: $FontColor9;
  }

  .inner-text-count {
    font-size: 14px;
    line-height: 14px;
    display: inline-block;
    text-transform: uppercase;
    /*margin-left: -2px;*/
  }

}
