@import "infrastructure/styles/theme";

.wo-header{
  height:35px;
  display: flex;

  .title{
    font-size: 18px;
    font-weight: 700;
    color: $FontColor15;
    display: inline-block;
    margin-top: 5px;
    margin-right: auto;
  }

  .header-buttons {
    display: inline-block;
  }

  .operations-buttons {
    display: flex;
    button {
      padding-left: 15px;
      margin-left: 9px;
    }
  }
}
