@import "mat/styles/includes";

.popup-notifications-item {
  border: 1px solid #EBEBEB;
  padding: 0 10px 0 15px;
  line-height: 18px;

  .popup-notifications-row {

    min-height: 40px;
    padding: 10px 0 10px 0;

    .popup-icon-cell{
      width: 25px;
      display: inline-block;
      float: left;

      label.pl{
        font-size:9px;
        vertical-align: top;
        margin-top: 3px;
        padding-left: 3px;
        &:focus{outline:none;}
        &:hover{}
        &:active{}
      }

      .unread{
        color: $IconColor3;
      }
    }

    .popup-content-cell{
      display: inline-block;
      width: 285px;
    }

    .popup-time-cell{
      display: inline;
      float: right;

      label {
        vertical-align: top;
      }
    }
  }

  &:hover {
    background: #EBEBEB;

    .popup-content-cell{
      label{
        font-weight: bold;
      }
    }

    .popup-icon-cell{
      label.pl{
        margin-top: 0;
        padding-left: 0;
        font-size: 15px;
        border-radius: 50%;
        background: $IconColor1;
        cursor: pointer;
      }
    }
  }
}

.status-icon{
  margin-right: 10px;
  display: inline-block;
  line-height: inherit;
  float: left;
  font-size: 16px;
}

.notifications-item-popover {
  font-weight: bold;
  width: auto;
  white-space: nowrap;
  .popover-content {
    padding-top: 10px;
  }
}
