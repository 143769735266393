@import "mat/styles/includes";

.add-remove-list-container-ext {
  padding-right: 5px;
  position: relative;
  &.list-ext-overflow {
    max-height: calc(100% - 105px);
    min-height: calc(100% - 105px);
    overflow-y: auto;
    overflow-x: hidden;
    //background-color: lavenderblush;
    position: relative;
  }
  .add-remove-list {
    padding: 0 15px 10px 0;

    .list-item {
      @include flex-row();
      align-items: center;
      margin-top: 10px;

      .move-buttons-wrapper {
        display: flex;
      }

     .item-btn {
       color: $FontColor10;
        margin-left: 5px;
        cursor: pointer;
        padding: 5px;

        &.add-below-btn {
          font-size: 18px;
          padding: 0 5px 0 0;
          height:24px;
          width:24px;
          transform: scaleY(-1);
        }
        &.remove-btn{
          padding: 0 1px 0 0;
          height:24px;
          width:24px;
          border: 1px solid transparent;
        }
        &.move-btn{
          padding: 0 1px 0 0;
          height:24px;
          width:24px;
          border: 1px solid transparent;
        }
        &:hover {
          border: 1px solid $BorderColor15;
        }
        &:active {
          background-color: $BGColor12;
          border: 1px solid $BorderColor15;
        }
      }

      .drag-handle {
        margin-left: 5px;
        cursor: pointer;

        .drag-handle-icon {
          color: #892461;
        }
      }
    }
    .list-item:first-child {
      margin-top: 0;
    }
  }
  .add-row, .remove-all {
    margin-top: 20px;
  }
  .remove-all {
    float: right;
  }
}
