@import "mat/styles/includes";


$SideFiltersEnabled: false;
$DataDisplayBorder: 1px solid #EDEDED;
//$DataDisplayBorder: 1px solid #a5a5a5;


// TOP NAVIGATOR ICON WIDTH FIX FOR NOTIFICATIONS ICON.
.navigation .location-section .navigator-main-icon.pl-notifications-line {
  font-size: 38px;
  //width: 42px;
}



.notifications-page-container {
    position: relative;
    @include flex-row();


  // ARE SIDE-FILTERS ENABLED YET?
  @if $SideFiltersEnabled {
    // YES.
    .side-filters-menu {
      height: 100%;
      min-width: 250px;
    }
  }
  @else {
    // NO.
    padding-right: 140px;
    .side-filters-menu {
      height: 100%;
      width: 120px;
    }
  }

}


.notifications-page-container .content-view {
  //background-color: greenyellow;

  width: 100%;
  padding: 0 40px 20px 57px;

  padding-top: 23px;
  height: calc(100% - 23px);


  .top-header {
    //background-color: springgreen;
    color: $FontColor23;
    min-height: 35px;

    @include flex-row();
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 18px;
      font-weight: 700;
    }

  }

  .data-display {
    //background-color: yellow;
    width: 100%;
    height: calc(100% - 53px);
    margin-top: 18px;
    overflow-y: auto;


    border-top: $DataDisplayBorder;
    //border-bottom: $DataDisplayBorder;

  }


}









