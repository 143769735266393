@import "infrastructure/styles/theme";

.kitting-materials-custom-cell {
  height: 30px;
  .blue-color {
    color: $FontColor5
  }

  .purple-color{
    color: $FontColor10
  }

  .gray-color{
    color: $FontColor12
  }

  .error {
    color: $IconColor3;
  }
  .warning {
    color: $IconColor4;
  }

  .pl-check-circle-icon-blue{
    margin-top: 2px;
    margin-right: 10px;
    display: inline-block;
    line-height: inherit;
  }
  span.truncate-to-fit{
    display: inline-block;
    width: calc(100% - 50px);

    .material-name{
      max-width: calc(100% - 15px);
      margin-right: 5px;
      vertical-align: middle;
    }

    .pl-substitute-material-mark {
      display: inline-block;
      line-height: inherit;
      margin-right: 5px;
    }
    .alert-icon {
      margin-right: 0;
    }
  }






}

