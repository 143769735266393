@import "infrastructure/styles/theme";

.pl-toggle-button-group {
  @include flex-row();
  margin-bottom: 10px;
  justify-content: space-between;

  width:300px;
  .btn {
    height: 35px;
    font-weight: 500;
    line-height: 35px;
    background-color: $BGColor10;
    color: $FontColor11;
    padding: 1px 10px;
    border-radius: unset;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid $BorderColor21;
    &:hover, &:focus {
      border-bottom: 2px solid $BorderColor6;
      box-shadow: none;
    }
    &:focus, &:active {
      outline: none !important;
    }
    &:active {
      border-bottom: 2px solid $BorderColor6;
      color: $BGColor10;
      background-color: $BGColor5;
    }
  }

  .btn-default.active {
    background-color: $BGColor5;
    border-bottom: 2px solid $BorderColor6;
    color: $BGColor10;
    box-shadow: none;
    border-radius: unset;
  }

  .toggle-button{
      width: 100%;
  }
  .pl-error-icon{
    color: $FontColor20;
    padding-left: 5px;
    line-height: 16px;
  }

}


