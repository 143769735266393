@import "mat/styles/includes";

.asset-details {
  padding: 20px 30px;

  .asset-details-section {
    background-color: $BGColor2 !important;
    display: flex;
    width: 100%;
    padding: 20px 0;

    hr {
      border-top: 1px dashed $BGColor29;
      opacity: 1;
    }

    .value {
      > span:not(:last-child) {
        margin-right: 15px;
      }
    }
    .in-freezer {
      background-color: $BGColor32;
      font-size: 12px;
      padding: 0 5px;
    }
    .error {
      color: $IconColor3;
    }
    .warning {
      color: $IconColor4;
    }
    .info {
      color: $IconColor7;
    }
    .alert-icon {
      margin: 0 0 0 5px;
      &:before {
        vertical-align: baseline;
      }
    }
    .PL-textAreaField {
       height: 36px;
    }

    .link-details {
      max-width: 205px;
      height: 36px;
      padding-top: 8px;
    }

  }
}
