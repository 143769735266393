@import "mat/styles/includes";

.upload-files-dialog {
  .modal-dialog {
    width: 640px;

    .upload-file {
      color: $Color7;
      font-weight: 500;

      .body-top-section.title {
        margin-bottom: 20px;
        & > div {
          &:first-child {
            margin-right: 15px;
          }
        }
      }

      .body-title {
        display: block;
        font-size: 18px;
      }

      .body-comment {
        margin-top: 3px;
        font-size: 14px;
      }

      .settings-link {
        margin-left: 5px;
        cursor: pointer;
      }

      &.import-type-wide {
        .pl-combobox.import-type-selection {
          width: 175px;
        }
        .file-chooser-selection .PL-textField {
          width: 250px;
        }
      }

      .material-types-body-note {
        font-weight: bold;
      }
    }
  }
}

.error-selection {
  margin-top: 15px;

  .error-item {
    display: table;
    line-height: 18px;
    //width: 420px;

    &:not(:first-child) {
      margin-top: 15px;
    }

    .pl:before {
      vertical-align: text-top;
    }

    .error {
      color: $IconColor3;
    }
    .warning {
      color: $IconColor4;
    }

    .error-icon {
      font-size: 16px;
      display: table-cell;
      width: 25px;
    }

    .error-msg {
      font-size: 15px;
      display: table-cell;
      vertical-align: top;
      line-height: 17px;
    }

  }
}





