@import "../../../../styles/includes";

.create-container-dialog.dialog {

  .modal-dialog {width: 800px;}

  .modal-body {
    max-height: $modalBodyMaxHeight;
    position: static;

    .entities-multi-select-field .select-value-row .column.extra-data {
      width: 125px;
    }

    .PL-textAreaField {
      width: 100%;
    }

    .wo-section {
      @include flex-row();

      .input-section {
        //margin-top: 0;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      //wo combo box
      .select-row.select-value-row .column:first-child {
        width: 100%;
        vertical-align: middle;
      }

      .entities-multi-select {
        .pl-select__option .select-option-row > :first-child {
          width: 0;
        }

        .pl-select__option .select-option-row > :not(:first-child) {
          width: calc(100% - 15px);
        }
      }
    }

    .attach-assets-from-wo {
      display: inline-block;
      margin-top: 5px;
      margin-left: 3px !important;
    }
  }

  .modal-footer {
    .footer-checkbox {
      display: inline-block;
      margin-left: 20px;
      .checkbox-label {
        margin-left: 5px;
      }
    }
  }
}

