@import "../../../../../../infrastructure/styles/theme";

.panel-root {
  display: flex;
  flex-flow: row;
  //border:thin solid $BorderColor1;
  margin: 10px 0;
  //padding: 5px;

  .multi-select-field {
    width: 100%;
  }

  .input-section {
    display: flex;
    flex-flow: row;
    align-items:  center;
    margin-right: 10px;

    &.goals-section {
      min-width: 300px;
    }

    label {
      display: flex;
      margin: 0 5px;
    }
  }

  .settings-section {
    margin-left: auto;
  }

  .mode-select {
    width: 115px;
  }
}
