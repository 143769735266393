@import "mat/styles/includes";

.afp-operation-wizard {
  .modal-dialog {
    height: 760px;
    margin: 0;

    .validation-area .validation-area-content {
      max-height: 72px;
    }
  }
}
