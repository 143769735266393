@import "mat/styles/includes";

.production-chart {
  height: 100%;
  .chart-header {
    width: 100%;
    padding: 10px 0;
    border-bottom: solid 1px #ccc;

    .column {
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      margin-right: 10px;
    }
    .column:first-child {
      width: 200px;
    }
    .column:nth-child(2) {
      width: 240px;
    }
  }

  .chart-content {
    height: calc(100% - 43px);
    overflow: auto;

    @include flex-row();
    align-items: flex-start;

    .table-section {
      display: inline-block;
      margin-top: 32px;
      .item-row {
        height: 30px;

        .entity-id-cell {
          width: 200px;
          white-space: nowrap;
          font-size: 15px;
          display: inline-block;
          padding: 5px 10px 4px 10px;
        }
        .cell {
          font-size: 15px;
          display: inline-block;
          padding: 5px 10px 4px 10px;
          span:first-child {
            display: inline-block;
            margin-right: 10px;
            //font-weight: bold;
            text-align: right;
            width: 50px;
          }
          span:nth-child(2) {
            color: $FontColor16;
          }

        }
      }
    }

    .chart-section {
      display: inline-block;

      .recharts-legend-wrapper {
        position: fixed !important;
        left: auto !important;
        bottom: auto !important;
        margin: 0 auto !important;
      }

      &.legend-bottom {
        .recharts-legend-wrapper {
          bottom: 40px !important;
        }
      }
    }

  }

  .custom-tooltip {
    padding-bottom: 10px;
    background: $BGColor2;
    border: solid 1px #F3F3F3;
    box-shadow: $BoxShadow;

    .tooltip-title {
      padding: 15px 20px 10px 20px;
      color: #4EA5B5;
      border-bottom: solid 1px #F3F3F3;
    }
    .tooltip-item {
      padding: 10px 20px 10px 20px;
      color: #737373;
      span {
        margin-right: 5px;
      }
      span:nth-child(2) {
        font-weight: bold;
        margin-right: 3px;
      }
    }
  }

  .custom-legend {
    font-size: 11px;
    text-transform: uppercase;
    &.pl:before {
      margin-right: 5px;
      color: #367A87;
      font-size: 16px;
    }
  }
}
