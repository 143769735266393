.misplaced-material-alerts-grid {
  height: 100%;
  margin-top: 18px;

  .pl-grid .ag-root .ag-row .ag-cell,
  .multi-rows-cell {
    display: flex;
    line-height: 32px;
  }

  .pl-grid .ag-root .ag-row .ag-cell {
    align-items: center;
  }

  .multi-rows-cell {
    align-items: flex-start;
  }
}
