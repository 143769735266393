@import "infrastructure/styles/theme";

.kitting-grid{
  height: calc(100% - 122px);

  .ag-cell {
    line-height:30px !important;
    padding: 20px;
  }

  a{
    color: $FontColor5;
  }
}







