@import 'mat/styles/includes';

.scanned-tool-page {
  position: relative;
  .tag-section {
    min-height: 80px;

    .multi-barcode-error {
      font-size: 20px;
      color: #d9385c;
    }

    .multi-barcode-input-add {
      font-size: 30px;
    }
    .items-list {
      padding: 15px 0;

      .tag-item {
        height: 45px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        background-color: #efefef;
        border: 1px solid #4da5b5;
        align-items: center;
        margin-bottom: 10px;

        &.invalid {
          border: 1px solid #d9385c;
          .tag-item-remove {
            background: none;
            color: #d9385c;
          }
          .icon {
            color: #d9385c;
            margin-right: 5px;
          }
        }

        .tag-item-name {
          padding: 0 10px;
        }
        .tag-item-remove {
          width: 35px;
          height: 100%;
          border-radius: 0;
          border: none;
          font-size: 30px;
        }
      }
    }
  }
}
