@import "infrastructure/styles/theme";

.authentication-page {
  .login-row{
    display: inline-block;
  }

  .login-error {
    text-align: left;
    height: 0;
    opacity: 0;
    color: $FontColor20;
    transition: margin .3s, height .3s, opacity .3s;
    margin-left: 46px;

  }
  .btn.btn-primary{
    transition: margin .3s;
    margin-top: 30px;
  }
  &.has-error {
    .btn.btn-primary{margin-top:0px; }
    .login-error {
      margin-top: 17px;
      height: 43px;
      opacity: 1;
    }
  }
  .footer-section{
    width: 400px;
    display: block;
    padding-left: 20px;
    .send-code-btn{
      padding-top: 22px;
      background: white;
      border: none;
      outline: none;
      color: $FontColor5;
      display: block;
      padding-left: 0px;
    }
    span{
      display: block;
      text-align: left;
    }
  }
}



