@import "mat/styles/includes";

.create-organization-dialog {
  .modal-dialog {
    width: $dialogBig1ColumnWidth;
  }
  .pl-warning-icon  {
    line-height: 20px;
    margin: 0 6px;
  }
  .edit-note {
    font-weight: bold;
    font-size: 14px;
    color: #707070;
    margin-top: 10px;
  }
}
