.shift-scheduler-grid-container {
  display: grid;
  grid-template-columns: 215px repeat(7, minmax(0, 1fr));

  .grid-column-header-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #888888;
    position: sticky;
    top: 0;
    z-index: 2;
    color: #fbfbfb;
    font-weight: bold;
  }

  .grid-row-header-cell {
    display: flex;
    align-items: center;
    padding-left: 16px;
    text-align: left;
  }

  .grid-item {
    height: 45px;

    &:not(:last-child) {
      border-right: 1px solid #888888;
    }

    &.grid-column-header-cell {
      border-right: 1px solid #ffffff;
    }

    &.grid-spacer {
      position: sticky;
      top: 0;
      display: flex;
      align-items: center;
      padding-left: 16px;
      text-align: left;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      background-color: #d8d8d8;
    }
  }

  .grid-cell {
    position: relative;
    border: 0px solid transparent;
    transition: border 100ms ease-in-out;

    &.highlighted-cell {
      border: 2px solid #000;
      & > * {
        pointer-events: none;
      }
    }

    &.highlighted-row {
      border-top: 2px solid #000;
      border-bottom: 2px solid #000;
    }

    &.highlighted-column {
      border-left: 2px solid #000;
      border-right: 2px solid #000;
    }

    &.highlighted-all {
      border: 2px solid #000;
    }

  }

  .grid-row-wrapper {
    display: contents;

    &:nth-child(odd) .grid-item {
      background-color: #e9e9e9;
    }
  }

  .grid-headers-row-wrapper {
    display: contents;

    .grid-item {
      height: 60px;
    }
  }
}
