@import "mat/styles/includes";

.alert-icon-cell {

  text-align: center;
  display: block;
  height: 30px;
  .pl:before {
    vertical-align: text-top;
  }

  .error {
    color: $IconColor3;
  }
  .warning {
    color: $IconColor4;
  }
  .info {
    color: $IconColor7;
  }

  .alert-icon {
    display: inline-block;
    margin-right: 25px;
    width: 15px;
  }
}







