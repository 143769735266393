.single-line-multi-select {
  height: 36px;
  width: 100%;
  background-color: #fff;

  &.disabled {
    .input-wrapper {
      border: none !important;
    }
    .item {
      opacity: 0.4 !important;
    }
    .options-list {
      display: none;
    }
  }

  .input-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 5px;
    border: 1px solid transparent;

    &.selected {
      border: 1px solid #4da5b5;
    }

    &:hover:not(.selected) {
      border: 1px solid #d1d1d1;
    }

    .selected-options {
      display: flex;
      .other-selected-options {
        padding-left: 5px;
        display: flex;
        align-items: center;
        color: #4da5b5;
        cursor: pointer;
        &:hover {
          font-weight: bold;
        }
      }
    }

    .input {
      border: none;
      outline: none;
      padding: 0 15px;
    }
  }
}

.editor {
  &.active {
    .single-line-multi-select {
      .input-wrapper {
        &.selected {
          border: none;
        }
      }
      .options-list {
        border: 1px solid #4da5b5;
      }
      .all-items-list {
        .items {
          border: 1px solid #4da5b5;
        }
      }
    }
  }
}

.details-row:has(.single-line-multi-select) {
  .disabled {
    opacity: 1;
    pointer-events: all;
  }
}
