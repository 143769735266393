@import "mat/styles/includes";

.pl-grid {

  .multi-row-cell-container {
    align-items: center;
    display: block;

    .alert-icon {
      display: inline-block;
      margin-right: 5px;
      width: 15px;
      &.pl:before {
        vertical-align: baseline;
      }
    }
    .main-message {
      display: inline-block;
    }
    .secondary-message {
      display: block;
      font-size: 13px;
      height: 30px;
    }
    .empty-message {
      display: block;
      height: 30px;
    }
    .error-message {
      max-width: calc(100% - 20px);
    }
  }
}







