@import "mat/styles/includes";

.create-asset-type-step-one-dialog {
  .modal-dialog {
    width: $dialog2ColumnsWidth;
  }

  .parent-natures-list {
    .nature-list-item {
      margin-bottom: 10px;
    }
  }

  .multiple-items {
    width: calc(50% - 15px);
    display: inline-block;
    margin: 18px 0 0 30px;
    .input-section:last-of-type {
      margin-top: 25px;
    }
    .half-width {
      width: calc(50% - 15px);
    }

    .pl-combobox .pl-select__menu .pl-select__menu-list {
      padding: 10px;
      max-width: 137px;
      .pl-select__option {
        display: inline;
        float: left;
        width: 30px;
        .pl:before {
          vertical-align: middle;
        }
      }

    }
  }

}
