@import "mat/styles/includes";

.user-roles-cell{
  height: 30px;

  span {
    max-width: 100%;
    display: inline-block;
    @include ellipsis(calc(100%));
  }
}
