//


.search-controls-wrapper {

  // background-color: aqua;
  background-color: #fff;

  display: block;
  width: 400px;
  // height: 150px;
  // max-height: 80%;
  // overflow: hidden;
  
  padding: 16px 15px 24px 16px;


  border-radius: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

}


.search-controls {
  display: block;
  // background-color: aqua;
}



////////////////////////////
// Search Input
//
.search-controls {
  .search-input-wrapper {
    display: block;
    padding-right: 9px;

    
    .search-input {

      margin: 0;
      padding: 0;
      display: flex;
      
      width: 100%;
      height: 36px;
      padding: 4px 15px 0px 10px;
      border-radius: 18px;
      background-color: #f5f5f5;
      
      
      svg {
        position: relative;
        width: 30px;
        height: 30px;
        
        top: -1px;
        left: -2px;
        
        // opacity: 0.4;
      }


      input {
        
        margin: 0;
        padding: 0;
        
        &::placeholder {
          color: #BDC3C8;
        }
        
        &:focus {
          outline: none;
        }
        
        width: 100%;
        height: 26px;
        
        padding-left: 3px;
        // padding-top: 0px;
        
        
        font-family: Roboto;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        
        
        border: none;
        background-color: transparent;

      }  


    }


  }
}






