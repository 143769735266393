.password-field-container {
  position: relative;
  vertical-align: middle;
}

.password-reveal-icon {
  position: absolute;
  right: 10px;
  top: 12px;
  transform: scale(1.2);
  cursor: pointer;
  &.with-error {
    right: 41px;
  }
}
