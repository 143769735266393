
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight:normal;
  font-display: swap;
}


@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight:bold;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
  font-weight:100;
  font-display: swap;
}




