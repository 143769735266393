@import "mat/styles/includes";

.alert-popover {
  .popover-content {

    .alert-icon {
      margin-right: 10px;
      &.hint, &.help {
        color: $IconColor2;
      }
      &.error {
        color: $IconColor3;
      }
      &.warning {
        color: $IconColor4;
      }
      &.info {
        color: $IconColor7;
      }
       &.default, &.success {
        color: $IconColor5;
      }
    }

    .alert-popover-component {
      div {
        margin-bottom: 5px;
      }
    }
  }
  &.default, &.success {
    border-color: $Color2;
    @include arrow-border-color($Color2);
  }
  &.error {
    border-color: $BorderColor7;
    @include arrow-border-color($BorderColor7);
  }
  &.warning {
    border-color: $BorderColor14;
    @include arrow-border-color($BorderColor14);
  }
  &.info {
    border-color: $BorderColor6;
    @include arrow-border-color($BorderColor6);
  }
  &.hint, &.help {
    border-color: $BorderColor15;
    @include arrow-border-color($BorderColor15);
  }
}

.popover-target {
  .alert-icon {
    &.hint, &.help {
      color: $IconColor2;
    }
    &.error {
      color: $IconColor3;
    }
    &.warning {
      color: $IconColor4;
    }
    &.info {
      color: $IconColor7;
    }
    &.default, &.success {
      color: $IconColor5;
    }
  }
}

