@import "mat/styles/includes";

.main-page {

  .main-menu {
    padding: 20px;
    .main-menu-item {
      display: block;
      margin-bottom: 20px;
      padding: 20px;
      border: 1px solid $BorderColor6;
      text-align: center;
    }
    font-size: 20px;
  }
}





