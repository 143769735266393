@import "infrastructure/styles/theme";

.admin-grid{
  height: calc(100% - 20px);
  padding-top: 20px;
}








