@import 'infrastructure/styles/theme';

.task-page {
  display: flex;
  flex-direction: column;
  .scan-header {
    flex-grow: 0;
    flex-shrink: 0;
    .btn-middle.scan-header-item {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .task-page-task-info {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
    .scan-details-section {
      height: 100%;
    }
  }

  .task-page-buttons {
    padding: 10px 5% 0 5%;
    flex-grow: 0;
    flex-shrink: 0;
    .btn.btn-primary.task-page-button {
      height: 60px;
      width: 100%;
      margin-bottom: 10px;
      font-size: 23px;
    }
  }

  .task-page-modal-header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    color: #fff;
  }

  .mobile-modal-content {
    .main-menu-item {
      height: 60px;
      width: 100%;
      margin-bottom: 10px;
    }
    .completed-quantity-section {
      margin: 15px 0;
      font-weight: bold;
      text-align: center;

      .completed-quantity__label {
        text-transform: uppercase;
      }
      .completed-quantity__value {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;

        .PL-textField {
          width: 120px;
          margin-right: 10px;
        }
      }
    }

    .update-btn {
      margin-top: 180px;
    }
  }

  .task-page-modal-button {
    height: 60px;
    width: 100%;
    margin-bottom: 10px;
    color: #fff;
  }
}

@media screen and (max-width: $MobileMaxWidth) {
  #globalMessageDialog {
    #viewDetails {
      display: none;
    }

    .buttons-container {
      margin: 0;
    }
  }
}
