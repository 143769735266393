// @import "infrastructure/styles/theme";
@import "../../../../infrastructure/styles/theme.scss";


// @import "mat/styles/includes";
// .app-container > .app-container-content > .livemap-page{
//   height: 100%;
// }




/////////////////////////////////////////
// Page specific styles
//
.livemap-page {
  // position: relative;
  // padding: 0 0 0 0;
  // overflow: scroll;
  height: 100%;
  overflow: scroll;


  .live-map-wrap {
    position: relative;
    height: 100%;

    #maptarget {
      background-color: rgb(224, 224, 224);
      position: relative;
      width: 100%;
      height: 100%;
    }

  }

}



/////////////////////////////////////////
// Map overlays positioning
//
.livemap-page {

  // Position Search Controls component.
  .live-map-wrap .live-map-overlay-search-controls {
    position: absolute;
    top: 22px;
    left: 24px;
    z-index: 2;

  }

  // Position Camera Presets component.
  .live-map-wrap .live-map-overlay-camera-presets {
    position: absolute;
    top: 17px;
    right: 14px;
    z-index: 2;

  }

  // Position Level Selector component.
  .live-map-wrap .live-map-overlay-level-chooser {
    position: absolute;
    top: 90px;
    right: 24px;
    z-index: 2;

  }


}


.livemap-page {
  .mappedin__smart-collision-engine__container {
    transform-style: preserve-3d;
    // transform-style: unset;
  }
}

.livemap-page {
  .mappedin-marker {
    
    // background-color: aqua;
    
  }
}
