@import "infrastructure/styles/theme";

.latency-calc {

  label {
    display: block;
    text-align: left;
    margin: 0px 30px;
  }

  .description {
    margin: 20px 20px;
  }

  .btn.start {
    margin-top: 20px;
  }

  .wait, .error {
    margin: 0px;
    position: absolute;
    width: 100%;
    text-align: center;
    font-weight: bold;
    text-align: center;

  }

  .error {
    color: $IconColor3;
  }

  .result {
    font-weight: bold;
    margin-top: 30px;
    text-align: center;
    font-size: 20px;
  }


}



