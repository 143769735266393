.side-navigator {
  position: relative;
  height: 100%;
  background-color: #403f40;

  .side-menu {
    width: 359px;
    height: calc(100vh - 64px);
    background-color: #403f40;
    position: absolute;
    top: 0px;
    outline: 0px;
    left: 0px;
    transform: translateX(-359px);
    visibility: hidden;
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms, visibility 225ms;
    overflow-y: auto;
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    &.expanded {
      z-index: 99999;
      transform: none;
      transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms, visibility 225ms;
      visibility: visible;
    }

    a.settings-button,
    a.settings-button::after,
    a.settings-button:active {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #fff;
    }

    .settings-button {
      width: 227px;
      height: 38px;
      padding: 8px 22px 8px 22px;
      border-radius: 19px;
      background-color: #5d5d5d;
      margin: 30px auto;

      &:hover {
        background-color: #000;
      }

      &:active, &.selected{
        background-color: #4da5b5;
      }

      .settings-button-icon {
        width: 22px;
        height: 22px;
        margin-right: 8px;
      }

      span {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  .side-bar {
    display: flex;
    flex-flow: column;
    height: 100%;

    .side-bar-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      width: 64px;
      height: 64px;
      cursor: pointer;

      .icon {
        width: 22px;
        height: 22px;
      }

      &:hover {
        background-color: #5d5d5d;
      }

      &:active, &.selected {
        background-color: #4da5b5;
      }

      &:last-child {
        margin-top: auto;
      }
    }
  }
}
