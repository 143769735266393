@import "infrastructure/styles/theme";

.tool-more-info-cell {
  line-height: 31px;
  label {
    vertical-align: middle;
    text-align: left;
    display: block;
    color: $FontColor16;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width:100%;
  }

  span {
    vertical-align: top !important;
    color: $FontColor17;
  }
}
