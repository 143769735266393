@import "infrastructure/styles/theme";

.entities-multi-select-hidden {
  &.button{
    width : 120px;
  }

  display: flex;
  flex: 1;
  min-height: 36px;

  > .label-wrapper {
    display: inline-block;
    color: #878787;
    min-height: 36px;
    width: 100%;
    padding-left: 12px;

    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;


    border: 1px solid transparent;
    line-height: 36px;
    height: 36px;

    &.editable {
      background-color: #fff;
      border: 1px solid #E0E2E3;
      text-transform: capitalize;
    }

    &.editable:hover {
      border-color: #4ea5b5;
    }
  }

  .inner-component-wrap {
    flex: 1;
    z-index: 1;
  }

}









