@import 'infrastructure/styles/theme';
.activity-log-dialog {
  .modal-dialog {
    width: 1250px;

    .modal-body {
      padding-bottom: 0;

      .activity-log-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .activity-log-header__label {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
  }

  @media (hover:none), (pointer:coarse) {
    .modal-dialog {
      width: 90dvw;

      .activity-log-dialog-grid, .tasks-assignments-grid {
        height: 340px;
      }
    }
  }
}


