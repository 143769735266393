@import "infrastructure/styles/theme";

.login-page {

  .login-error, .sso-error {
    text-align: left;
    height: 0;
    opacity: 0;
    color: $FontColor20;
    transition: margin .3s, height .3s, opacity .3s;
  }
  
  .btn.btn-primary{
    transition: margin .3s;
    margin-top: 30px;
  }

  &.has-error {
    .btn.btn-primary{}
    .login-error {
      margin-top: 17px;
      height: 20px;
      opacity: 1;
      width: 300px;
    }
  }

  &.has-sso-error {
    .sso-error{
      margin-top: 17px;
      height: 20px;
      opacity: 1;
      width: 300px;
    }
  }

}