@import "mat/styles/includes";


//.tethered-select-dropdown {
  .hide-select-menu-outer {
    .pl-select__menu {
      display: none;
    }
  }
//}

.simple-input {
  position: relative;
  background-color: white;

  i.loading {
    position: absolute;
    left: 50%;
    top: 8px;
    font-size: 20px;
  }
}

