@import 'infrastructure/styles/mixin';


$G_MinimumAppWidth : 1260px;
$MobileMaxWidth: 450px;

//Theme Colors
//todo: delete this colors when rewrite user management style.
$Color1 : #403f40;
$Color2 : #4ea5b5;
$Color3 : white;
$Color4 : white;
$Color5 : #367a87;//noam
$Color6 : #38636b;//noam
$Color7 : #000000;

$BorderColor1 : #dbdbdb;
$BorderColor2 : #e8eaea;
$BorderColor3 : rgba(92, 104, 110, 0.2);
$BorderColor4 : #ecf0f0;
$BorderColor5 : #626061;
$BorderColor6 : #4ea5b5;
$BorderColor7 : #d9385c;
$BorderColor8 : rgba(136, 144, 148, 0.26);
$BorderColor9 : #367a87;
$BorderColor10 : #38636b;
$BorderColor11 : white;
$BorderColor12 : #a6a6a6;
$BorderColor13 : #c0c5c6;
$BorderColor14 : #e89430;
$BorderColor15 : #7d2853;
$BorderColor16 : #CFD5D6;
$BorderColor17 : #c7c7c7;
$BorderColor18 : #aeafb0;
$BorderColor19 : #d9dbdb;
$BorderColor20 : #9C9E9F;
$BorderColor21 : #A0A0A0;
$BorderColor22 : #cfcfcf;
$BorderColor23 : #E8E6E6;
$BorderColor24: #efefef;
$BorderColor25: #CECECE;
$BorderColor26: #CCCCCC;


$FontColor1 : #fefefe;
$FontColor2 : #a6a6a6;
$FontColor3 : #ffffff;
$FontColor4 : #767576;
$FontColor5 : #4da5b5;
$FontColor6 : #367a87;
$FontColor7 : #38636b;
$FontColor8 : #5F5F5F;
$FontColor9 : #565454;
$FontColor10 : #7d2853;
$FontColor11 : #707070;
$FontColor12: #c7c7c7;
$FontColor13: #000000;
$FontColor14 : #b3b3b3;
$FontColor15 : #3D3C3D;
$FontColor16 : #849095;
$FontColor17 : #403f40;
$FontColor18 : #4A5960;
$FontColor19: #49c5db;
$FontColor20 : #d9385c;
$FontColor21 : #EBEBEB;
$FontColor22 : #303030;
$FontColor23 : #3F3F3F;

$BGColor1 : #403f40;
$BGColor2 : #ffffff;
$BGColor3 :#707070;
$BGColor4 :#f7f6f6;
$BGColor5 : #4da5b5;
$BGColor6 : #367a87;
$BGColor7 : #38636b;
$BGColor8: #e9ebed;
$BGColor9: #ecf0f0;
$BGColor10: #efefef;
$BGColor11: #EEEEEE;
$BGColor12: #d9d9d9;
$BGColor13 : #7d2853;
$BGColor14 : #c7c7c7;
$BGColor15 : #b3b3b3;
$BGColor16 : #F7F7F7;
$BGColor17 : rgba(255, 252, 252, 0);
$BGColor18 : rgba(5, 5, 5, 0.08643159999999998);
$BGColor19 : #d9385c;//tamar
$BGColor20 : rgba(255, 255, 255, 0.9);
$BGColor21 : rgba(0, 0, 0, 0.5);
$BGColor22 : #F1F1F2;
$BGColor23 : rgba(5, 5, 5, 0.03313726);
$BGColor24 : rgba(255, 252, 252, 0);
$BGColor25 : #C7C7C9;
$BGColor26: #2f2f2f;
$BGColor27: #181717;
$BGColor28: #F4F4F4;
$BGColor29: #D1D1D1;
$BGColor30: #F1F1F1;
$BGColor31: #555555;
$BGColor32: #EDF6F7;
$BGColor33: #515051;
$BGColor34 : rgba(255, 255, 255, 0.4);
$BGColor35 : rgba(255, 255, 255, 0.8);
$BGColor36 :#E3000E;
$BGColor37 :#E9E8E8;
$BGColor38: #ededed;
$BGColor39: #EBEBEB;
$BGColor40: #f2f2f2;


$IconColor1: #ffffff;
$IconColor2: #7d2853;
$IconColor3 : #d9385c;
$IconColor4 : #e89430;
$IconColor5: #49c5db;
$IconColor6: #E9E8E8;
$IconColor7: #4ea5b5;

$BoxShadow : 2px 2px 2.91px 0.09px rgba(76, 76, 76, 0.16);
$h1 : 26px;
$h2 : 24px;
$h4 : 15px;
$h5 : 14px;


$FontSize1: 14px;
$FontSize2: 10px;
$FontSize3: 11px;

$Dialog_FontSize1 : 20px;

$FontFamily1 : "Roboto";



$dialog1ColumnWidth : 470px;
$dialogBig1ColumnWidth : 600px;
$dialog2ColumnsWidth : 700px;
$dialogWidth4 : 900px;

$modalBodyMaxHeight: 750px;

$comboboxInputWidth1: 88px;
$comboboxInputWidth3: 200px;
$comboboxInputWidth4: 230px;
$comboboxInputWidth5: 260px;
$comboboxInputWidth6: 330px;
$comboboxInputWidth7: 370px;
$comboboxInputWidth8: 430px;
$comboboxInputWidth9: 480px;

$assetTypeWidth1: 95px;

$ZIndex-DatepickerPopper: 10;

.fa-ellipsis-h:before { //SAAS-13500: Please let Eshel know if there is a better place to put this
 font-weight: lighter;
}
