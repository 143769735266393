@import "mat/styles/includes";

.add-remove-list-container {
  .add-remove-list {
    padding: 0 15px 5px 0;
    &.list-overflow {
      overflow-y: auto;
      overflow-x: hidden;
    }

    .list-item {
      @include flex-row();
      align-items: center;
      margin-top: 10px;
      color: $FontColor10;
      span.remove-btn {
        margin-left: 5px;
        cursor: pointer;
        padding: 5px;
        border: 1px solid transparent;

        &:hover {
          border: 1px solid $BorderColor15;
        }
        &:active {
          background-color: $BGColor12;
          border: 1px solid $BorderColor15;
        }
      }
    }
    .list-item:first-child {
      margin-top: 0;
    }
  }
}
