@import "mat/styles/includes";

.additional-field-list-item {
  @include flex-row();
  line-height: 18px;
  .short-textfield {
   margin-right: 30px;
  }

  .pl-combobox {
    width: 220px;
  }
}






