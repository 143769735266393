@import "../../../../../../styles/includes";

.update-attributes-preview-step {
  display: flex;
  height: 100%;

  .assets-list-container {
    padding: 10px 10px;
    .assets-list {
      height: 100%;
      width: 240px;
      overflow-x: hidden;
      overflow-y: auto;

      .asset-list-item {
        display: flex;
        align-items: center;
        padding: 10px 15px;

        .fa-circle, .fa-circle-o, .pl {
          color: $IconColor7;
        }
        .item-data {
          flex-basis: 150px;
          margin: 0 20px;
          cursor: default;
        }
        .pl.remove-btn {
          font-size: 10px;
          opacity: 0;
          cursor: pointer;
          padding: 3px;
        }

        &.selected {
          font-weight: bold;
        }

        &:hover {
          background-color: #EBEBEB;;
          .fa-circle, .fa-circle-o, .pl {
            color: $BGColor6;
          }
          .pl {
            opacity: 1;
          }
        }
      }

    }
  }

  .attributes-preview-container {
    padding: 10px 30px;
    border-left: 1px solid #4ea5b5;
    flex:1;
    position: relative;

    .attributes-preview-list {
      max-height: 400px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .attribute-line {
      display: flex;

      div {
        flex:1;
        margin: 10px 0;
        &:not(:last-child) {
          font-weight: bold;
          margin-right: 15px;
        }
      }
    }
  }
}
