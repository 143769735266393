@import "mat/styles/includes";

.datepicker-overlay {
  width: 355px; //main width, subject to change
  max-width: none;
  height: 420px;
  max-height: 450px;
  font-family: $FontFamily1;

  &.popover, .popover.popover_default {
    border-width: 1px;
    border-color: $BorderColor13;
    box-shadow:$BoxShadow;
  }

  .popover-content {   //temp here - remove horizontal scrolls
    overflow: hidden;
    .popover-component {
      overflow: hidden;
    }
  }

  &.popover.bs-popover-bottom  .popover-arrow {
    &::after {
      top:1px;
    }
    &::before {
      border-bottom-color: $BorderColor13;
    }
  }

  &.popover.bs-popover-top > .popover-arrow {
    &::after {
      bottom:2px;
    }
    &::before {
      border-top-color: $BorderColor13 !important;
    }
  }

  .popover-content {
    width: 100%;
    height: 100%;
    max-height: none;
    margin: 0;
    padding: 0;

    .popover-title {
      margin: 0;
    }
    .popover-component {
      max-height: 100%;
    }
    .pl-datepicker-overlay .react-datepicker {
      border: none;
      box-shadow: none;
    }
  }
}
.datepicker-label {
  input {
    border: none;
    height: 36px;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  .alert-icon-component {
    display: inline-block;
    position: absolute;
    left: 90px;
  }

  .popover-target {
    display: inline-block;
    position: absolute;
    left: 90px;
    margin: 0 5px;
    .pl:before {
      vertical-align: text-top;
    }
  }

  display: inline-block;
  position: relative;

  min-height: 36px;
  width: 209px;
  padding-left: 12px;

  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;


  border: 1px solid transparent;
  line-height: 36px;
  height: 36px;
  &.disabled{
    pointer-events: none;
  }
  &.editable:hover {
    border: 1px solid #E0E2E3;
  }
  &.editmode {
    border: 1px solid $BorderColor6;
  }

}
