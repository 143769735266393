@import 'infrastructure/styles/theme';


.security-message-dialog{
  .modal-header {
    button.close {
      display: none;
    }
  }
  .modal-body{
    white-space: pre-line;
    line-height: 25px;
  }

  .security-message-link{
    display: block;
  }

  .pl-checkbox{
   line-height: 50px;
  }
}
