@import "mat/styles/includes";

.on-hand-inventory-header {
  padding: 20px 30px;
  background-color: $BGColor11;
  border-bottom: 2px solid #eee;

  .input-section.inline {
    max-height: 120px;
    display: inline-block;
    width: 250px;
    vertical-align: top;

    &:not(:first-child) {
      margin-left: 20px;
    }
  }

  .btn {
    margin-top: 22px !important;
    margin-left: 20px;
  }

  .multi-select-field {
    width: 100%;
    min-height: 36px;
    max-height: 85px;
  }

  .pl-combobox .pl-select__value-container {
    max-height: 86px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .pl-select__multi-value__label{
    width: 100%;
  }
  .pl-select__multi-value{
    width: 90%;
  }
}
