@import "infrastructure/styles/theme";

.materials-custom-cell {

  .main-material {
    height: 30px;
  }
  .substitute-material{
    max-width: calc(100% - 30px);
    height: 25px;
  }

  .pl-substitute-material-mark {
    &:before {
      margin: 0 5px 0 10px;
    }

    line-height: 25px;
    color: $FontColor12;
  }
  .info{
    color: $FontColor14;
    font-size: 14px;
    line-height: 14px;
  }
}

