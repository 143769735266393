@import "mat/styles/includes";

.app-loader {
  position: fixed;
  width: 100%;
  height:100%;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  height:100%;
  z-index:9999;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  color: $FontColor1;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  line-height: 25px;
  .indication-bar{
    i{
      position:absolute;
      font-size:50px;
      top:calc(50% - 25px);
      color:$FontColor15;
    }
  }

  &.on {
    visibility: visible;
    opacity: 1;
    background:rgba(255, 255, 255, 0);
    .indication-bar{
      height: 25px;
    }
    label {
      -webkit-animation: breathing 2s ease-out infinite normal;
      animation: breathing 2s ease-out infinite normal;
    }
  }
}

