@import "infrastructure/styles/theme";

.update-kit-plies-form {
  height: 100%;
  padding: 20px;
  position: relative;

  .pl-tabs-container {
    margin: 20px 0;
    width: calc(100% - 150px);
    height: calc(100% - 200px);
    min-height: 35px;
    &.no-bags {
      .pl-tabs-header {
        display: none;
      }
    }
    .tab-content {
      height: calc(100% - 32px);
      .tab-pane {
        height: 100%;
        padding: 0 2px 2px 2px;
      }
    }

    .nav-link {
      font-size: 16px !important;
      line-height: 16px !important;
      .nav-link_title {
        display: flex;
        align-items: center;
        .kitted {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
    }

    .tab-content {
      background-color: #eeeeee;
    }
  }

  textarea.barcode-hidden-input {
    //height: 60px;
    //width: 100px;
    height: 0;
    width: 0;
    border: 0;
    padding: 0;
  }

  .kit-plies {
    height: 100%;
    padding: 3px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow: auto;
  }

  .scan-status {
    display: inline-block;
    margin-top: 15px;
    padding: 3px;
    color: #333333;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    border: 1px solid #dadada;
    cursor: pointer;
    background-image: url('infrastructure/assets/svg/scan-disabled.svg');
    background-position: left center;
    background-position-x: 5px;

    background-repeat: no-repeat;
    .scan-status-label {
      display: inline-block;
      width: 110px;
      margin-left: 30px;
      &:after {
        content: 'CLICK TO SCAN';
      }
    }
  }

  .scan-status:focus-within {
    color: $IconColor2;
    background-image: url('infrastructure/assets/svg/scan.svg');
    cursor: default;
    border: 1px solid transparent;
    .scan-status-label {
      &:after {
        content: 'READY TO SCAN';
      }
    }
  }

  .plies-total_title {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
  }

  .plies-total {
    span {
      margin-right: 10px;
    }
  }

}
