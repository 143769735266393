
.PL_carousel {
    width: 100%;
    height: 100%;
    position: relative;

  .PL_carousel-indicators {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 25px;
    width: 100%;
  }

  .indicator {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    margin: 0 5px;
    background-color: rgba(256, 256, 256, 0.3);
    cursor: pointer;
  }

  .indicator.active {
    background-color: #fff;
  }
}
