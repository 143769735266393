@import "mat/styles/includes";

.csv-property-list-item {
  @include flex-row();
  align-items: center;

  .column-1 {
    width: 150px;
    text-align: center;
    line-height: 36px;
  }
  .column-2 {
    width: 200px;
    margin-right: 10px;
  }
  .column-3 {
    width: 300px;
  }

  .pl-combobox .pl-select__menu {
    .pl-select__menu-list {
      max-height: 150px;
    }
  }
  .pl-combobox {
    .mark-as-mandatory {
      font-weight: bold;
    }
  }
}

.pl-select__menu-portal {
  .mark-as-mandatory {
    font-weight: bold;
  }
}






