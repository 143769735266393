@import "mat/styles/includes";

.last-triggers-panel {
  position: absolute;
  top: 30px;
  right: 0;
  width: 450px;
  z-index: 10000;
  padding: 15px;

  border: 2px solid #4EA5B5;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  color: #333333;

  .last-triggers-panel__header {
    font-size: 21px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .last-triggers-panel__body {
    position: relative;
    min-height: 50px;
  }

  .trigger-item {
    display: flex;
    margin: 10px 0;
    >:not(:first-child) {
      margin-left: 10px;
    }
  }

  .no-triggers-placeholder {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
  }
}
