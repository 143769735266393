@import "mat/styles/includes";

.search-component {
  display: inline-block;
  vertical-align: middle;

  .expanded-search {
    height:35px;
    display: inline-block;
    position: relative;

    $selectTypeWidth:125px;
    $selectValueWidth:220px;

    .pl-combobox .pl-select__menu .pl-select__menu-list {
      max-height: unset;
    }

    .entity-type-select {
      &.pl-combobox {
        width: $selectTypeWidth;
        border-color:$BorderColor6;
      }
    }

    .simple-input {
      width: 220px;
      input {
        width: 85% !important;
        &.form-control {
          height: 34px;
        }
      }
    }

    //to limit the input expanding when typing
    .pl-combobox .pl-select__control .pl-select__input > input {
      max-width: $comboboxInputWidth3;
    }

    .entity-value-select{
      width: $selectValueWidth;
    }

    .results-container {
      border: 1px solid $BorderColor13;
      border-top: 0px solid transparent;
      background: $BGColor2;
      position: absolute;
      z-index: 700;
      max-height: 230px;
      //width: calc((#{$selectTypeWidth}) + (#{$selectValueWidth}) - 2px);
      width: calc((#{$selectTypeWidth}) + (#{$selectValueWidth}));
      padding-top: 7px;

      .results {
        max-height: 185px;
        overflow: auto;

        a {
          &, &:active, &:hover {
            text-decoration: none;
            color: $FontColor11;
          }
        }

        .result {
          padding: 2px 10px;
          height: 30px;
          line-height: 30px;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:hover {
            background: $BGColor8;
          }
          b {
            color: $FontColor13;
          }

          &.result-header {
            font-weight: bold;
            cursor: default;
          }
          &.no-results {
            padding-top: 0px;
            color: $FontColor16;

            &:hover {
              cursor: default;
              background: $BGColor2;
            }
          }
        }
      }

      .result-bottom {
        background: $BGColor8;
        line-height: 35px;
        font-weight: bold;
        padding-left: 10px;
        cursor: default;
      }
    }
  }
  *:focus {
    outline: none;
  }
}

