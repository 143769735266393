@import 'mat/styles/includes';

.common-alerts-view-container {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;


  .common-alerts-view-header {
    min-height: 35px;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;

    .common-alerts-view-title {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 700;
    }

  }

  .top-common-alerts-view-tabs-section {
    height:calc(100% - 45px);
    >div{height:100%;}
    .tab-content{
      margin-left:0;
      margin-right:0;
      height:calc(100% - 70px);

      .common-alerts-form {
        display: inline-block;
        width: 100%;
        padding: 20px 0;

        .common-alerts-form-button-save {
          padding-left: 13px;
          position: absolute;
          margin: 15px 0;
          top: 0;
          right: 0;
        }

        .common-alerts-form-enable-alerts-section {
          padding: 17px 0 39px 0;
          border-bottom: 2px solid #eee;
          span {
            vertical-align: middle;
            margin-left: 13px;
            font-size: 16px;
            line-height: 18px;
            font-weight: 700;
          }
        }

        .common-alerts-form-default-values-container {
          padding: 20px 0 50px 0;
          border-bottom: 2px solid #eee;

          .common-alerts-form-default-values-header {
            display: flex;
            flex-direction: row;
            flex: 1;
            justify-content: space-between;
            align-items: center;
            padding: 17px 0 17px 0;

            .common-alerts-form-default-values-title {
              font-size: 18px;
              font-weight: 700;
            }

          }

          .common-alerts-form-default-values-section {
            @include flex-row();

            .input-section:first-child {
              margin-right: 50px;
            }

            .PL-textField {
              width: $comboboxInputWidth1;
            }

            .additional-info {
              display: inline-block;
              color: #707070;
              font-size: 14px;
              line-height: 35px;
              font-weight: bold;
              margin-left: 6px;
            }
          }
        }
      }

      .tab-pane.active {
        height:calc(100% - 70px);
      }
    }
  }
}
