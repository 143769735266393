@import "mat/styles/includes";

.merge-operation-list-item {
  @include flex-row();

  .entities-multi-select {
    @include flex-row();

    .pl-combobox.entity-type-select {
      display: none;
    }
  }

  .short-textfield {
    max-width: 80px;
  }
}






