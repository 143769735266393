@import "../../../../../styles/includes";

.task-alert-popover-cell {
  text-align: center;

  .description {
    margin-right: 5px;
  }

  .alert-icon {
    &:before {
      vertical-align: baseline;
    }
  }
}

#schedulerTaskAlertPopover {
  .category {
    font-weight: bold;
    margin-right: 5px;
  }
}





