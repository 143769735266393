@import 'mat/styles/includes';

.kit-type-ply-type-list-item {
  width: 100%;
  display: flex;
  align-items: center;

  .item-index {
    margin-right: 15px;
    font-size: 14px;
    font-weight: bold;
  }

  .entities-multi-select {
    flex: 1 0 200px;

    .pl-select__option .select-option-row > span:first-child {
      display: none;
    }

    .pl-select__option .select-option-row > :not(:first-child) {
      width: 100%;
    }
  }
}

.plytype-select-menu-portal {
  .pl-select__menu {
    position: unset;
  
    .pl-select__option .select-option-row {
      & > :not(:first-child) {
        width: 100%;
        line-height: unset;
      }
  
      .column:first-child {
        display: none;
      }
    }
  }
}