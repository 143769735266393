@import "infrastructure/styles/theme";

.reset-password-page{
  .reset-password-error {

    height: 0;
    opacity: 0;
    color: $FontColor20;
    transition: margin .3s, height .3s, opacity .3s;
    &.success{
      color: black;
    }
  }

  .btn.btn-primary{
    transition: margin .3s;
    margin-top: 30px;
  }
  &.has-error {
    .btn.btn-primary{margin-top:0px; }
    .reset-password-error {
      text-align: left;
      margin-top: 17px;
      height: 45px;
      opacity: 1;
      width: 300px;
    }
  }

}


