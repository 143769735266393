@import 'infrastructure/styles/theme';

.pl-grid {
  .multi-select-filter {
    //width: 334px;


    .pl-combobox.pl-multi-select-field.search-box {
      width: 100%;
    }

    .result.result-bottom {
      background: $BGColor8;
      line-height: 35px;
      font-weight: bold;
      cursor: default;
      margin: 0 -20px 10px -20px;
      padding-left: 20px;
    }
  }
}

