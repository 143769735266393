@import 'infrastructure/styles/theme';

.task-detail-row {
  display: flex;
  height: fit-content;
  border-bottom: 1px solid $BorderColor2;
  background-color: #f7f7f7;

  .left-spacer {
    width: 60px;
    background-color: #f7f7f7;
  }

  .task-detail-content {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 10px 20px;
    height: fit-content;
  }

  @media (hover:none), (pointer:coarse) {
    .task-detail-content {
      justify-content: unset;
    }
  }

  .task-detail-cell {
    width: 160px;
    display: flex;
    flex-flow: column;
    margin-right: 5px;

    label {
      font-size: 15px;
      font-weight: bold;
    }

    .read-only-field {
      height: 36px;
      border: solid 1px #c7c7c7;
      display: flex;
      align-items: center;
      padding: 0 8px;
    }

    &.tool-cell {
      width: 210px;
    }

    &.duration-cell {
      width: 120px;
    }

    &.shifts-cell {
      padding: 10px;
    }

    .shift-assignment {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      & span:first-child {
        font-size: 12px;
        line-height: normal;
        margin-right: 10px;
      }
    }

    .pl-combobox {
      .popover-target {
        display: inline-block;
        width: 20px;
      }
    }
  }
}

.task-detail-tool-option-renderer {
  span {
    display: inline-block;
    width: 20px;
    color: $IconColor4;
  }
  .ellipsis-with-tooltip {
    width: calc(100% - 20px);
  }
}
