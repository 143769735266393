@import 'infrastructure/styles/theme';

.digital-twin-tree {
  .tree-item {

    .leaf-icon {
      margin-right: 6px;
      width: 20px;
      color: #4da5b5;
      font-size: 20px;
    }

    .branch-icon {
      margin-right: 4px;
      width: 20px;
      vertical-align: 0;

      &.pl-arrow-down {
        font-size: 8px;
      }
    }

    .pl:before {
      line-height: 34px;
    }

    .tree-item__actions {
      > span {
        margin-right: 5px;
        color: $FontColor5;
        font-size: 22px;

        &:hover {
          font-weight: bold;
          color: $FontColor6;
        }
      }
    }
  }
}


