@import "mat/styles/includes";

.antenna-list-item {

  @include flex-row();
  align-items: center;

  span.cell {
    &:nth-child(1) {
      max-width: 170px;
    }
    &:nth-child(2) {
      max-width: 130px;
    }
    &:nth-child(3) {
      max-width: 65px;
    }
    &:nth-child(4) {
      max-width: 95px;
    }
    &:nth-child(5) {
      max-width: 90px;
    }
    &.short-cell {
      margin-left: 18px;
    }
  }

  .alert-icon {
    line-height: 21px;
    font-size: 15px;
    margin-left: 10px;
  }
}






