@import "mat/styles/includes";

.activate-workorder-dialog {
  .modal-dialog {
    height: 385px;
    width: $dialogBig1ColumnWidth;

    .input-section {
      margin-top: 0;
    }
  }

  .activate-workorder-confirmation {
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    color: #707070;
    margin-bottom: 20px;
  }

  .comment-textField {
    width: 100%;
  }
}

.activate-workorder-confirm-message {
  .modal-body .line .label {
    font-weight: bold;
    font-size: 20px;
    width: 100%;
    color: $FontColor15;
    padding: 5px 25px 15px 25px;
  }
}
