
.task-tab-form {

  .common-alerts-form-default-values-header {
    color: #7d2853;
  }

  .common-alerts-form-enable-alerts-section {
    border-bottom: none !important;
  }

  //.common-alerts-form-default-values-section {
  //  width: 45%;
  //  .PL-textField {
  //    width: 120px;
  //  }
  //}

}
