@import "infrastructure/styles/theme";

.activity-log-grid{
  height: 100%;
}








