@import "mat/styles/includes";

.asset-alert-popover-cell {
  text-align: center;

  .description {
    margin-right: 5px;
  }

  .alert-icon {
    &:before {
      vertical-align: baseline;
    }
  }

}







