@import "../../../../../../styles/includes";

.pl-grid {

  .label-cell-container {
    @include flex-row();
    align-items: center;

    .alert-icon {
      display: inline-block;
      width: 15px;
      &.pl:before {
        vertical-align: baseline;
      }
    }
    .error-message {
      margin-left: 10px;
    }
    .additional-field{
      display: block;

    }
  }

  .label-cell {
    line-height: 30px !important;

    &.disabled {
      color: $FontColor12;
    }
  }

}







