@import "mat/styles/includes";

$selectTypeWidth:125px;
$selectValueWidth:220px;

.results-container {
  border: 1px solid $BorderColor13;
  border-top: 0px solid transparent;
  background: $BGColor2;
  position: absolute;
  z-index: 700;
  max-height: 230px;
  width: calc((#{$selectTypeWidth}) + (#{$selectValueWidth}) - 2px);
  padding-top: 7px;

  .results {
    max-height: 185px;
    overflow: auto;

    a {
      &, &:active, &:hover {
        text-decoration: none;
        color: $FontColor11;
      }
    }

    .result {
      padding: 2px 10px;
      height: 30px;
      line-height: 30px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        background: $BGColor8;
      }
      b {
        color: $FontColor13;
      }

      &.result-header {
        font-weight: bold;
        cursor: default;
      }
      &.no-results {
        padding-top: 0px;
        color: $FontColor16;

        &:hover {
          cursor: default;
          background: $BGColor2;
        }
      }
    }
  }
}
