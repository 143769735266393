.progress-cell {
  width: 100%;
  padding-right: 20px;
  display: flex;
  align-items: center;

  .pl-progress-bar {
    position: relative;
    width: 100%;
    height: 17px;
    border: solid 1px #d9dbdb;
    background-color: #fff;

    .pl-progress-label {
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 12px;
    }

    .pl-progress{
      height: 100%;
    }
  }
}
