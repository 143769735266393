@import 'infrastructure/styles/theme';
.numeric-multi-cell-grid-editor {
  .editable-cell{
    &.multi-row-cell{
      padding-bottom: 27px;
    }
  }

  .PL-textField  {
    width: 100%;
  /*  padding-left: 5px;*/
    height: 30px;
    font-size: 15px;

    input {
      border-color: #b5b5b5;
    }
  }
}
