@import "infrastructure/styles/theme";

.common-work-orders-grid {
  height: calc(100% - 53px);
  margin-top:18px;

  .ag-cell {

    line-height:30px !important;
    padding: 20px;

    //.ag-react-container {
    //  div, span {
    //    text-overflow: ellipsis;
    //    white-space: nowrap;
    //    overflow: hidden;
    //  }
    //}
  }

  a{
    color: $FontColor5;
  }

  .pl-component-assets{
    font-size: 18px;
    margin-left: -5px;

  }
  .has-assets-cell.pl-check-icon-blue-hover{line-height:30px;}

  .pl-table-assets-location {
    color: $FontColor8;
    //to make the icon lighter
    opacity: 0.7;
  }

  }
