@import "mat/styles/includes";

.edit-task-attributes-dialog{
  .modal-dialog {width: $dialogBig1ColumnWidth;}

  .modal-body {
    max-height: 500px;
    position: static;

    .modal-dialog-overlay {
      height: calc(100% - 60px);
    }

    .entities-multi-select .entity-type-select {
      width: 135px;
    }

    //to limit the input expanding when typing
    .pl-combobox .pl-select__control .pl-select__input > input {
      max-width: $comboboxInputWidth9;
    }

    .pl-combobox.pl-multi-select-field.entity-value-select {
      .select-value-row {
        .column:first-child {
          width: 190px;
        }

        .extra-data, .icon {
          width: 0;
        }
      }

      .pl-select__option .select-option-row > :first-child {
        width: 0;
      }

      .pl-select__option .select-option-row > :not(:first-child) {
        width: calc(100% - 15px);
      }
    }

    .time-value-wrapper {
      width: 105px;
    }

    .short-textfield {
      width: 125px;
      margin-right: 10px;
    }
    .field-info {
      margin-top: 8px;
      display: inline-block;
    }
  }
}
