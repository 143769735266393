@import "mat/styles/includes";

.preferences-page{
  height: 100%;

  .common-header {
    margin: 15px 0;
  }

}





