@import 'mat/styles/includes';

.create-automation-scenario-dialog {
  .modal-dialog {width: $dialog2ColumnsWidth;}

  .modal-body {
   .antennas-type-select {
     width: 110px;
   }
   .antennas-select {
     width: calc( 100% - 110px);

     &.pl-combobox.pl-multi-select-field .pl-select__multi-value {
       max-width: 150px;
       .pl-select__multi-value__label {
         max-width: 126px;
       }
     }
   }

    .qualified-event-section, .action-sequence-section {
      margin: 15px 0;
      border-top: solid 1px #cccccc;
      .input-section {
        margin-top: 10px;
      }
    }

   .feedback-params-section {
     display: flex;
     align-items: center;
     .input-section:first-child {
       .pl-checkbox {
         margin-top: 18px;
       }
     }


     .input-section.inline.small {
        font-size: 14px;
        &:not(:first-child) {
          margin-left: 10px;
        }
      }

     .feedback-title {
       flex: 1;
       margin-bottom: 10px;
     }
  }
}

}
