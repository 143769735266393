.single-line-multi-select-item {
  box-sizing: border-box;
  background-color: #efefef;
  border-radius: 0;
  color: #707070;
  height: 23px;
  display: flex;
  border: solid 1px #d1d1d1;
  align-items: center;
  justify-content: space-between;
  .item-name {
    padding: 0 6px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .item-delete {
    border: 1px solid transparent;
    box-sizing: border-box;
    padding-right: 2px;
    padding-left: 2px;
    height: 23px;
    cursor: pointer;
    flex-shrink: 0;
    color: #7d2853;
    font-weight: bolder;
    &:hover {
      border: 1px solid #aeafb0;
      background: none;
    }
  }
}
