@import "mat/styles/includes";

.related-assets-container {
  padding: 0 30px 20px 30px;
  @include flex-column();
  min-height: 190px;

  .container-title {
    text-transform: uppercase;
  }
  .related-assets {
    position: relative;
    @include flex-row();
    background-color: $BGColor2 !important;
    border-top: 2px solid $BorderColor6;
    padding: 20px 0;

    .column {
      position: relative;
      @include flex-column();
      padding: 0 0 0 25px;

      .title {
        font-size: 14px;
        text-transform: uppercase;
        color: $FontColor2;
        margin-bottom: 10px;

        .units {
          text-transform: none;
          margin-left: 3px;
        }
      }
    }

    .grid-wrapper {
      //flex: 1 0 auto;
      flex: 0 0 40%
    }

    .grid-container {
      display: grid;
      //grid-template-columns: 1fr 1fr;
      grid-template-columns: 60% 40%;
    }

    .grid-column-1 {
      grid-column-start: 1;
      overflow: auto;
      z-index: 1;
    }

    .grid-column-2 {
      grid-column-start: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: auto;
      z-index: 1;
    }

    .assets-list {
      @include flex-column();
      overflow: auto;
      z-index: 1;
    }

    .asset-info {
      &:not(:first-child) {
        padding-top: 10px;
      }
      .equipment-asset-id {
        .asset-id {
          max-width: calc(100% - 120px);
        }
        .has-attached-asset {
          display: inline;
          margin-left: 20px;
          color: $BorderColor6;
        }
      }
      .info-section{
        display: inline-block;
        width: calc(100% - 20px);
      }
      .asset-type{
        display: inline-block;
        margin-right: 5px;
        vertical-align: top;
      }

      .asset-label {
        display: block;
        @include ellipsis(95%);
      }
      .asset-id{
        max-width: calc(100% - 40px);
      }
      .pl{
        line-height: 20px;
        margin-right: 5px;
      }
      .operation-date-wrap {
        margin: 0;
        padding: 0 0 0 5px;
        span {
          font-size: 12px;
          color: $FontColor2;
          display: block;
        }
      }
    }
  }

  .assets-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 100px;
    color: $IconColor6;
    right: 20px;
    z-index: 0;
  }
  .remove-asset-button{
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
  }
  .remove-btn-section{
    display: inline-block;
    vertical-align: top;
    width: 18px;
  }
}

.remove-related-asset-confirm-message {
  .modal-header .modal-title .title {
    text-transform: capitalize;
  }
}

.remove-related-asset-confirm-success-message {
  .modal-header .modal-title .title {
    &::first-letter {
      text-transform: capitalize;
    }
  }
  .modal-body .message-area {
    &::first-letter {
      text-transform: capitalize;
    }
  }
}



