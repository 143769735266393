@import 'mat/styles/includes';

.scheduler-analytic-mode-settings-dialog {
    .modal-dialog {
        width: 600px;
    }

    .tab-headers {
        margin-top: 20px;
        margin-bottom: 10px;
        background: #ddd;
        font-size: 15px;
        @include flex-row();
        align-items: center;
    }

    .tab-wrapper {
        .column {
          margin-left: 15px;

            &:nth-child(1) {
                width: 250px;
            }

            &:nth-child(2), &:nth-child(3) {
                width: 65px;
                text-align: left;
            }
        }
    }

  .entities-list {
    max-height: 400px;
  }
}
