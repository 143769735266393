//

.unselectable {
  user-select: none;
  /* For modern browsers */
  -webkit-user-select: none;
  /* Safari and Chrome */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE 10+ */
}



////////////////////////////
// Search Results - layout
//

.search-controls {
  margin: 0;
  padding: 0;

  .search-results {
    margin: 0;
    padding: 0;

    padding-top: 6px;


    .search-results-scroll-container {
      // background-color: aqua;

      margin: 0;
      padding: 0;


      // max-height: 500px;
      max-height: 65vh;

      // height: 80%;

      overflow-y: auto;
      overflow-x: hidden;



      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;



      scrollbar-gutter: stable;

      // /* Scrollbar width */
      // &::-webkit-scrollbar {
      //   // width: 8px;
      //   /* Adjust as needed */
      //   border-radius: 4px;
      // }

      /* Track (available range) */
      &::-webkit-scrollbar-track {
        background: #E9E9E9;
      }

      // /* Handle (draggable part) */
      // &::-webkit-scrollbar-thumb {
      //   background: #8897A5;
      //   /* Color of the draggable handle */

      //   border-radius: 4px;
      //   // border: 1px solod aqua;
      // }

      // /* Handle on hover */
      // &::-webkit-scrollbar-thumb:hover {
      //   background: #888888;
      //   /* Darker color when hovered */
      // }


      // scrollbar-width: thin; /* Can be 'auto', 'thin', or 'none' */
      // scrollbar-color: #888 #f1f1f1; /* handle color track color */      
      // scrollbar-track-color: aqua;




    }

  }

}





.search-result-by-type-block {
  margin: 0;
  padding: 0;

  width: 100%;

  padding-right: 4px;
  padding-top: 16px;


  .search-result-block__title {
    margin: 0;
    padding: 0;

    display: block;

    padding-left: 5px;

    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #403f40;

    cursor: pointer;


    .title-text {
      margin: 0;
      padding: 0;
    }

    .title-separator {
      margin: 0;
      padding: 0;

    }

    .title-count {
      margin: 0;
      padding: 0;
    }


    &:hover {
      text-decoration: underline;
    }



  }

  // End of .search-result-block__title



  .search-result-block__items {
    margin: 0;
    padding: 0;

    padding-top: 9px;

    // background-color: yellow;

    display: flex;
    flex-direction: column;

  }


  .search-result-block__show-more {
    margin: 0;
    padding: 0;

    padding-left: 6px;
    padding-top: 5px;
    // padding-bottom: 3px;


    span {

      cursor: pointer;

      margin: 0;
      padding: 0;


      font-family: Roboto;
      text-align: left;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 12px;
      letter-spacing: normal;
      text-align: left;
      color: #4da5b5;

    }


  }

  .search-result-block__theres-more {
    margin: 0;
    padding: 0;

    // background-color: aqua;

    padding-left: 40px;
    padding-top: 5px;
    // padding-bottom: 3px;



    span {

      margin: 0;
      padding: 0;

      position: relative;
      top: -6px;


      font-family: Roboto;
      text-align: left;
      font-size: 24px;
      line-height: 1px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      // color: #4da5b5;
      color: #666666;

    }


  }


  .search-result-block__no-results {
    margin: 0;
    padding: 0;

    // background-color: aqua;

    padding-left: 22px;
    // padding-top: 5px;
    // padding-bottom: 3px;



    div {
      margin: 0;
      padding: 0;
      // position: relative;
      // top: -6px;

      font-family: Roboto;
      text-align: left;
      font-size: 24px;
      line-height: 1px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      // color: #4da5b5;
      color: #666666;

    }


  }


  .search-result-block__loading {
    margin: 0;
    padding: 0;

    // background-color: aqua;

    padding-left: 22px;
    // padding-top: 5px;
    // padding-bottom: 3px;



    div {
      margin: 0;
      padding: 0;
      // position: relative;
      // top: -6px;

      font-family: Roboto;
      text-align: left;
      font-size: 24px;
      line-height: 1px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      // color: #4da5b5;
      color: #666666;

    }


  }



}






.search-result-group {
  margin: 0;
  padding: 0;

  width: 100%;

  padding-top: 16px;


  .search-result-group__title {

    display: block;

    padding-left: 5px;

    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    // color: #403f40;
    // color: #969696;
    color: #4DA5B5;

    cursor: pointer;


    .title-text {}

    .title-separator {}

    .title-count {}

    &:hover {
      text-decoration: underline;
    }


  }

  .search-result-group__items {}



  &.collapsed {

    .search-result-group__items {
      display: none;
    }

  }





}





//