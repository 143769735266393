@import "../../../../../../styles/includes";

.pl-grid {

  .nest-cell-container {
    @include flex-row();
    //align-items: center;

    .alert-icon {
      display: inline-block;
      width: 15px;
      margin-right: 10px;
      &.pl:before {
        vertical-align: baseline;
      }
    }
    //.error-message {
    //  margin-left: 10px;
    //}
  }

  .label-cell {
    line-height: 30px !important;

    &.disabled {
      color: $FontColor12;
    }
  }

}







