@import 'infrastructure/styles/theme';
.multi-grid-cell-editor {
  .editable-cell{
    &.multi-row-cell{
      padding-bottom: 30px;
    }
  }

  .PL-textField  {
    width: 100%;
    height: 30px;
    font-size: 15px;

  }
}
