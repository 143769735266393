@import 'mat/styles/includes';

.common-csv-settings-form {
  //padding: 20px 20px;
  padding: 20px 20px 0 20px;
  //background-color: #EDF6F7;
  height: 100%;
  //position: relative;

  .common-header.csv-settings-form-header {
    position: absolute;
    top: 0;
    right: 0;
  }

  .config-section {
    .input-section {
      display: inline-block;
      label {
        margin-bottom: 0;
      }
    }
    .starting-row-section {
      margin-right: 40px;
      label {
        display: inline-block;
        line-height: 36px;
        margin-right: 10px;
      }
      .short-textfield {
        width: 150px;
      }
    }
    .merge-columns-section {
      .pl-checkbox .checkbox-input {
        top: 7px;
      }
      .pl-checkbox .checkbox-label {
        margin-left: 5px;
      }
      .pl-combobox {
        margin-left: 10px;
        width: 70px;
      }
      .example-label {
        margin-left: 10px;
        display: inline;
        line-height: 36px;
        text-transform: none;
        font-size: 14px;
      }
    }
  }

  .csv-properties-list-header {
    margin-top: 20px;
    margin-bottom: 10px;
    height: 35px;
    background: #ddd;
    font-size: 15px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .column {
      width: 150px;
      text-align: center;
    }
  }
}
