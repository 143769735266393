@import 'mat/styles/includes';

.create-material-type-dialog {
  .modal-dialog {
    width: $dialog2ColumnsWidth;
  }

  .modal-body {
    .defrosting-section {
      display: flex;
      .input-section:first-child {
        width: 80%;
      }
    }

    .label-extra-component {
      text-transform: none;
    }

    .defrosting-ranges {
      display: grid;
      grid-template-columns: auto auto min-content;
      column-gap: 15px;
      margin-top: 40px;

      .defrosting-ranges__header,
      .add-remove-list-container,
      .add-remove-list,
      .list-item {
        display: grid;
        grid-template-columns: subgrid;
        grid-column: span 3;
        align-items: center;
      }

      .defrosting-ranges__header {
        margin-bottom: 10px;
        height: 35px;
        background: #ddd;
        font-size: 15px;

        .defrosting-ranges__column-header {
          display: flex;
          align-items: center;

          label {
            position: relative;
          }

          .pl-combobox {
            position: absolute;
            left: 0;
            width: 100%;
            margin-left: 10px;

            .pl-select__control {
              background-color: unset;
              border: none;

              .pl-select__single-value {
                visibility: hidden;
              }

              .pl-select__indicators {
                position: absolute;
                right: -15px;

                .pl-select__indicator {
                  padding: 0;
                }
              }
            }
          }
        }

        label {
          padding-left: 10px;
          flex-shrink: 0;
        }
      }

      .add-remove-list {
        margin-bottom: 10px;
      }

      .add-row.btn {
        justify-self: start;
      }
    }
  }
}
