@import 'infrastructure/styles/theme';

.pl-select-button-wrapper {
    display: flex;

    .pl-select-button {
        position: relative;
        display: flex;
        height: 37px;
        border: solid 1px #cfcfcf;
        padding-right: 30px;
        padding-left: 11px;

        &:not(.disabled):hover {
            button,
            .pl-select__control {
                background-color: #f7f7f7;
            }
            &:active {
                button,
                .pl-select__control {
                    background-color: #d7d7d7;
                }
            }
        }

        &.disabled {
            button {
              color: #a6a6a6;
            }
        }

        button {
            z-index: 9;
            height: 100%;
            width: 100%;
            text-align: left;
            border: none;
            background-color: #fff;
            white-space: nowrap;
            padding: 0;
            color: #7d2853;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 12px;
        }

        .pl-select {
            height: 35px;
            position: absolute;
            right: 0;
            width: 100%;
        }

        .pl-select__control {
            border: none;
            box-sizing: border-box;
            background-color: $BGColor2;
            width: 100%;
            height: 100%;
            min-height: unset;
            box-shadow: none;
            font-size: 14px;
            color: $FontColor11;
            transition: none;
            border-radius: 0;

            &.pl-select__control--is-disabled {
                .pl-select__placeholder {
                    color: $FontColor2;
                }

                .pl-select__dropdown-indicator svg {
                    color: $FontColor2;
                }

                .pl-select__single-value--is-disabled {
                    pointer-events: auto;
                }
            }

            .pl-select__indicator.pl-select__dropdown-indicator {
                color: $FontColor10;
                border-radius: 0;
                transition: none;
                padding: 8px 5px;
            }
        }

        .pl-select__value-container {
            max-height: 150px;
            overflow-x: hidden;
            overflow-y: auto;

            .pl-select__single-value {
                color: $FontColor11;

                &.pl-select__single-value--is-disabled {
                    color: $FontColor2;
                }
            }

            .pl-select__multi-value {
                border: 1px solid hsla(200, 5%, 56%, 0.26);
                box-sizing: border-box;
                background-color: $BGColor10;
                border-radius: 0;
                color: #707070;
                height: 23px;

                .pl-select__multi-value__label {
                    font-size: 100%;
                    color: $FontColor11;
                }

                &.pl-select__multi-value--is-disabled {
                    .pl-select__multi-value__label {
                        color: $FontColor2;
                    }
                }
            }
        }
    }

    .pl-select-menu-portal {
        .pl-select__menu {
            position: absolute;
            left: 0;
            z-index: 1000;
            margin-top: 0;
            margin-bottom: 0;
            border-radius: 0;
            box-shadow: 0px 1px 0 0px #cfcfcf, 1px 0px 0px 0px #cfcfcf, -1px 0px 0px 0px #cfcfcf;

            .pl-select__menu-list {
                max-height: 130px;
                padding: 0;
                overflow-x: hidden;

                .pl-select__option {
                    padding: 0 11px;
                    height: 36px;

                    .pl-select-option-label {
                        display: flex;
                        height: 100%;
                        align-items: center;
                        border-top: 1px solid rgba(112, 112, 112, 0.2);
                        color: #7d2853;
                        font-weight: bold;
                        text-transform: uppercase;
                        font-size: 12px;
                    }

                    &.pl-select__option--is-selected {
                        background-color: transparent;
                    }

                    &.pl-select__option--is-focused {
                        background-color: #f7f7f7;
                    }

                    &.pl-select__option--is-disabled {
                        color: $FontColor2;
                    }
                }
            }
        }
    }
}
