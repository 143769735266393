@import "mat/styles/includes";

.mass-creation-view {
  .title {
    padding: 17px 0 16px 0;
    border-bottom: 2px solid #eee;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
  }

  .mass-creation-form {
    display: inline-block;
    width: 100%;
    padding: 20px 0;

    .hook-attributes-section {
      width: 70%;
      @include flex-row();
      justify-content: space-between;
      padding: 30px 0;
      margin-top: 20px;
    }

    .checkbox-section {
      margin-right: 18px;
      span {
        font-size: 12px;
        font-weight: bold;
        color: $FontColor11;
        text-transform: uppercase;
      }
    }

    .pl-combobox {
      width: 309px;
    }

    .pl-checkbox {
      margin-right: 10px;

    }
  }
}
