@import 'infrastructure/styles/theme';

.tasks-grid {
  margin-top: 18px;
  flex: 1 1 auto;

  .ag-sticky-top {
    background-color: #fff;
  }

  .ag-header {
    border-bottom: 2px solid #5ca5b6;
  }

  .ag-cell {
    &[col-id='valid'] {
      padding-right: 20px;
      .alert-icon-cell {
        .alert-icon {
          margin-right: 5px;
        }
      }
    }
    &.grid-purple-cell {
      color: $FontColor10;
    }
  }

  .time-left-cell {
    padding-left: 0 !important;
    .negative-time-left {
      color: $IconColor3;
    }
    .ag-react-container {
      display: flex;
      justify-content: center;
    }
  }

  .ag-row-level-1 {
    .ag-react-container {
      height: 100%;
    }
  }

  .ag-side-bar {
    .ag-tool-panel-wrapper {
      .ag-column-select-column-label {
        text-transform: none;
      }
    }
  }
}

.pl-grid-slim {
  .pl-grid {
    .ag-header {
      //line-height: unset;

      .ag-header-cell {
        text-transform: capitalize;

        .ag-react-container {
          //height: 100%;
          //width: 100%;
          //display: flex;
          //align-items: center;

          .default-header {
            //display: flex;
            //height: 100%;
            //width: 100%;

            .custom-header-label {
              //    display: flex;
              //    align-items: center;
              //    z-index: unset;
              font-weight: bold;
            }

            //&.no-filter-header {
            //    &.no-sort-header {
            //        .custom-header-label {
            //            width: calc(100% - 20px);
            //        }
            //    }
            //}

            .custom-header-icon {
              //height: 100%;
              //display: flex;
              //align-items: center;
              //z-index: unset;

              .pl-error-icon {
                color: #d9385c;
              }
            }
          }
        }

        .pl-checkbox {
          //margin: 0 auto;
        }
      }
    }

    .ag-cell {
      line-height: unset !important;
      display: flex;
      align-items: center;
      border-right: none !important;
      //font-size: 12px !important;

      &.collapse-cell {
        //position: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        cursor: pointer;
        background-color: #f7f7f7;

        .ag-icon-tree-closed,
        .ag-icon-tree-open {
          background-size: 16px 16px;
          margin: 0;
          padding: 0;
          width: 20px;
          height: 12px;
        }

        .ag-icon-tree-closed {
          background: transparent url(../../../../../../infrastructure/assets/svg/arrow-down.svg) center no-repeat;
        }
        .ag-icon-tree-open {
          background: transparent url(../../../../../../infrastructure/assets/svg/arrow-up.svg) center no-repeat;
        }

        .ag-cell-wrapper {
          height: 100%;
          flex: 1 auto;

          .ag-group-contracted,
          .ag-group-expanded {
            height: 100%;
            display: flex;
            flex: 1 auto;
            align-items: center;
            justify-content: center;
          }
        }
      }

      &.sync-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        .ag-react-container {
          width: unset;
        }
      }

      .ag-react-container {
        width: 100%;
      }

      .ag-cell-wrapper {
        height: unset;
      }
    }
  }
}

.human-capacity-cell {
  display: flex;
  flex-shrink: 1;
}

