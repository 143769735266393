@import 'mat/styles/includes';

.pick-lists-page {
  padding: 23px 0 10px 0;
  height: 100%;
}


.app-container > .app-container-main > .app-container-content > .pick-lists-page {  //Note: for Operations Manager only
  padding: 23px 30px 10px 30px;
  .common-header {
    margin: 0;
  }
}
