@import "mat/styles/includes";

.create-shift-type-dialog {

  .modal-dialog {width: $dialog2ColumnsWidth;}

  .modal-body {
    .input-section:first-child {
      margin-top: 0;
    }
    .PL-textField > input {
      padding-right: 30px;
    }
    .pl-combobox .pl-select__value-container {
      max-height: 85px;
      overflow-x: hidden;
      overflow-y: scroll;
    }
    .pl-select__multi-value__label{
      width: 100%;
    }
    .pl-select__multi-value{
      width: 90%;
    }
  }
}
