@import 'infrastructure/styles/theme';

.digital-twin-view {
  display: flex;
  position: relative;
  height: calc(100% - 60px);

  .digital-twin-view__tree {
    min-width: 340px;
    display: flex;
    flex-direction: column;
    background-color: $BGColor16;
    padding-top: 5px;
  }
  .digital-twin-view__details {
    flex: 1 0;
    position: relative;
    padding-left: 30px;
    max-width: 800px;
    min-width: 710px;
  }
}
