@import "mat/styles/includes";

.activate-assets-dialog {

  .modal-dialog {width: $dialogBig1ColumnWidth;}

  .modal-body {
    .input-section:first-child {
      margin-top: 0;
    }
  }

    //.input-section {
    //  margin-top: 0;
    //}


  .activate-assets-confirmation {
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    color: #707070;
    margin-bottom: 20px;
  }

  .comment-textField {
    width: 100%;
  }
}
