@import "mat/styles/includes";

.rolls-chart {
  float:left;
  height: 439px;
  background-color: white;
  margin-left: 20px;
  margin-top: 10px;

  .chart-title {
    text-align: center;
    font-size: 17px;
    padding-top: 15px;
  }
  .chart-content {
    display: inline-block ;

    .chart-section {
      display: inline-block;
      width: 300px;


      .recharts-legend-wrapper {
        position: fixed !important;
        left: auto !important;
        bottom: auto !important;
        margin: 0 auto !important;
      }

      &.legend-bottom {
        .recharts-legend-wrapper {
          bottom: 40px !important;
        }
      }
    }
  }

  .custom-legend {
    font-size: 11px;
    text-transform: uppercase;
    &.pl:before {
      margin-right: 5px;
      color: #367A87;
      font-size: 16px;
    }
  }
}
