.material-scrap-page {
  .material-scrap-grid {
    height: calc(100% - 53px);
    margin-top: 18px;

    hr.material-scrap-grid-separator {
      width: 100%;
      height: 1px;
    }
    span.material-scrap-grid-item {

    }
  }
}
