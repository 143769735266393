@mixin ellipsis($width) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:$width;
}

@mixin gradient($fromColor, $toColor){
  background-color: $fromColor;

  background: -webkit-linear-gradient(90deg, $fromColor 0%, $toColor 100%);
  background: -moz-linear-gradient(90deg, $fromColor 0%, $toColor 100%);
  background: -o-linear-gradient(90deg, $fromColor 0%, $toColor 100%);
  background: -ms-linear-gradient(90deg, $fromColor 0%, $toColor 100%);
  background: linear-gradient(0deg, $fromColor 0%, $toColor 100%);

  background: -webkit-gradient(linear, 0 0, 0 100%, from($fromColor), to($toColor));
}

@mixin flex-column() {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@mixin flex-row() {
  display: flex;
  flex-direction: row;
  flex: 1;
}

@mixin arrow-border-color($borderColor) {
  border-color:  $borderColor;

  &.bs-popover-top > .popover-arrow {
    &::before {
      border-top-color: $borderColor;
    }
  }

  &.bs-popover-bottom > .popover-arrow {
    &::before {
      border-bottom-color: $borderColor;
    }
  }

  &.bs-popover-start > .popover-arrow {
    &::before {
      border-left-color: $borderColor;
    }
  }

  &.bs-popover-end > .popover-arrow {
    &::before {
      border-right-color: $borderColor;
    }
  }
}

