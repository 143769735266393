@import "infrastructure/styles/theme";

.tooltip {
  position: fixed;
  z-index: 1000000;

  &.show {
    opacity: 1;
  }

  .tooltip-inner{
    background-color: $BGColor31;
    border-radius:0 ;
    opacity: 1;
    word-wrap: break-word;
    text-align:left;
    >* {
      color: white;
    }
  }

  &.bs-tooltip-top .tooltip-arrow {
    &:before {
      border-top-color: $BGColor31 !important;
    }
  }

  &.bs-tooltip-bottom .tooltip-arrow {
    &:before {
      border-bottom-color: $BGColor31 !important;
    }
  }

  &.bs-tooltip-end .tooltip-arrow {
    &:before {
      border-right-color: $BGColor31 !important;
    }
  }

  &.bs-tooltip-start .tooltip-arrow {
    &:before {
      border-left-color: $BGColor31 !important;
    }
  }

  &[data-popper-reference-hidden=true] {
    visibility: hidden;
    pointer-events: none;
  }

  &[data-popper-escaped=true] {
    visibility: hidden;
    pointer-events: none;
  }
}
