

.ticking-time-cell {
    .blinker {
        animation: blinker 1s step-start infinite;
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}
