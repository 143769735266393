@import "mat/styles/includes";
.sensors-grid {
  height: calc(100% - 65px);
  margin-top: 10px;
  .ag-cell {
    line-height:30px !important;
    padding: 20px;
  }
}


