@import 'infrastructure/styles/theme';

.grid-cell-editor {
  width: 100%;
  height: 100%;
  padding-left: 5px;
  &.PL-textField{
    padding-left: 0;
  }

}
.pl-grid{
  .PL-textField{
    height: 30px;
  }
  .PL-textField > input{
    height: 30px;
    padding: 0 5px;
    font-size: 15px;
  }
}
