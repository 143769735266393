.promotion {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;

  .promotion-bg {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .promotion-content {
    position: absolute;
    width: 546px;
    color: #fff;
    font-family: Lexend;
    bottom: 0;
    padding: 0 40px 60px;

    .highlight {
      background-color: rgba(0,0,0,.5);
      padding: 2px 5px;
      box-decoration-break: clone
    }

    .promotion-header {
      font-size: 32px;
      margin-bottom: 30px;
      overflow-wrap: break-word;
      .highlight {
        padding: 4px 5px;
      }
    }

    .promotion-description {
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 20px;
      overflow-wrap: break-word;
    }

    .promotion-action {
      height: auto;
      background-color: #fff;
      border: 1px solid #707070;
      border-radius: 6px;
      padding: 5px 10px;

      span {
        background: transparent linear-gradient(90deg, #301b47 0%, #cf2b55 100%) 0% 0% no-repeat padding-box;
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        text-transform: none;
        font-size: 15px;
      }

      &:hover {
        background: linear-gradient(90deg, #e73b62, #d20e7a);
        background-clip: none;

        span {
          color: #fff;
        }
      }

      &:active {
        background: linear-gradient(90deg, #c73354, #a5005b);
      }
    }
  }

  @media screen and (min-width: 1920px) {
    .marketing-layout-bg {
      background-position: top -60px right -70px;
      background-size: 80%, auto;
    }

    .marketing-layout-right {
      //padding: 60px;
    }

    .promotion-content {
      padding: 60px;
    }

    .promotion-header {
      font-size: 40px;
    }

    .promotion-description {
        font-size: 24px;
      }
  }
}
