@import "mat/styles/includes";

.scheduler-gantt-page {
  padding: 0 15px;
  display: flex;
  flex-direction: column;


  .scheduler-gantt-wrapper {
    flex: 1;
    margin-top: 20px;
    position: relative;
  }
}


.message-dialog.scheduler-page-message-dialog {
  .message-area {
    font-size: 18px;
  }
}

