@import "mat/styles/includes";

.pl-select__option {
  .select-option-row {
    .error-color {
      color: $IconColor3;
    }
    .warning-color {
      color: $IconColor4;
    }
    .column {
      vertical-align: middle;
      display: inline-block;
      line-height: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > :not(:last-child) {
      margin-right: 5px;
    }
    > span:first-child {
      width: 20px;
    }
    > :not(:first-child) {
      width: calc((100% - 20px - 15px) / 3);
    }
  }

  .column.refine-more-options {
    background: $BGColor8;
    color: $FontColor11;
    font-weight: bold;
    margin:  -5px -15px -10px -15px;
    padding: 5px 15px;
  }
}

.entities-multi-select-field {
  &.pl-combobox.pl-multi-select-field {

    .pl-select__loading-indicator {
      display: inline-block;
      position: absolute;
      right: 10px;
      //right: calc(50% - 9px);
      top: calc(50% - 9px);
    }
  }

  .select-value-row {
    .error-color {
      color: $IconColor3;
    }
    .warning-color {
      color: $IconColor4;
    }

    .column {
      display: inline-block;
      line-height: 16px;  //?????
      vertical-align: top;
    }

    .column:first-child {
      width: 106px;
    }
    .column.extra-data {
      width: 190px;
    }
    .column.icon {
      width: 22px;
    }
    >:not(:last-child) {
      margin-right: 5px;
    }
  }

}


