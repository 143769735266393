@import 'mat/styles/includes';

.misplaced-material-view {
  display: inline-block;
  width: 100%;
  height: 100%;

  .nav-tabs {
    height: 45px;
  }
}
