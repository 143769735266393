@import "infrastructure/styles/generic";
@import "mat/styles/includes";

.assets-to-asset-section-consumption {

  .assets-to-asset-selection {
    width: 233px;
    .select-row.select-value-row, .select-row.select-option-row  {
      .column {
        height: 20px;
        line-height: 20px;
      }
    }
  }


  .add-to-asset-section.add-section {
    .entities-multi-select {
      width: 235px;
      .select-value-row .column.extra-data {
        max-width: 55px;
      }
    }
    .asset-length-section {
      width: 100px;
      margin-top: 16px;
      position: absolute;
      top: 0;
      right: 0;
      left: 242px;

      .label-extra-component {
        text-transform: none;
        margin: 0 0 0 3px;
      }
    }
  }

  .add-to-asset-section.to-section {
    .select-value-row .column.extra-data {
      max-width: 130px;
    }
  }

}
