.human-capacity-chip {
  color: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 7px 6px 10px;

  & span:first-child {
    margin-right: 7px;
    font-size: 12px;
    line-height: normal;
  }
}
