@import "infrastructure/styles/theme";

.invalid {
  input, textarea, .pl-select__control {
    &, &:focus {
      border-color: $BorderColor7 !important;
    }
  }

  &.pl-combobox, &.PL-textField, &.pl-datepicker, &.PL-textAreaField {
    position: relative;
  }
  .invalid-icon {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    top: 0;
    right: 36px;
    img {
      vertical-align: middle;
    }
  }
  .invalid-message {
    color: $FontColor20;
    font-size: 14px;
    visibility: hidden;
  }

  &.PL-textField, &.pl-multi-select-field, &.PL-textAreaField  /*, &.pl-datepicker*/ {
    .invalid-icon {
      right: 8px;
    }
  }
  //put the invalid icon into the right bottom corner (for textArea only)
  &.PL-textAreaField {
    .invalid-icon {
      align-items: flex-end;
      padding-bottom: 8px;
    }
  }
}

.edit-in-place {
  .invalid .invalid-icon {
    right: 67px;
  }
}


// Note: the validation message (instead of tooltip on 'hover') currently available only for the touch screen
// and the defined below dialogs. This is temporarily limitation.
.pl-date-range-popover, .create-kits-dialog, .report-cuts-dialog, .report-task-progress-dialog, .work-order-dialog {
  .invalid {
    .invalid-message {
      visibility: visible;
      color: $FontColor20;
      font-size: 11px;
    }
  }
}
