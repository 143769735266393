@import 'mat/styles/includes';

.common-pdf-label-settings-form {
  //padding: 20px 20px;
  padding: 0 20px 0 20px;
  //background-color: #EDF6F7;
  //height: 100%;
  height:calc(100% - 65px);
  //position: relative;

  .common-header {
    width: 190px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .properties-list-header {
    margin-top: 20px;
    margin-bottom: 10px;
    height: 35px;
    background: #ddd;
    font-size: 15px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .column {
      width: 300px;
      text-align: center;
    }

    .column-2 {
      width: 120px;
      margin-left: 20px;
    }
  }
}
