.location-kitting-view{
  padding: 23px 0 10px;
  height: 100%;

  .create-kit-button-section{
    text-align:center;
    position:relative;
    height:0;
    .overlay-button{
      top: 140px;
      position: absolute;
      margin: auto;
      left: calc(50% - 82.5px);
      display:inline;
      z-index:3;
    }
  }
}
