@import "mat/styles/includes";

.pl-grid {
  .task-status-cell {
    padding-right: 20px;

    .pl:before {
      vertical-align: text-top;
    }

    .pl-schedule{
      color: #E89430;
    }
    .pl-unscheduled{
      color: #D83A5B;
    }
    .pl-started{
      color: #57CCDE;
    }
    .pl-completed{
      color: #4EA5B5;
    }

    .description {
      margin-left: 3px;

      span {
        display: inline;
      }
    }
  }
}








