.mobile-modal-overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  &.open {
    display: block;
  }
}

.mobile-modal {
  width: 80%;
  height: auto;
  position: fixed;
  background: #fff;
  left: 0;
  right: 0;
  top: 100px;
  margin: auto;

  &.open {
    animation: appear 500ms cubic-bezier(0.57, 0.37, 0.8, 1.17);
  }
  &.close {
    animation: disappear 250ms cubic-bezier(0.57, 0.37, 0.8, 1.17) forwards;
  }

  .mobile-modal-content {
    padding: 20px;
  }

  .mobile-modal-footer {
    padding: 0 20px;
  }
}

@keyframes appear {
  0% {
    display: block;
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes disappear {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
    display: none;
  }
}
