@import "mat/styles/includes";

.create-attachment-dialog {
  .input-file {
    display:none;
  }
  .modal-dialog {width: $dialogBig1ColumnWidth;}

  .modal-body{
    .input-section:first-child {
      margin-top: 0;
    }
  }

  .pl-dropdown {
    width: 200px;
  }

  .comment-textField {
    width: 100%;
  }
}
