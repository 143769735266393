@import "infrastructure/styles/theme";

.custom-overlay{



  &.popover, .popover.popover_default {
    border-width: 1px;
    border-color: $BorderColor13;
    box-shadow:$BoxShadow;
  }

  &.popover.bottom > .arrow {
    border-style: solid;
    border-bottom-color: $BorderColor13;

    &::after {
      top:1px;
    }
  }
}


