@import "mat/styles/includes";


.confirm-new-kit-wizard {

  .modal-dialog {

    .modal-body {
      padding: 15px 0 0 0;
    }
  }

  .confirm-new-kit-wizard-title {
    span {
      display: inline-block;
      vertical-align:bottom;
    }
    span.more-info {
      line-height: 47px;
      font-size:14px;
      margin-left: 10px;
      @include ellipsis(350px);
    }
  }

  .kit-type-section {
    $cellWidth1: 300px;
    $cellWidth2: 200px;

    margin: 40px;

    .inner-title-row {
      width: 100%;
      text-align: center;
      border-bottom: 3px solid $BorderColor6;
      line-height: 30px;
      font-size: $h4;
    }

    .inner-title {
      display: inline-block;

      &.type {
        width: $cellWidth1;
        text-align: left;

        .kits-type {
          font-weight: 700;
        }

        .kits-type-id {
          font-weight: 400;
          display: inline-block;
          width: auto;
        }

        .kits-type-count {
          font-weight: 700;
          padding-left: 10px;
        }
      }

      &.plies {
        width: $cellWidth2;
        text-align: center;

      }
    }

    .inner-row {
      text-align: center;
      display: inline-block;
      width: 100%;
      line-height: 35px;
    }

    .kit-id, .kit-kitted-plies {
      display: inline-block;
    }

    .kit-id {
      width: $cellWidth1;
      text-align: left;
    }

    .kit-kitted-plies {
      width: $cellWidth2;
      text-align: center;
    }
  }

  .floating-center-text {
    text-align: right;
    .pl-checkbox .checkbox-input {
      margin-right: 5px;
    }

    > label {
      width: 379px;
    }
  }
  &.loading {
    .floating-center-text > label {
      width: 339px;
    }
  }
}


