@import "mat/styles/includes";

.import-tool-types-dialog {

  .modal-dialog {
    width: 640px;

    .settings-link {

      text-decoration: underline;
      color: $FontColor5;
      cursor: pointer;
      margin: 0 5px;
    }
    .body-title {
      display: block;;
      font-size: 18px;
    }
  }
}

.import-tool-types-json-confirmation-dialog {
  .modal-dialog {
    width: 700px;
  }
  .modal-header .header-icon {
    display: none;
  }
  .message-area {
    max-height: 300px;
    overflow: auto;
  }
}
