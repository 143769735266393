@import "mat/styles/includes";

.popup-bo-item {
  border: 1px solid #EBEBEB;
  line-height: 18px;

    .operation-progress-indicator {
      background-color: #F8FEFF;
      @include flex-row();
      justify-content: flex-start;
      align-items: center;
      padding: 10px;

      .indicator-progress, .indicator-title, .indicator-info {
        display: inline-block;
        vertical-align: middle;
      }

      .indicator-title {
        div:first-child {
          font-weight: bold;
        }
        margin: 0 20px;
        width: 260px;
      }
    }

}


