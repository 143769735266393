.marketing-layout.default {
  background: transparent linear-gradient(90deg, #221a4d 0%, #5dc7d9 100%) 0% 0% no-repeat padding-box;
  .marketing-layout-bg {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../../assets/images/3Dmap.png'), url('../../../assets/images/marketing-bg.png');
    background-position: top -50px right -130px;
    background-repeat: no-repeat;
    background-size: 1196px 475px, auto;
  }

  .marketing-layout-default {
    align-self: flex-end;
    width: 546px;
    color: #fff;
    font-family: Lexend;
    margin: 0 0 40px 40px;

    h1 {
      font-size: 32px;
      margin-bottom: 30px;
    }

    p {
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 20px;
    }

    .marketing-button {
      height: 32px;
      background-color: #fff;
      border: 1px solid #707070;
      border-radius: 6px;
      padding: 5px 10px;

      span {
        background: transparent linear-gradient(90deg, #301b47 0%, #cf2b55 100%) 0% 0% no-repeat padding-box;
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        text-transform: none;
        font-size: 15px;
      }

      &:hover {
        background: linear-gradient(90deg, #e73b62, #d20e7a);
        background-clip: none;
        span {
          color: #fff;
        }
      }

      &:active {
        background: linear-gradient(90deg, #c73354, #a5005b);
      }
    }
  }

  @media screen and (min-width: 1920px) {
    .marketing-layout-bg {
      background-position: top -60px right -70px;
      background-size: 80%, auto;
    }

    .marketing-layout-default {
      margin: 0 0 60px 60px;
      h1 {
        font-size: 40px;
      }

      p {
        font-size: 24px;
      }
    }
  }
}
