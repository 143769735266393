@import 'infrastructure/styles/theme';

.pl-grid {
  .date-select-filter {

    .text-field {
      width: 310px;
    }

    .filter-footer {
      margin-top: 20px;
    }

    #dateTimePicker{
      .react-datepicker{
        width: 450px;
      }
    }
  }
}
