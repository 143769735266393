
.live-map-overlay-camera-presets {
  display: block;
  height: 60px;
  // background-color: aqua;

  // width: 100%;
  // background-color: white;
  // opacity: 50%;

  background-color: transparent;

  // position: absolute;
  // top: 17px;
  // right: 14px;
  // z-index: 2;



  .live-map-camera-presets-controls {

    margin: 0;
    padding: 0;

    // width: 237px;
    height: 48px;
    // padding: 12px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #707070;
    background-color: #403f40;

    display: flex;
    // gap: 10px;



    button {
      background-color: transparent;
      height: 100%;

      margin: 0;
      padding: 0;
      border: none;
      outline: none;

      // padding: 0 6px;
      padding-right: 10px;

      &.first {
        padding-left: 10px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &.last {
        padding-right: 10px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      
      &:active {
        .camera-button-content-wrap {
          background-color: #4da5b5;
        }
      }
      
      &:hover {
        .camera-button-content-wrap {
          background-color: #000000;
        }
        
        &:active {
          .camera-button-content-wrap {
            background-color: #4da5b5;
          }
        }
        
      }

      .camera-button-content-wrap {
        display: flex;

        // padding: 0px 12px 0px 12px;

        .camera-button-icon {
          // display: inline-block;
          width: 24px;
          height: 24px;
          // background-color: red;
        }

        .camera-button-text-1 {
          // background-color: greenyellow;

          margin-left: 4px;
          padding-left: 2px;

          font-family: Roboto;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 24px;
          letter-spacing: normal;
          text-align: left;
          color: #fff;

        }

        .camera-button-text-2 {
          // background-color: lightsteelblue;

          margin-left: 4px;
          padding-right: 6px;

          font-family: Roboto;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 24px;
          letter-spacing: normal;
          text-align: left;
          color: #959595;

        }
      }

    }




  }



}

