@import "mat/styles/includes";

.bring-asset-dialog {
  .modal-dialog {width: $dialogBig1ColumnWidth;}

  .modal-body {
    label {
      text-transform: uppercase;
    }

    .no-result-for {
      span:last-child {
        font-weight: bold;
      }
    }
  }
}
