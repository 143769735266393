@import "../../../../styles/includes";

.picklist-details {
  padding: 20px 30px;
  background-color: #eee;
  position: relative;

  .picklist-details-section{
    background-color: $BGColor2  !important ;
    display: table;
    width: 100%;
    padding: 20px 0px;

    .column {
      width: 33%;
      height: 100%;
      vertical-align: top;
      display: table-cell;
      padding: 0px 17px;

      &:not(:last-child) {
        border-right: solid 1px $BGColor29;
      }
    }
  }


  hr{
    margin-top: 20px;
    border-top: 1px dashed $BGColor29;
  }

  .value {
    >span:not(:last-child) {
      margin-right: 15px;
    }
  }
  .popover-target {
    display: inline-block;
  }

}












