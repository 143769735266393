@import "mat/styles/includes";

.select-nests-dialog {
  color: $Color7;
  font-weight: 500;

  .modal-dialog {
    width: $G_MinimumAppWidth;

    .select-nests-counter {
      font-size: 16px;
    }
  }

  .body-top-section.title {
    margin-bottom: 20px;
    & > div {
      &:first-child {
        margin-right: 15px;
      }
    }
  }

  .body-title {
    display: block;;
    font-size: 18px;
  }

  .body-comment {
    margin-top: 3px;
    font-size: 14px;
  }

  .error-selection {

    .error-item {
      margin-top: 15px;
      display: table;
      line-height: 18px;
      width: 800px;
      color: $IconColor3;

      &:not(:first-child) {
        margin-top: 15px;
      }

      .pl:before {
        vertical-align: text-top;
      }

      .error {
        color: $IconColor3;
      }
      .warning {
        color: $IconColor4;
      }

      .error-icon {
        font-size: 16px;
        display: table-cell;
        width: 25px;
      }

      .error-msg {
        font-size: 15px;
        display: table-cell;
        vertical-align: top;
        line-height: 17px;
      }

    }
  }

}



.select-nests-grid {
  height: 430px;
  margin-top:18px;
  position: relative;

  .ag-cell {
    line-height:30px !important;
    padding: 17px;
  }

  .pl-grid {
    height: 100%;
  }
}






