.multi-value-with-text-input {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  .pl-select__multi-value {
    width: calc(100% - 70px);
    height: 26px;
    flex-shrink: 1;
    flex-grow: 0;
  }
  .attached-input {
    transition: border-color 300ms linear;
    border: 1px solid rgba(136, 144, 148, 0.26);
    width: 50px;
    height: 23px;
    outline: none;
    flex-grow: 1;
    margin-left: 10px;
    &.empty {
      border-color: red;
      border-width: 2px;
    }
    &.dirty {
      border-color: #7d2853;
      border-width: 2px;
    }
    &:focus {
      border-color: #4da5b5;
      border-width: 2px;
    }
  }
}
