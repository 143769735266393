
.block{display:block;}

.inline-block{display:inline-block;}

.pointer{cursor:pointer;}

.vertical-middle{>*{vertical-align:middle;}}

.normal-size{font-weight:normal;}

.bold{font-weight:bold;}

.disable-select-user{
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;          /* Likely future */
}
