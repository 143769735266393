@import "mat/styles/includes";

.part-details {
}













