
.smart-selection-page {
  display: flex;
  flex-direction: column;

  .smart-selection-grid-section{
    margin: 18px 30px 10px 30px;
    height: calc(100% - 126px);
  }
}
.smart-selection-confirm-message {
  span{
    white-space: pre-wrap;
  }
}
