@import "mat/styles/includes";

.pl-grid {
  .status-cell {
    .pl:before {
      vertical-align: text-top;
    }
    .icon {
      display: inline-block;
      margin-right: 10px;
      width: 15px;
      font-size: 16px;

      &.pl-check-circle-icon-blue {
        color: $IconColor7;
      }
      &.pl-error-icon {
        color: $IconColor3;
      }
      &.pl-warning-icon {
        color: $IconColor4;
      }
      //&.pl-operation-canceled {
      //  color: $IconColor4;
      //}
    }

    .description {
      margin-right: 5px;
    }
  }
}








