@import "mat/styles/includes";

$ItemBorder: 1px solid #EDEDED;
$ItemMinHeight: 69px;

$MessageTextFontSize: 15px;

.page-notifications-item {
  color: $FontColor18;

  width: 100%;
  min-height: $ItemMinHeight;

  border-left: $ItemBorder;
  border-top: $ItemBorder;
  border-right: $ItemBorder;
  &:last-child {
    border-bottom: $ItemBorder;
  }

  padding: 15px 10px 0 15px;
  line-height: 18px;

  .page-notifications-row {

    padding: 10px 0 10px 0;

    .status-cell {
      width: 25px;
      display: inline-block;
      float: left;

      label.pl{
        font-size:9px;
        vertical-align: top;
        margin-top: 3px;
        padding-left: 3px;
        &:focus{outline:none;}
        &:hover{}
        &:active{}
      }

      .unread{
        color: $IconColor3;
      }

    }

    .time-cell {
      vertical-align: top;
      font-size: 12px;
      display: inline-block;
    }

    .message-cell {
      display: inline-block;
      width: 780px;
      margin-left: 9px;

      .message-header {
        font-size: $MessageTextFontSize;
        margin-bottom: 13px;
      }

      .message-line {
        margin-bottom: 16px;

        .line-icon {
          display: inline-block;
          padding-right: 12px;

          i {
            position: relative; top: 1px;
            font-size: 17px;
            color: $IconColor5;
          }

        }
        .line-text {
          display: inline-block;
          font-size: $MessageTextFontSize;
        }
        .line-info-icon {
          background-color: lightskyblue;
          display: inline-block;

          margin-left: 4px;
          margin-right: 4px;

        }
        .line-action-link {
          display: inline-block;
          font-size: $MessageTextFontSize;
        }

      }

      .item-title {
        background-color: #ffd7d9;
      }
      .item-view-link {
        background-color: #deffde;
      }
    }

    .goto-details.icon {}

  }

  &:hover {
    background: #EBEBEB;

    .status-cell{
      label.pl{
        margin-top: 0;
        padding-left: 0;
        font-size: 15px;
        border-radius: 50%;
        background: $IconColor1;
        cursor: pointer;
      }
    }
  }

  &.unread-notification {
    .time-cell {
      font-weight: bold;
    }
    .message-cell {
      font-weight: bold;
    }
  }

}

.status-icon{
  margin-right: 10px;
  display: inline-block;
  line-height: inherit;
  float: left;
  font-size: 16px;
}

.notifications-page-item-popover {
  font-weight: bold;
  width: auto;
  white-space: nowrap;
  .popover-content {
    padding-top: 10px;
  }
}







