@import "mat/styles/includes";

.change-location-result-page {
  @include flex-column();
  justify-content: flex-start;

  .result-header {
    background-color: $BGColor6;
    color: white;
    padding: 20px;
    span {
      display: block;
      font-size: 18px;
      &.pl:before {
        margin-right: 20px;
      }
      &.location-name {
        margin-left: 40px;
      }
    }

  }
  .result-assets-list {
    overflow-x: hidden;
    overflow-y: auto;
    @include flex-column();
  }

  .list-item {
    position: relative;

    span.result-icon {
      display: inline-block;
      position: absolute;
      top: 18px;
      left: 20px;
      font-size: 25px;
      &.result-success {
        color: #56CADC;
      }
      &.result-failed {
        color: $FontColor20;
      }
    }
  }
}





