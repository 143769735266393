//



.search-result-block__item {
  margin: 0;
  padding: 0;

  cursor: pointer;

  padding-left: 6px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;


  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;


  &:hover {
    background-color: #E9E9E9;
  }

}



////////////////////////
// Title part
// 
.search-result-block__item {
  .item-title {

    display: flex;
    flex-direction: row;

    .item-icon {
      width: 22px;
      height: 22px;

      line-height: 1px;

    }

    .item-text {

      padding-left: 10px;

      position: relative;
      top: 1px;

      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #666566;

    }

  }

}


////////////////////////
// Type Icon part
//
.search-result-block__item {

  .item-icon {
    .spool-icon {
      position: relative;
      left: -3px;
    }
  }

  .item-icon {
    .part-icon {
      
      svg {
        width: 17px;
        height: 17px;
      }
      
      position: relative;
      left: 3px;
      top: 1px;
    }
  }

}



////////////////////////
// Alert Icon part
//
.search-result-block__item {
  .item-alert-icon {
    width: 22px;
    height: 22px;

    line-height: 1px;

    position: relative;
    top: 2px;

    &.warning {
      width: 20px;

      position: relative;
      top: 3px;
    }

  }

}




//