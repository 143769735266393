@import "mat/styles/includes";

.batch-job--progress-dialog{
  .modal-dialog {width: 506px;}

  .modal-header {
    background-color: #ffffff;
    .modal-title {
      color: #2f2f2f;
    }
    button.close {
      display: none;
    }
  }
  .modal-body {
    text-align: center;
    background-color: #F8FEFF;

    .top-label {
      margin-bottom: 10px;
      font-size: 16px;
    }
    .time-counter {
      margin-bottom: 10px;
    }
    .bottom-label {
      margin-top: 20px;
      font-size: 16px;
    }

  }

  .modal-footer {
    background-color: #ffffff;
    .floating-center-text {
      margin-right: 30px;
    }
  }


}
