.misplaced-material-alert-rule-dialog {
  .PL-textField {
    width: 100px;
  }

  .modal-body {
    .item-index {
      flex: 0 0 25px;
      margin-right: 15px;
      text-align: center;
      font-weight: bold;
      color: #4ea5b5;
    }
  }
}
