@import "mat/styles/includes";

.auto-scheduler-dialog {

  .modal-dialog {width: $dialogBig1ColumnWidth;}

  .modal-body {
    .title{
      display: block;
      margin-bottom: 4px;
      text-transform: uppercase;
      color: #707070;
      font-size: 12px;
      line-height: 18px;
      font-weight: bold;
      text-align: left;
      vertical-align: top;
    }

    .react-datepicker {
      .react-datepicker__triangle {
        left: 110px;
      }
    }
  }
}

.message-dialog.auto-schedule-message-dialog {
  .message-area {
    white-space: pre-line;
  }
}
