@import "../../../../../../styles/includes";

.add-to-group-dialog.dialog {

  .modal-dialog {width: 990px;}

  .modal-body {
    max-height: $modalBodyMaxHeight;
    position: static;

    .add-to-asset-container {
      @include flex-row();
      align-items: flex-start;

      .add-to-asset-section {
        width: 45%;
        //display: inline-block;
        //position: relative;

        .add-to-asset-label {
        //  //display: inline-block !important;
        //  position: absolute;
        //  margin-right: 10px;
          text-transform: uppercase;
        //  top: 10px;
        //  left: 0;
        }

        //.entities-multi-select-field .select-value-row .column.extra-data {
        //  //width: 65px;
        //  width: 57px;
        //}

        .entities-multi-select {
          .entities-multi-select-field .select-value-row .column.extra-data {
            width: 95px;
            //width: 57px;
          }
          .pl-select__value-container {
            max-height: 110px;
          }
          //@include flex-column();

          //.entity-type-select {
          //  margin: 0 0 2px 35px;
          //}

          //.add-to-asset-selection {
          //  width: 100%;
          //
          //  //put the invalid icon into the right bottom corner (for .add-to-asset-selection only)
          //  &.invalid {
          //    .invalid-icon {
          //      align-items: flex-end;
          //      padding-bottom: 8px;
          //    }
          //  }
          //
          //  .pl-select__value-container {
          //    min-height: 90px;
          //    //justify-content: flex-start;
          //    align-items: flex-start;
          //
          //    .select-value-row .extra-data{
          //      width: 130px;
          //    }
          //    //to limit the input expanding when typing
          //    .pl-select__input > input {
          //      max-width: $comboboxInputWidth5;
          //    }
          //  }
          //}
          //.assets-to-asset-selection {
          //  width: 100%;
          //  .select-row.select-value-row .column:first-child {
          //    width: 250px;
          //    vertical-align: middle;
          //  }
          //}
        }
      }
      .add-to-asset-arrow {
        display: inline-block;
        width: 10%;
        text-align: center;
        //padding-left: 25px;
        font-size: 15px;
        margin-top: 30px;
      }
    }
  }
}

.add-to-cont-confirmation-dialog {
  .message-item {
    margin-bottom: 30px;

    .message-item-line {
      .line {
        margin-bottom: 10px;

        .value {
          max-height: 195px;
          overflow-y: auto;
          overflow-x: hidden;

          .message-item-value {
            width: 200px;
          }
        }
      }
    }

    .related-asset-data {
      padding-top: 1px;

      .message-item-value {
        .related-asset-type {
          width: 60px;
        }

        .related-asset-id {
          width: 150px;
          margin-right: 5px;
        }

        .related-asset-metadata {
          width: 190px;
        }
      }
    }
  }
}

