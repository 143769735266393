@import "mat/styles/includes";

.billing-page {

  position: relative;

  .billing-params-section {
    padding: 20px 60px;
    background-color: $BGColor11;
    border-bottom: 2px solid #eee;

    .input-section.inline {
      width: 200px;
      display: inline-block;
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
    .btn {
      margin-top: 15px !important;
      margin-left: 20px;
    }
    border-bottom: 2px solid #eee;

  }
  .short-textfield {
    width: 200px;
  }

  .billing-info-section {
    padding: 20px 60px;
    .period-section {
      label {
        display: inline-block;
        width: 10%;
      }
    }
    .assets-section {
      margin-top: 15px;
    }

    label {
      text-align: left;
      display: block;
      color: $FontColor16;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      margin-top: 5px;
    }
    span {
      padding-left: 5px;
      color: $FontColor13;
    }
  }
}






