@import "mat/styles/includes";

.dashboard-header {
  padding: 15px 20px 20px 20px;
  background-color: $BGColor11;

  .padding-top{
    padding-top: 26px;
  }
  .input-section.inline {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    width: 200px;
    .pl-combobox {
      width: 100%;
    }
  }

  //should be moved to inventoryDashboardHeader.css
  &.inventory-dashboard-header {
    .input-section.inline {
      width: 250px;
    }
  }

  .btn {
    margin-top: 22px !important;
    margin-left: 20px;
    float: right;
  }

  .option-sub-item {
    margin-left: 20px;
  }
  .option-title {
    display: block;
    margin-left: -6px;
    padding-left: 6px;
    background-color: white;
  }

  .multi-select-field {
    min-height: 36px;
    max-height: 85px;
  }
  .pl-combobox .pl-select__value-container {
    max-height: 85px;
    overflow-x: hidden;
  }
  .pl-select__multi-value__label{
    width: 100%;
  }
  .pl-select__multi-value{
    width: 90%;
  }
}
