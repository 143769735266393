
.pm-terminology-grid{
    height: calc(100% - 160px);
    margin-top:10px;

  .ag-cell {
    line-height:30px !important;
    padding: 20px;
  }

}







