@import 'mat/styles/includes';

.create-kits-dialog {
  .modal-dialog {
    width: $dialog2ColumnsWidth;
  }
  .modal-body {
    padding: 15px 0 0 0;

    .cut-select {
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin-bottom: 15px;

      .cut-select-label {
        flex: 1 0 auto;
        margin-right: 10px;
        font-weight: bold;
      }

      .entities-multi-select {
        width: 100%;
        flex: 1 1 auto;
      }

      .cut-data-row {
        display: flex;
        flex-flow: row;

        & > div {
          margin-right: 10px;
          flex: 1;
        }

        .material, .roll-business-id {
          flex: 2;
        }
      }
    }
  }

  .modal-content {
    background: $BGColor2;

    .pl-tabs {
      margin-top: -25px;
      &.nav-tabs {
        padding-right: 86px;

        li:first-child button {
          border-left: 0 solid transparent;
        }
      }

      .kit-tab-delete-button {
        display: block;
        width: 20px;
        height: 20px;
        float: right;
        position: relative;
        top: -8px;
        right: -14px;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;

        &:hover {
          color: red;
        }
      }
    }

    .tab-pane {
      padding: 25px;
      max-height: 560px;
      overflow-y: scroll;
      background-color:$BGColor11;
    }

    .new-kit-button {
      display:flex;
      justify-content: flex-end;
      margin-right: 10px;
    }

    .validation-area {
      background-color: $BGColor11;
    }
  }
  .PL-textAreaField {
    height: 85px;
  }

  .modal-footer {
    .floating-center-text {
      margin-left: 20px;
    }
  }
}
