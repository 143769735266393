@import "mat/styles/includes";

.roll-id-cell {
  @include flex-row();
  align-items: center;

  .description {
    margin-right: 5px;
  }

  .popover-target {
    height: 15px;
  }
}







