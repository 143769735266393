@import "mat/styles/includes";

.operation-list-item {
  @include flex-row();

}






