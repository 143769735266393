@import "infrastructure/styles/generic";
@import "infrastructure/styles/theme";


.full-screen-tabs-separator{
  position: absolute;
  width: 100%;
  //min-width:$G_MinimumAppWidth;
  left: 0;
  margin-top: 55px;
  height:0 !important;
  > div {
    border-bottom: 4px solid $BorderColor23;
    > div{
    border-bottom: 1px solid $BorderColor3;
    }
  }
}





