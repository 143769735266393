
.kit-ply {
  display: flex;
  align-items: center;
  width: 180px;
  padding: 8px 7px;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: white;
  margin: 3px;
  gap:5px;
  cursor: pointer;

  &:hover {
    border: 1px solid #4ea5b5;
  }

  >* {
    display: inline-block;

    &.kit-ply_index {
      font-weight: bold;
    }
    &.kit-ply_name {
      color: #4ea5b5;
      width: 120px;
    }
    &.kit-ply_icon {
      height: 28px;
    }
  }
  &.kitted {
    background-color: #AEF3D3 ;
    .kit-ply_name {
      color: #333333;
    }
  }
}
