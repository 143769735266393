@import "mat/styles/includes";

.create-mqtt-broker-dialog {
  .modal-dialog {
    width: $dialogBig1ColumnWidth;

    .message-title {
      border-bottom: solid 2px $BGColor29;
      margin-top: 18px;
      font-size: 15px;
    }

    .messages-tab {
      .pl-checkbox {
        margin-top: 28px;
      }

      .message-section {
        @include flex-row();
        justify-content: space-between ;

        .input-section.left-side {
          flex: 1;
        }
        .input-section.right-side {
          width: 100px;
        }
        .PL-textAreaField {
          height: 36px;
          width: 100%;
        }
      }
    }
  }
}


