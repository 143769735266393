@import "mat/styles/includes";

// TODO: As need dictates, leave only common rules in this file,
// and move component specific stuff to their own css.


  .input-section {

    label {
      display: block;
      margin-bottom: 4px;
      text-transform:uppercase;
      color: $FontColor11;
      font-size: 12px;
      line-height: 18px;
      font-weight: bold;
      text-align: left;
      vertical-align: top;

      span {
        font-size: 14px;
        margin: 0 5px;
      }
    }

    &.small {
      .PL-textField {
        display: inline-block;
        width: 100%;
      }
      .explanation {
        width:100%;
        display: inline-block;
        position: relative;
        top: 7px;
        left: 4px;
        color: $FontColor11;
        font-size: 12px;
        font-weight: normal;
        line-height: 1;

      }



    }

    &.full {
      width: 625px;
      .PL-textField {
        width: 100%;
      }
    }
    .header-checkbox-wrapper{
      display: inline-block;
      float: right;
      > .pl-checkbox {
        margin-top: -5px;
      }
      label {
        display: inline-block;
        padding-left: 1px;
      }

      input[type="checkbox"] + label {
        height: 12px;
        width: 12px;
        i {
          font-size: 9px;
        }
      }
    }
    &.header-checkbox {
      label {
        display: inline-block;
      }
    }

  }


