@import "mat/styles/includes";

.import-files-dialog {
  .modal-dialog {
    width: 1200px;
  }
}




