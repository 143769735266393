@import "mat/styles/includes";

.tool-capacity-chart {
  float:left;
  width: 617px;
  height: 439px;
  margin-left: 20px;
  margin-top: 10px;
  background-color: white;
  .chart-title {
    text-align: center;
    font-size: 17px;
    padding-top: 15px;

  }
  .recharts-legend-wrapper {
    left: 50px !important;
  }
  .empty-chart-section{
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .recharts-surface {
    overflow: hidden;
  }

  .recharts-wrapper svg{
    overflow: inherit !important;
  }
  .chart-content{
    display: inline-block;

    .chart-section{
      display: inline-block;
      width: 300px;
    }
  }
}
