@import "infrastructure/styles/theme";

.register-layout {

  $formInnerWidth: 309px;

  background: $BGColor2;
  text-align: center;

  .register-page-img-bg {
    height: 303px;
    background-image: url('../../../../assets/images/login-bg.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media screen and (max-width: $MobileMaxWidth) {
    .register-page-img-bg {
      height: 150px;
    }
    .register-body {
      width: 340px;
      margin: -130px auto 15px auto;
    }
  }

  @media screen and (min-width: ($MobileMaxWidth + 1px)) {
    .register-body {
      width: 670px;
      margin-top: -135px;
      margin-left: calc(50% - 345px);
    }
  }

  .register-body {
    text-align: left;
    position: relative;
    //margin-top: -135px;
    //margin-left: calc(50% - 345px);
    background-color: $BGColor20;
    //width: 670px;

    .register-top-gradient {
      display: block;
      height: 8px;
      background-image: linear-gradient(120deg, $BGColor5 0%, $BGColor5 82%, $BGColor13 82%, $BGColor13 90%, $BGColor19 90%, $BGColor19 100%);
    }

    .register-body-inline {
      padding-top: 40px;
      padding-bottom: 22px;
      border: 1px solid $BorderColor3;
      border-top: 0px solid transparent;

      .logo {
        text-align: center;
      }

      .form {
        padding: 25px 15px 0 15px;
        display: inline-block;

        .register-row {

        .input-section {
            margin-top: 20px;
            text-align: left;

            &.right-side {
              float: right;
            }

            &.left-side {
              float: left;
            }

            label {
              color: $Color7;
              font-size: 12px;
              line-height: 30px;
              font-weight: 400;

              &.textfield-label {
                display: block;
                text-transform: uppercase;
              }
            }

            .PL-textField input {
              border-color: $BorderColor12;
            }

            .pl-combobox {
              width: 309px;

              .pl-select__control {
                border-color: #a6a6a6;
              }
            }
          }
      }

        .register-user-homepage-container {
          display: flex;
          flex-direction: row;
          flex: 1;

            .input-section.register-user-homepage-section {
               float: left;
              .pl-combobox {
                width: 130px;
              }
            }

            .input-section.register-user-homepage-details-section {
              float: left;
              padding-top: 30px;
              margin-left: 6px;

              .pl-combobox {
                width: 500px;
              }
            }

          }
        }

        .register-row-single-row {
          width: 100%;

          .input-section{
            margin-top: 20px;
            text-align: left;
            display: inline-block;

             .PL-textField input {
               border-color: $BorderColor12;
             }
        }
      }

      .btn.btn-primary {
        width: 309px;
      }

      .footer {
        margin-top: 25px;
        display: inline-block;
        width: 100%;
        text-align: center;
      }
    }
  }
}
