@import "mat/styles/includes";

.common-list {
  .common-list-item {
    padding: 10px 20px;
    background-color: #ffffff;
    border-bottom: solid 1px #E9E8E8;

    &:hover {
      background-color: #f9f9f9;
      cursor: pointer;
    }

    &.selected {
      color: $FontColor10;
      font-weight: bold;
    }
  }
}


