@import 'mat/styles/includes';

.kit-type-form {
  height: 100%;
  display: flex;
  flex-flow: column;
  position: relative;

  .title {
    margin-bottom: 15px;
  }

  .common-header.form-header {
    margin: 0;
  }

  .kit-type-form-tabs {
    + .tab-content {
      overflow-y: scroll;
    }
  }

  .tabs-wrapper {
    display: flex;
    min-height: 0;

    .header-wrapper {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;

      .footer-validation-checkbox {
        margin-left: 10px;
      }
    }

    .bags-plies-section {
      display: flex;
      flex-flow: column;
      align-items: flex-start;

      .title {
        padding: 0;
        margin-bottom: 30px;
        border: none;
      }

      &.no-bags {
        .add-bag-btn {
          margin-left: 0;
          margin-bottom: 15px;
        }
      }

      .add-bag-btn {
        margin-left: 15px;
        &:hover {
          border: none;
        }
      }

      .pl-tabs-container {
        width: 100%;
      }

      .ply-types-list {
        position: relative;
        display: grid;
        grid-template-columns: min-content 200px 300px min-content min-content min-content;
        grid-auto-rows: min-content;
        background-color: #eee;
        width: 100%;
        height: 500px;
        padding: 20px;

        &.empty-list {
          .add-row {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .add-remove-list-container-ext {
          position: unset;
          &.list-ext-overflow {
            position: unset;
          }
        }

        .add-remove-list {
          user-select: none;
        }

        .ply-types-list__headers,
        .add-remove-list-container-ext,
        .add-remove-list,
        .list-item,
        .kit-type-ply-type-list-item {
          display: grid;
          grid-template-columns: subgrid;
        }

        .add-remove-list-container-ext,
        .add-remove-list,
        .list-item {
          grid-column: 1 / 7;
          
          &.SortableHelper {
            grid-template-columns: min-content 200px 300px min-content min-content min-content;
            padding: 0;
            background-color: unset;
          }
        }

        .ply-types-list__headers {
          grid-column: 2 / 6;
          font-size: 14px;
          color: #787878;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .entities-multi-select {
          .select-value-row .column {
            width: 100%;
          }
        }

        .kit-type-ply-type-list-item {
          grid-column: 1 / 4;
        }

        .add-row {
          position: absolute;
          bottom: 20px;
          left: 20px;
        }

        .remove-all {
          position: absolute;
          bottom: 20px;
          right: 20px;
        }
      }

      .tab-content {
        height: 500px;
      }

      .nav-link {
        position: relative;
        padding: 0 14px;

        &:hover {
          .bag-title .bag-title-remove-btn {
            display: block;
          }
        }

        .bag-title {
          display: flex;
          align-items: center;

          .bag-title-icons {
            margin-left: 5px;
          }

          .bag-title-remove-icon {
            width: 26px;
            height: 26px;
          }

          .bag-title-remove-btn {
            display: none;
            position: absolute;
            right: 0;
          }
        }
      }

      .plies-summary {
        margin-top: 30px;

        .plies-summary-data{
          display: flex;
          gap: 20px;

          .plies-summary-data-column {
            > div:first-child {
              margin-bottom: 4px;
              text-transform: uppercase;
              color: #707070;
              font-size: 12px;
              line-height: 18px;
              font-weight: bold;
            }
  
            > div:last-child {
              width: 100%;
              padding: 10px;
              font-size: 12px;
              color: #707070;
              border: 1px solid rgba(136, 144, 148, 0.26);
              height: 36px;
            }
          }

        }
      }
    }

    .tabs-content {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .tab-pane.active {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .details-tab {
    .details-tab_left {
      max-width: 580px;
      margin-right: 100px;
      margin-bottom: 25px;

      .pl-combobox {
        max-width: 309px;
      }

      .PL-textAreaField {
        width: 100%;
      }
    }

    .details-tab_right {
      flex: 1;
      min-width: 750px;
    }

    .input-section {
      margin-bottom: 25px;
    }

    .params-section {
      display: flex;
      gap: 10px;

      .input-section {
        flex: 1 0 auto;
      }

      .PL-textField {
        width: 100%;
      }
    }

    .list-materials-title {
      margin: 40px 0 10px 0;
      padding: 0 40px 0 10px;
      height: 35px;
      background: #ddd;
      display: flex;
      justify-content: flex-start;

      .column {
        width: 125px;
        line-height: 35px;
        font-size: 15px;
        &.wide-column {
          flex: 1;
        }
      }
    }

    .required-materials-list {
      .add-remove-list {
        padding: 0 10px 5px 0;
        .list-item {
          align-items: center;

          span.remove-btn {
            align-self: flex-start;
            margin-top: 26px;
            z-index: 1;
          }
          &:only-child {
            .remove-btn {
              pointer-events: none;
              cursor: default;
              color: $FontColor12;
            }
          }
        }
      }
      .add-row {
        margin-top: 10px;
      }
    }
  }

  .sequence-tab {
    .operation-sequence-list {
      min-width: 1100px;
    }

    .operations-list-header,
    .locations-list-header {
      margin-top: 20px;
      margin-bottom: 10px;
      background: #ddd;
      font-size: 15px;
      @include flex-row();
      align-items: center;
    }

    .operations-list-header {
      gap: 10px;

      .column {
        flex: 1;
        display: flex;
        justify-content: flex-start;

        &:first-child {
          flex: 0 1 60px;
          justify-content: center;
        }

        &:nth-child(2) {
          flex: 0 0 calc(50% - 85px);
        }

        &:nth-child(3) {
          flex: 0 0 105px;
        }

        &.tool-type {
          justify-content: space-between;
        }
      }
    }

    .location-sequence-list {
      min-width: 800px;
    }

    .locations-list-header {
      .column:first-child {
        width: 95px;
        margin-left: 5px;
      }
    }
  }

  .add-remove-list-container-ext .add-remove-list .list-item {
    align-items: baseline;
  }

  .pl-combobox {
    width: 100%;
  }
}

.add-bag-popover {
  min-width: 200px;

  .popover-content {
    overflow: visible;

    .popover-component {
      overflow: visible;
    }
  }

}

.bag-select-menu-portal {
  .pl-select__menu {
    position: unset;
  
    .pl-select__option .select-option-row {
      & > :not(:first-child) {
        width: 100%;
        line-height: unset;
      }
  
      .column:first-child {
        display: none;
      }
    }
  }
}