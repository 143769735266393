@import "mat/styles/includes";

.rule-list-item {

  //padding: 10px 0;

  @include flex-row();

  display: flex;
  flex-direction: row;

  //to limit the input expanding when typing
  .pl-combobox .pl-select__control .pl-select__input > input {
    max-width: 135px;
  }

  //.short-textfield {
  //  max-width: 150px;
  //}

  //.medium-textfield {
  //
  //  max-width: 65px;
  //  text-align: center;
  //}

  //.small-textfield {
  //  max-width: 45px;
  //  text-align: center;
  //}

  //.fix-margin-left{
  //  margin-left: 7px;
  //}

  //.fix-margin-middle{
  //  margin-left: 32px;
  //}

  //.fix-margin-right{
  //  margin-left: 51px;
  //}

}






