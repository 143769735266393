@import "../../../../../styles/includes";

.pl-grid .value-with-state-cell {

  position: relative;
  left: -20px;
  //border-left: 2px solid transparent;

  span {
    padding-left: 18px;
    display: block;
    line-height: 30px;
    border-left: 2px solid transparent;
  }
  label {
    padding-left: 18px;
    display: block;
    line-height: 10px;
    height: 10px;
    font-size: 11px;
    color: $FontColor16;
    text-transform: uppercase;
  }
  &.has-error{
    span:first-child {
      color: $IconColor3;
      border-color: $IconColor3;
    }
    //border-color: $IconColor3;
  }
  &.has-warning{
    span:first-child {
      color: $IconColor3;
      border-color: $IconColor4;
    }
    //border-color: $IconColor4;
  }

}







