.smart-selection-grid {
  height: calc(100% - 53px);
  margin-top: 18px;

  .ag-react-container {
    height: 100%;
  }

  .pl-detail-grid {
    .ag-cell {
      line-height: 30px !important;
      padding: 20px;

      .ag-react-container {
        div,
        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
  .ag-header-cell {
    &.group-invisible-header {
      border-right-color: transparent;
    }
  }

  .ag-sticky-top-viewport {
    background-color: #fff;
  }
}
