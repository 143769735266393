@import "infrastructure/styles/theme";

.pl-switch{
  .checkedIcon, .uncheckedIcon {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .checkedIcon {
    padding-left: 4px;
  }

  .uncheckedIcon {
    padding-left: 2px;
  }
}










