@import "infrastructure/styles/generic";
@import "infrastructure/styles/theme";


.input-with-generate-button {

  span.PL-textField {
    width:calc(100% - 100px);

    .input{width:100%;}
    &:not(.invalid) {
      input:not(:focus) {
        border-right-color: #ffffff;
      }
    }

  }
  .btn.generateButton {
    display: inline-block;
    margin-left: 0px;
    height: 36px;
    border-color: $BorderColor8;
    vertical-align: top;
    padding-right: 10px;
    padding-left: 10px;
    color: $FontColor10;
    font-size:12px;
    font-weight: 700;
    width:100px;
  }

}
