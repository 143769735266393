@import 'mat/styles/includes';

.orgs-dashboard-view {
  padding: 23px 0 10px 0;
  height: 100%;
  position: relative;
}

.orgs-dashboard-header {
  position: absolute;
  top: 23px;
  left: 0;
}
