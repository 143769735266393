@import "mat/styles/includes";
@import "infrastructure/styles/theme";


.shelf-life-update-dialog{
  .modal-dialog {width: $dialog2ColumnsWidth;}

  //to limit the input expanding when typing
  .pl-combobox .pl-select__control .pl-select__input > input {
    max-width: $comboboxInputWidth9;
  }

  .pl-combobox.pl-multi-select-field.entity-value-select {
    .select-value-row .extra-data{
      width: 320px;
    }
  }

  hr {
    border-color: $BGColor29;
    margin-bottom: 15px;
  }

  .comment-textField {
    width: 100%;
  }

  .row-details {
    div:not(:first-child) {
      margin-left: 10px;
    }
    .row-details-title {
      max-width: 100px;
    }
    .row-details-asset {
      font-weight: bold;
      max-width: 290px;
    }
    .row-details-extra-data {
      color : $FontColor4;
      max-width: 270px;
    }
  }

  .updated-data{
    padding-left: 10px;
    display: inline-block;
    div{
      display: inline-block;
      font-size: 14px;
      color: $FontColor2;
    }
    .pl-move-arrow-right{
      font-size: 10px;
      vertical-align: top;
      line-height: 21px;
      margin: 0 7px;
    }
  }

  .ETL-operation{
    width: 218px;
  }

  .etl-time-wrapper {
    display: inline-block;
  }

  .etl-by-range {
    .ETL-operation {
      width: 160px;
    }
    .time-value-wrapper .hours {
      width: 60px;
    }

    .ETL-calendar-trigger {
      background: white url('infrastructure/assets/images/calendar.png') no-repeat center;
      width: 26px;
      height: 26px;
      top: 50%;
      right: 6px;
      transform: translateY(-50%);
      position: absolute;
    }

    .etl-time-wrapper {
      display: inline-block;
      position: relative;

      .time-value-wrapper {
        width: 144px;
      }

      .popover-target {
        background: white url('infrastructure/assets/images/calendar.png') no-repeat center;
        width: 26px;
        height: 26px;
        top: 50%;
        right: 6px;
        transform: translateY(-50%);
        position: absolute;
      }
    }
  }

  .etl-section{
    display: inline-block;
    vertical-align: top;
    margin-left: 30px;
    height: 60px;
    margin-top: 18px;
    width: 300px;

    .input-section{
      padding-top: 5px;
      .pl-checkbox{
        width:100px;
      }
    }
  }
}
