@import "mat/styles/includes";

.edit-dashboard-demo-data-dialog {

  .modal-dialog {width: $dialog2ColumnsWidth;}

  .modal-body {
    .input-section:first-child {
      margin-top: 0;
    }
    .PL-textField > input {
      padding-right: 30px;
    }


    .use-custom-content {
      margin: 10px 0 0 33px;
    }


  }
}
