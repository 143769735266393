@import 'infrastructure/styles/theme';

.marketing-layout {
  position: relative;
  height: 100%;
  display: flex;

  .marketing-layout-left {
    z-index: 3;
    flex: 0 0 556px;
    background-color: rgba(255, 255, 255, 0.98);
    padding: 20px;
  }

  .marketing-layout-right {
    display: flex;
    width: 100%;
    z-index: 2;
    position: relative;
    overflow: hidden;
    margin: 20px;
    border-radius: 16px;
    box-shadow: 0 0 0 2000px rgba(255, 255, 255, 0.98);
  }

  .loading-overlay {
    color: $FontColor5;
  }
}
