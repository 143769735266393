.archive-kits-grid {
  height: calc(100% - 53px);
  margin-top:18px;

  .ag-cell {
    line-height:30px !important;
    padding: 20px;
  }
}


