@import 'mat/styles/includes';

.qualified-events-grid {
  height: calc(100% - 78px);
  .ag-cell {
    line-height: 25px !important;
    padding: 20px;
    white-space: normal;
  }
}
