@import "mat/styles/includes";

.panel-container {

  .panel-body {
    padding: 0px;
    border: 2px solid #4EA5B5;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    .header-wrapper {
      height: 55px;
      padding: 0 15px;
      border-bottom: 1px solid #EBEBEB;

      .header-title {
        color: $FontColor23;
        font-size: 18px;
        font-weight: 500;
        float: left;
        width: 214px;
        line-height: 55px;
      }

      .header-secondary-title {
        color: #4EA5B5;
        font-size: 15px;
        width: 215px;
        float: right;
        text-align: right;
        line-height: 55px;

        label {
          cursor: pointer;
        }
      }

      .disable{
        pointer-events: none;
        color: #a6a6a6;

        label {
          cursor: pointer;
        }
      }
    }

    .panel-content {
      position: relative;
      overflow: auto;
      max-height: calc(100vh - 220px);
    }

    .footer-wrapper {
      border-top: 1px solid #EBEBEB;
      padding: 15px 15px 30px 15px;

      .footer-title {
        font-size: 14px;
        font-weight: bold;
        float: left;
        line-height: 16px;
        width: 214px;
        color: #7D2853;

        label {
          font-size: 16px;
          margin-right: 7px;
          font-weight: normal;
        }

      }

      .footer-secondary-title {
        color: #7D2853;
        line-height: 16px;
        font-weight: 500;
        font-size: 14px;
        width: 213px;
        float: right;
        text-align: right;

        label {
          color: #7D2853;
          cursor: pointer;
          font-size: 12px;
        }
      }
    }
  }

.system-placeholder {
   font-weight: bold;
   font-size: 20px;
   padding-left: 60px;
  }
}



