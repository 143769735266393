.all-items-list {
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 9;
  position: relative;
  .items {
    width: 100%;
    padding: 10px;
    border: 1px solid #d1d1d1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    .item {
      width: 90%;
      margin-bottom: 5px;
    }
  }
}
