@import "mat/styles/includes";

.create-tool-category-dialog {

  .modal-dialog {width: $dialog2ColumnsWidth;}

  .modal-body {
    .add-remove-list-container-ext {
      &.list-ext-overflow {
        max-height: 400px;
      }
      .add-row {
        margin-top: 10px;
      }
    }
  }
}
