@import 'mat/styles/includes';

.user-management-page {
  height: 100%;

  .common-header {
    margin-top: 15px;
  }
}

//Note: for the User Management' in the 'Orgs Management'
.app-container > .app-container-main > .app-container-content > .user-management-page {  //Note: for Org Management only
  padding: 23px 30px 10px 30px;
  .common-header {
    margin: 0;
  }
}





