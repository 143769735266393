@import "../../../../../../../styles/includes";

.create-promotion-dialog {
  .modal-dialog {
    width: $dialogBig1ColumnWidth;
  }

  .pl-checkbox {
    margin-top: 8px;
  }

  .PL-textAreaField {
    height: 100px;
  }
}
