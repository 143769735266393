@import 'infrastructure/styles/theme';

.pl-grid {
  .multi-properties-filter {

    .properties-dropdown {
      width: 120px;
    }

    .text-field {
      margin-left: 8px;
      width: 174px;
      height: 36px;
    }

    .filter-footer {
      margin-top: 20px;
    }
  }
}
