@import "infrastructure/styles/theme";

$DateBoxMargin: 5px;

.date-range-picker {
  .popover-content {
    max-height: unset;
  }
}

.date-range-datepicker-area {
  margin-top: 10px;

  .date-range-datepicker-container {
    display: block;
    width: 100%;
    background-color: $BGColor12;
    padding-bottom: 2px;
    margin: 5px 0;

    span {
      color: $FontColor13;
      font-family: $FontFamily1;
      text-transform: uppercase;
      font-size: $FontSize3;
      font-weight: bold;
      margin-left: $DateBoxMargin;
    }

    .date-range-datepicker {
      margin: $DateBoxMargin;
    }
  }

  .btn {
    height: 35px;
    width: 72px;
  }

  .btn.btn-primary {
    float: right;
  }
}

.date-range-popover-target {

  border: 1px solid $BGColor29;
  background: white url('../../../../assets/images/calendar.png') no-repeat right 7px center;
  height:36px;
  transition: border-color 100ms;

  &:hover {
    border-color: $Color2;
  }

  .date-range-label {
    text-align: center;
    font-size: 11.5px;
    display: inline-block;
    width: 82%;
    line-height: 32px;
    text-transform: capitalize;
    margin-top: 1px;
    margin-bottom: 0;
    white-space: nowrap;
  }
}

#date-range-popover.pl-popover.date-range-popover {
  border-color: $BGColor29;
  .arrow {
    border-bottom-color: $BGColor29;
  }
}
