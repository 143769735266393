@import "../../../../../../styles/includes";

.create-tool-dialog.dialog{
  .modal-dialog {width: $dialog2ColumnsWidth;}
  .modal-body{
    max-height: $modalBodyMaxHeight;
    position: static;
  }
  .PL-textAreaField {
    height: 85px;
  }
  .time-value-wrapper{
    width:100%;
  }
}

