@import 'mat/styles/includes';

.material-list-item {
  @include flex-row();
  flex-wrap: wrap;
  min-width: 580px;

  > div:first-child {
    display: flex;
    flex: 1 0 100%;
    gap: 10px;

    > div:first-child {
      flex: 1;
    }
  }

  .short-textfield {
    max-width: 125px;
  }

  .pl-combobox .pl-select__multi-value .pl-select__multi-value__label {
    width: 100%;
  }
}
