.shift-types {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  margin-bottom: 15px;
  border-bottom: 2px solid #eee;

  .no-shifts-defined {
    flex: 1;
    text-align: center;
  }
}

.shift, .eraser, .shift-drag-image {
  display: flex;
  align-items: center;
  padding: 14px;
  margin: 7px;
  cursor: move;
  user-select: none;
  border-radius: 4px;
  font-weight: bold;

  span:first-child {
    margin-right: 14px;
  }
}

.shift-name {
  margin-right: 5px;
}

.shift-drag-image {
  position: absolute;
  top: -999999px;
}

.eraser {
  border: solid 1px #707070;
}
