@import "mat/styles/includes";


.import-files-dialog {
  .body-top-section.title {
    margin-bottom: 20px;
    & > div {
      &:first-child {
        margin-right: 15px;
      }
    }
  }
  .modal-footer {
    .left-section {
      button:last-child {
        margin-left: 30px;
      }
    }
    .right-section {
      display: inline-flex;

      .floating-center-text > label {
        max-width: unset;

        .checkbox-right-section {
          display: flex;
          align-items: center;

          label {
            margin: 0 5px;
          }

          .PL-textField {
            width: 35px;
            height: 25px;

            .form-control {
              padding: 0 5px;
            }
            img {
              height: 14px;
            }
          }
          .popover-target span {
            vertical-align: baseline;
          }
        }
      }

    }
  }
}

.import-info-selection {
  font-size: 15px;

  .import-info-items {
    margin-top: 5px;
    line-height: 34px;

    .import-info-item {
      display: table;

      .error {
        color: $IconColor3;
      }
      .warning {
        color: $IconColor4;
      }
      .blue-color {
        color: $FontColor5
      }

      .import-info-msg {
        display: table-cell;
        vertical-align: middle;
      }

      .import-info-icon {
        font-size: 17px;
        display: table-cell;
        vertical-align: middle;
        width: 25px;
      }

    }
  }
}

.operation-sequence-confirmation-dialog {
  .modal-body {
    .line {
      margin-left: 55px;
      .label {
        width: 100%;
        color: #333;
        font-size: 15px;
        font-weight: bold;
      }
      .value {
        width: 0;
      }
    }
  }
}
