@import "infrastructure/styles/theme";

.common-header {
  min-height: 35px;

  @include flex-row();
  justify-content: space-between;
  align-items: center;

  .dropdown, .main-operation {
    margin-left:8px;
  }

  .title {
    font-size: 18px ;
    font-weight: 700;
    text-transform: uppercase;
  }

  .flex-grow-2 {
    flex:auto;
    flex-grow: 2;
  }

  .flex-grow-0 {
    flex:auto;
    flex-grow: 0;
  }
}
