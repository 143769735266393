@import "../../../../../../styles/includes";

.create-resin-dialog.dialog{

  .modal-dialog {width: 900px;}

  .modal-body {
    max-height: $modalBodyMaxHeight;
    position: static;

    .measurements-section {
      width: calc(50% - 15px);
      display: inline-flex;
      >div {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
      .label-extra-component {
        text-transform: none;
      }
    }
  }


  .PL-textAreaField {
    height: 85px;
    width: 100%;
  }
  .time-value-wrapper{
    display: block;
  }
  .header-checkbox-wrapper{
    float: none;
    margin-left: 10px;
  }
  #maxExposureTimeCureStart, #maxExposureTimeCureEnd{
    .react-datepicker{
      width: 450px;
      .react-datepicker__navigation--next--with-time {
        right: 120px;
      }
    }
  }
  .weight-button{
    margin-top: 36px;
    .pl-error-icon{
      color: $IconColor3;
      top: 5px;
      position: relative;
      font-size: 22px;
      img {
        vertical-align: middle;
      }
    }
    .pl-check-circle-icon-blue{
      color: $IconColor5;
      top: 5px;
      position: relative;
      font-size: 22px;
      left: 28px;
      img {
        vertical-align: middle;
      }
    }
    .popover-target {
      display: inline;
      left: 28px;
      position: relative;
    }
  }
}

