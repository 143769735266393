@import "mat/styles/includes";


.create-visible-device-dialog{
  .modal-dialog {
    width: $dialog2ColumnsWidth;

    .pl-combobox .pl-select__multi-value .pl-select__multi-value__label {
      max-width: 125px;
    }

    .input-section.inline.small {
      width: 206px;

    }

    .padding-top {
      padding-top: 26px;
    }
    .short-textfield{
      width: 200px;
    }
  }
}


