@import 'mat/styles/includes';

.common-csv-settings-view {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;

  .common-header {
    margin: 15px 0;
  }

  .common-csv-settings-view-tabs-section {
    height:calc(100% - 65px);
    >div {
      height:100%;
    }
    .tab-content{
      margin-left:0;
      margin-right:0;
      height:calc(100% - 47px);

      .tab-pane.active {
        height:100%;
      }
    }
  }

}
