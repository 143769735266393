@import "mat/styles/includes";

.scan-input-container {
  margin-bottom: 10px;
  text-align: center;
  .barcode-input {
    @include flex-row();
    border: 1px solid $BorderColor6;

    .PL-textField {
      height: 100%;
    }
    input {
      height: 45px;
      width: 100%;
      padding-left: 10px;
      font-size: 20px;
      border: none;
    }
    button {
      height: 45px;
      width: 45px;
      font-size: 12px;
      border: none;

      color: $FontColor10;
      &:disabled {
        color: $BorderColor22;
      }
      &.submit {
        font-size: 18px;
        .pre-button {
          border-left: 1px solid $BorderColor22;
        }
      }
      &:hover {
        border-bottom: none;
      }
    }
  }

  .barcode-input-error {
    color: $FontColor20;
    margin: 0 auto;
    font-weight: normal !important;
  }
}




