@import "mat/styles/includes";

.scan-tag-page {
  background-color: $BGColor2;
  position: relative;

  .content {
    font-size: 20px;
    min-height: calc( 100% - 120px);
    height: calc( 100% - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    .section {
    }

    .scan-input-section {
      margin: 30px 20px 0;
    }

    .prompt-text {
      margin: 0 60px 30px;
      text-align: center;
    }

    .barcode-image {
      @media screen and (max-height: 550px) {
        display: none;
      }
      text-align: center;
      img {
        height: 290px;
      }
    }
  }

  .scan-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

}





