@import 'infrastructure/styles/theme';

.assets-file-chooser {
  margin-top: 10px;
  margin-bottom: 10px;

  .import-type-selection {
    display: inline-block;
    margin-right: 10px;
    width: 135px;
  }

  .input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}




