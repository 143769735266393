@import "mat/styles/includes";

.communication-parameters-view {

  .input-section {
    margin-top: 18px;
  }
}









