@import "mat/styles/includes";

.logo-section{
  width:64px;
  height: 64px;
  border-right:1px solid $BorderColor5;
  vertical-align:middle;
  line-height:18px;
  text-align:center;
  display:inline-block;
  padding-top: 10px;

  .plataine-logo {
    width: 33px;
    height: 32px;
  }

  label, .org-label {
    display: block;
    color:$FontColor1;
    font-size:$FontSize2;
    margin: 0 3px;
    &:hover {
      cursor: pointer;
    }
  }
  .logo-label {
    font-weight: bold;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover{
    background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 252, 252, 0.156) 100%);
    background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 252, 252, 0.156) 100%);
    background: -o-linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 252, 252, 0.156) 100%);
    background: -ms-linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 252, 252, 0.156) 100%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(255, 252, 252, 0.156) 100%);
  }
  &:active{
    background: -webkit-linear-gradient(90deg, rgba(255, 252, 252, 0.24599999999999997) 0%, rgba(0, 0, 0, 0) 100%);
    background: -moz-linear-gradient(90deg, rgba(255, 252, 252, 0.24599999999999997) 0%, rgba(0, 0, 0, 0) 100%);
    background: -o-linear-gradient(90deg, rgba(255, 252, 252, 0.24599999999999997) 0%, rgba(0, 0, 0, 0) 100%);
    background: -ms-linear-gradient(90deg, rgba(255, 252, 252, 0.24599999999999997) 0%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(0deg, rgba(255, 252, 252, 0.24599999999999997) 0%, rgba(0, 0, 0, 0) 100%);
  }
}
