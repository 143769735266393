@import 'mat/styles/includes';
@import 'infrastructure/styles/theme';

.dashboards-demo-data-view {
  display: inline-block;
  height: 100%;
  position: relative;

  .common-header {
    margin: 15px 0;
  }

  .dashboards-list {
    padding: 20px 0;

    .dashboards-list-header {
      height: 35px;
      background: #ddd;
      font-size: 15px;
      @include flex-row();
      align-items: center;

      .column {
        text-align: center;
        &:first-child {
          width: 230px;
        }
        &:last-child {
          width: 100px;
        }
      }
    }

    .dashboards-list-row {
      @include flex-row();
      padding: 10px 20px;
      align-items: center;
      justify-content: space-between;

      border-bottom: 1px solid #cccccc;

      .cell:first-child {
        width: 225px;
      }
      .cell:nth-child(2) {
        width: 100px;
      }

      .cell:last-child {
        width: 495px;
      }

      button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

}


