@import "mat/styles/includes";

.rolls-status-chart {
  width: 317px;
  display: inline-block;
  margin-left: 20px;
  margin-top: 10px;
  background-color: white;
  height: 439px;
  .separator {
    height: 10px;
  }
  .chart-section {
    display: inline-block;
    width: 300px;
    height: 200px;
    .popover-target {
      .alert-icon {
        font-size: 16px;
        display: inline-block;
        vertical-align: middle;
        //color: black;
        padding-left: 5px;
      }
    }
    .recharts-wrapper {
      height: 150px !important;
    }
    .chart-title {
      padding-top: 15px;
      text-align: center;
      vertical-align: middle;
      font-size: 17px;
    }
    .chart-footer {
      text-align: center;
      color: red;
      vertical-align: middle;
    }
  }

  .custom-legend {
    font-size: 11px;
    text-transform: uppercase;
    &.pl:before {
      margin-right: 5px;
      color: #367A87;
      font-size: 16px;
    }
  }
}
