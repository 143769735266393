@import 'infrastructure/styles/theme';

.admin-form{
  .form-buttons {
    @include flex-row();
    justify-content: flex-end;
    align-items: center;

    .btn.btn-default.border-btn {
      span {
        font-size: 18px;
      }
    }
  }

  .form-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.35;
    z-index: 99;
    background: $BGColor2;
    display: none;
    &.active {
      display: block;
    }
  }
}



