@import "mat/styles/includes";

.archive-workorder-dialog {
  .modal-dialog {
    height: 385px;
    width: $dialogBig1ColumnWidth
  }

  .pl-dropdown {
    width: 200px;
  }

  .marked-assets-section label {
    height: 18px;
  }

  .comment-textField {
    width: 100%;
  }
}
