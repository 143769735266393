@import 'infrastructure/styles/theme';
//@import "infrastructure/js/components/controls/Combobox/combobox.scss";
//react-datepicker__tether-element
//react-datepicker__tether-enabled
$DatePickerBGcolor: $BGColor2;
.pl-datepicker {
  height: 36px;
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      width: 100%;
      input {
        background: $DatePickerBGcolor url('../../../../../infrastructure/assets/images/calendar.png') no-repeat right 7px center;
        border: solid 1px $BorderColor8;
        box-sizing: border-box;
        width: 100%;
        height: 36px;
        padding: 0 35px 0 11px;
        font-family: $FontFamily1;
        color: $FontColor11;
        font-size: 14px; /* Approximation due to font substitution */
        font-weight: 400;
        line-height: 30px; /* Approximation due to font substitution */
        text-align: left;

        &:focus, &:active {
          border-color: $BorderColor6;
          outline: none;
        }
        &:disabled {
          border-color: $BorderColor8;
          color: $FontColor2;
        }
      }

      .react-datepicker__close-icon{
        margin-right: 30px;
        margin-top: 3px;
        background: url('../../../../assets/svg/x-input-close.svg') no-repeat;
        background-size: 80%;
        &::after{
          background-color: transparent;
          color: transparent;
        }
      }
    }

  }

  .react-datepicker-popper {
    z-index: $ZIndex-DatepickerPopper;
  }
  .pl-datepicker-popper {
    .react-datepicker:has(.react-datepicker__month-container):has(.react-datepicker__time-container ) {
     width: 428px;
      .react-datepicker__navigation--next {
        right: 90px;
      }
    }
  }
  &.invalid {
    .react-datepicker__close-icon {
      display: none;
    }
  }
}

.pl-datepicker, .pl-datepicker-overlay {
  .react-datepicker {
    $arrowDistance: 20px;

    font-family: Roboto;
    border-radius: 0;
    padding: 30px $arrowDistance 10px;
    border: solid 1px $BorderColor17;
    box-shadow: 2px 2px 2.91px 0.09px rgba(76, 76, 76, 0.16);

    .react-datepicker__time-container {
      .react-datepicker__time-box {
        width: 80px;
      }

      .react-datepicker__time-list {
        padding: 0;
        font-size: 12px;
      }

      .react-datepicker-time__header {
        font-size: 12px;
      }
    }

    .react-datepicker__navigation--previous, .react-datepicker__navigation--next {
      margin-top: 32px;
      border-width: 7px;
    }

    $arrowColor: #898989;

    .react-datepicker__navigation--previous {
      border-right-color: $arrowColor;
      left: $arrowDistance;

      &:hover, &:active {
        border-right-color: $BGColor1;
      }
    }

    .react-datepicker__navigation--next {
      border-left-color: $arrowColor;
      right: $arrowDistance;

      &:hover, &:active {
        border-left-color: $BGColor1;
      }
    }

    .react-datepicker__triangle {
      border-bottom-color: $DatePickerBGcolor !important;
    }

    .react-datepicker__header {
      background: $DatePickerBGcolor;
      border-bottom: 0 solid transparent;
      border-radius: 0;
      padding-top: 0;

      .react-datepicker__current-month--hasYearDropdown {
        display: none;
      }

      .react-datepicker__header__dropdown {
        margin-top: 0;
      }
    }

    .react-datepicker__day-name, .react-datepicker__day {
      $cellSize: 31px;
      line-height: $cellSize;
      width: $cellSize;
      height: $cellSize;
      color: $FontColor13;
      font-size: 14px;
      text-align: center;
      margin: 6px 6px;
    }

    .react-datepicker__day {
      border: 1px solid transparent;
      border-radius: 0;
      font-weight: 400;
      text-align: center;

      &:hover {
        background: $BGColor11;
        border-radius: 0;
      }

      &:active {
        background: $BGColor12;
        border-radius: 0;
      }
    }

    .react-datepicker__day--today {
      border: 1px solid $BorderColor6;
      background-color: $DatePickerBGcolor;
    }

    .react-datepicker__day--selected {
      border: 1px solid $BorderColor6;
      background-color: $BGColor5;
      color: $FontColor3;

      &:hover {
        background-color: $BGColor6;
        color: $FontColor3;
      }

      &:active {
        background-color: $BGColor7;
        color: $FontColor3;
      }
    }

    .react-datepicker__day--disabled {
      color: $FontColor14;

      &:hover {
        background-color: $DatePickerBGcolor;
      }
    }

    .react-datepicker__day-name {
      font-weight: 700;
      margin-bottom: 0;
      margin-top: 25px;
    }

    .react-datepicker__month-container {
      background: $DatePickerBGcolor;
    }

    .react-datepicker__today-button {
      background: $BGColor5;
      color: $FontColor3;
      font-size: 16px;
      line-height: 30px;
      padding: 0;
      margin: 0 60px 10px;

      &:hover {
        background-color: $BGColor6;
        color: $FontColor3;
      }

      &:active {
        background-color: $BGColor7;
        color: $FontColor3;
      }
    }

    .react-datepicker__month-select,
    .react-datepicker__year-select {
      border: 1px solid $BorderColor3;
      background: $DatePickerBGcolor url('../../../../../infrastructure/assets/images/down-arrow.png') no-repeat right 10px center;
      height: 35px;
      width: 125px;
      padding: 0 30px 0 5px;
      margin: 0 2px;

      font-family: 'Tahoma';
      color: $FontColor11;
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;

      appearance: none;
      -webkit-appearance: none;

      &::-ms-expand {
        display: none;
      }

    }
  }

}





