@import "mat/styles/includes";

.user-menu {
  top: 30px;
  right: 0;

  $zIndex: 10000;

  width: 200px;

  background: $BGColor1;
  border: 0 solid transparent;
  border-radius: 0;

  z-index: $zIndex;
  position: absolute;
  height: 0;

  &.show {
    height: unset;
  }
  &.panel-body{
    padding: 0;
    border: 1px solid $BGColor3;
  }

  .org-name {
    font-size: 14px;
    color: $FontColor3;
    text-transform: uppercase;
    text-align: center;
    margin: 9px 0 17px 0;
    cursor: default;
  }

  .user-menu-item{
    padding: 0 15px;
    line-height: 35px;
    color: $FontColor3;
    cursor: pointer;

    &:hover {
      background: $BGColor3;
    }
  }

  .copyrights{
    margin-top: 3px;
    text-align: left;
    padding: 0 7px;
    height: 18px;
    line-height: 15px;
    color: #858585;

    &:hover{
      background: $BGColor1;
      cursor:default;
    }

    .special-character {
      font-size: 13px;
    }

    .info {
      font-size: 10px;
      vertical-align: top;
    }
  }

  .version{
    font-size: 9px;
    text-align: left;
    padding: 0 15px;
    height: 18px;
    line-height: 10px;

    &:hover{
      background: $BGColor1;
      cursor:default;
    }
  }
}
