@import "mat/styles/includes";

.assign-scheduler-task-dialog {

  .modal-dialog {width: $dialogBig1ColumnWidth;}

  .modal-body {

    .react-datepicker {
      .react-datepicker__navigation--next--with-time {
        right: 90px;
      }
    }

    .pl-combobox {
      .popover-target {
        display: inline-block;
        width: 20px;
      }

      .pl-select__option {
        > span:first-child {
          width: 20px;
          display: inline-block;
          &.warning {
            color: $IconColor4;
          }
        }
      }
    }
  }
}
