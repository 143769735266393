@import 'mat/styles/includes';

.create-alert-device-dialog {
  .modal-dialog {width: $dialog2ColumnsWidth;}

  .modal-body {
   .antennas-type-select {
     width: 110px;
   }
   .antennas-select {
     width: calc( 100% - 110px);

     &.pl-combobox.pl-multi-select-field .pl-select__multi-value {
       max-width: 150px;
       .pl-select__multi-value__label {
         max-width: 126px;
       }
     }
   }

   .device-params-section{
     margin-top: 30px;
      .input-section.inline.small {
        width: 206px;
        font-size: 14px;


        &:not(:first-child) {
          margin-left: 10px;
        }
      }
  }

   .rules-wrapper {

     width: 100%;
     margin-top: 40px;

     .rules-header {
       margin-bottom: 10px;
       height: 35px;
       background: #ddd;;
       font-size: 15px;

       display: flex;
       flex-direction: row;
       justify-content: space-between;
       align-items: center;

       label {
         margin: auto;
         //margin-left: 30px;
         //width: 206px;
       }

     }

     .rules-content {

     }
   }
}

}
