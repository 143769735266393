@import "../../../../../../styles/includes";

.select-resulting-asset-step {
  padding: 20px 30px;

  .entity-value-select {
    div.pl-select__single-value {
      width: 100%;
    }
  }

  .select-row.select-option-row {
    display: flex;

    span:first-child {
      width: 20px;
    }
    > :not(:first-child) {
      flex: 1 1 auto;
    }
  }

  .select-row.select-value-row {
    display: flex;

    > div:last-child {
      flex: 0 0 auto;
    }
    > :not(:last-child) {
      flex: 1 1 auto;
    }
  }

  .input-section.layers-section {
    margin-top: 30px;

    .layers-list {
      background-color: white;
      padding: 10px 20px;

      > div:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
}
