@import "mat/styles/includes";

.multi-entities-multi-search {
  position: relative;

  .select-mode-container {

    @include flex-row();
    text-transform: uppercase;
    margin: 5px 0px 15px 0px;

    .mode {
      width: 85px;
      border: solid 1px;
      border-color: $FontColor5;
      text-align: center;
      color: $FontColor5;
      padding: 0px 5px;
      line-height: 30px;
      font-weight: bold;


      .pl {
        margin-left: 5px;
        color: #d9385c;
      }
    }

    .mode:hover {
      border-color: $FontColor6;
      color: $FontColor6;
      cursor: pointer;
    }

    .mode.active {
      border-color: $FontColor5;
      background-color: $FontColor5;
      color: white;
      cursor: default;
    }

  }

  .small label {
    display: inline-block;
  }

  .hiddenScanInput {
    width: 50px;
    position: absolute;
    right: 0;
    top: 0;
    color: transparent;
    border: none;
    background-color: transparent;
    &:focus {
      outline: transparent;
    }
  }

  .by-tag-id-section {
    @include flex-row();
    align-items: center;
    margin-bottom: 10px;

    .entities-multi-select {
      .entity-type-select {
        display: none;
      }
      .tags {
        width: 200px;
      }
    }

    .by-tag-id-asset {
      line-height: 35px;
      margin: 0 10px;
      width: 170px;
    }
  }

  .errors-container {
    margin-top: 20px;
    max-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;

    .error-item {
      .pl {
        margin-right: 10px;
        color: #d9385c;
      }
    }

  }
}
