@import "infrastructure/styles/theme";

.PL-textField {
  width: 309px;
  display: inline-block;
  height: 36px;
  vertical-align: top;

  input, textarea {
    border-style: solid;
    border-width: 1px;
    border-color: $BorderColor8;
    box-sizing: border-box;
    background-color: $BGColor2;
    width: inherit;
    height: inherit;
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    color: $BGColor3;
    &, &.form-control {
      &:focus {
        border-color: $BorderColor6;
        box-shadow: none;
      }
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $FontColor2;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $FontColor2;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $FontColor2;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $FontColor2;
    }
  }


  // Icon support inside Text fields.
  &.pl {
    position: relative;

    input {
      padding-left: 36px;
    }
  }
  &.pl:before {
    color: $Color2;
    font-size: 18px;
    position: absolute;
    top: 8px;
    left: 9px;

  }

}









