@import 'infrastructure/styles/theme';

.pl-datepicker-touch {
  position: relative;

  .pl-datepicker {
    height: 36px;

    .react-datepicker__close-icon {
      margin-right: 45px;
    }

    &.invalid .invalid-icon {
      right: 50px;
    }
  }

  &.with-time-select {
    .pl-datepicker {
      .react-datepicker__close-icon {
        margin-right: 108px;
      }

      &.invalid .invalid-icon {
        right: 90px;
      }
    }
  }

  .react-datepicker-popper {
      margin-top: 0;

      .react-datepicker {
        padding: 5px;

        .react-datepicker__triangle {
          display: none;
        }

        &.react-datepicker--time-only {
          padding: 5px;

          .react-datepicker__time-container {
            width: 65px;
            .react-datepicker__header--time {
              .react-datepicker-time__header {
                font-size: 16px;
              }
            }
            .react-datepicker__time-box {
              width: 64px;
            }

            .react-datepicker__time-list {
              padding: 0;
            }
          }
        }

        .react-datepicker__navigation {
          &.react-datepicker__navigation--next, &.react-datepicker__navigation--previous {
            margin-top: 5px;
          }
        }

        .react-datepicker__month-container {
          .react-datepicker__current-month {
            font-size: 16px;
            margin-top: 10px;
          }
        }

        .react-datepicker__day-name, .react-datepicker__day {
          margin: 6px;
        }
      }
    }

  .buttons-container {
    position: absolute;
    top: 1px;
    right: 1px;
    .btn.btn-default.border-btn {
      height: 34px !important;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;

      &:hover {
        background: #EEEEEE !important;
        border-color: #7d2853;
      }
    }
  }
}








