@import 'mat/styles/includes';

.notifications-center {
  position: relative;
  display: flex;
  align-items: center;

  .notifications-icon-wrapper {
    cursor: pointer;
    position: relative;
  }

  .running-jobs {
    position: absolute;
    left: -13px;
    top: -6px;
    .fa-circle-o-notch {
      font-size: 40px;
      color: #60c7e0;
    }
  }

  .notifications-label {
    border-radius: 50%;
    display: inline-block;
    line-height: 13px;
    width: 23px;
    padding: 4px;
    margin: -9px;
    text-align: center;

    position: relative;
    top: -15px;
    right: 4px;

    background-color: $IconColor3;
    color: $IconColor1;
    font-weight: bold;
    font-size: 11px;

    &.pulse {
      animation: pulse 1s 5;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1);
    }
  }
}
