@import "infrastructure/styles/theme";

.pl-popover {
  $arrowAfterDistance: 3px;

  position: fixed;
  border: 2px solid $Color2;
  border-radius: 0;
  @include arrow-border-color($Color2);
  padding: 0;
  font-size: 14px;
  box-shadow: $BoxShadow;
  max-width: 500px;
  z-index:40001;

  .popover-content {
    //padding: 0px 15px 10px 15px;
    padding: 10px 15px;
    line-height: 8px;
    max-height: 200px;
    overflow: auto;
    background: $Color3;

    .popover-title {
      border-radius: 0;
      border-bottom: 0;
      font-size: 18px;
      margin-bottom: 10px;
      padding: 0;
      background: $Color3;
    }

    .popover-component {
      padding: 0;
      line-height: 18px;
      max-height: calc(100% - 40px);
      overflow: auto;
    }
  }

  &.bs-popover-top {
    .popover-arrow::after {
      bottom: $arrowAfterDistance ;
    }
  }

  &.bs-popover-bottom {
    .popover-arrow::after {
      top: $arrowAfterDistance;
    }
  }

  &.bs-popover-start {
    .popover-arrow::after {
      right: $arrowAfterDistance;
    }
  }

  &.bs-popover-end {
    .popover-arrow::after {
      left: $arrowAfterDistance;
    }
  }
}

.popover-target {
  display: inline;
}

.pl-popover[data-popper-reference-hidden="true"] {
  visibility: hidden;
  pointer-events: none;
}
