@import 'mat/styles/includes';
@import 'infrastructure/styles/theme';

.work-orders-management-view {
  height: 100%;
  .common-header {
    margin: 15px 0;
  }
}


