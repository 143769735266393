@import "../../../../styles/includes";

.add-to-pick-list-dialog {
  .modal-dialog {
    height: 385px;
    width: $dialogBig1ColumnWidth;


    .entities-multi-select .entity-type-select {
      width: 115px;
    }

    .select-row.select-value-row .column:first-child {
      width: 100%;
      vertical-align: middle;
    }

    .entities-multi-select {
      .pl-select__option .select-option-row > :first-child {
        width: 0;
      }

      .pl-select__option .select-option-row > :not(:first-child) {
        width: calc(100% - 15px);
      }
    }
  }
}
