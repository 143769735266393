@import "mat/styles/includes";

.move-asset-dialog {
  .modal-dialog {width: $dialogBig1ColumnWidth;}

  .modal-body{
    label {
      text-transform: uppercase;
    }

    .no-result-for {
      span:last-child {
        font-weight: bold;
      }
    }
  }
  //to limit the input expanding when typing
  .select-destination .pl-combobox .pl-select__control .pl-select__input > input {
    max-width: $comboboxInputWidth8;
  }
}
//location link in confirmation
#globalMessageDialog div.move-assets-confirmation-link > a {
  font-size: 20px;
  vertical-align: text-bottom;
  line-height: 30px;
}
