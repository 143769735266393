.task-button-wrapper {
  margin-bottom: 10px;
  .btn {
    height: 60px;
    width: 100%;
    color: #fff;
    &.btn-default:hover {
      color: #fff;
    }
  }
}
