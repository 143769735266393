@import "infrastructure/styles/theme";

.pl-combobox.pl-multi-select-field {
  min-height: 36px;
  height: auto;

  .pl-select__control {
    height: auto;
    .pl-select__value-container {
      align-content: flex-start;
    }
  }
    .pl-select__multi-value__remove {
      border:1px solid transparent;
     padding-right:2px;
      padding-left:2px;
      //font-size: 19px;
      //width:22px;
      height:23px;
      margin-right:-1px;
      color:$FontColor10;
      margin-top:-1px;
      &:hover, &:active {
        border:1px solid $BorderColor18;
        background:none;
      }
    }

}
