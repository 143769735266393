@import "mat/styles/includes";

.administration-page-container {
  position: relative;
  @include flex-row();

  .navigator-menu {
    background-color: #707070;
    flex-shrink: 0;
    width: 288px;
    overflow-y: scroll;
  }

  .administration-page {
    min-width: 0;
    width: 100%;
    padding: 0 40px 20px 40px;

  }

  .administration-view-grid{
    height: calc(100% - 137px);

    .ag-cell {
      line-height:30px !important;
      padding: 20px;
    }
  }
  .administration-view{
    display: inline-block;
    width: 100%;
    height: 100%;

    .title {
      padding: 17px 0 16px 0;
      border-bottom: 2px solid #eee;
      font-weight: 700;
      font-size: 18px;
      text-transform: uppercase;
    }
    .common-header {
      margin: 20px 0;
    }
  }
}

