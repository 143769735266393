.import-files-common-grid {
  height: 430px;
  margin-top:18px;

  .ag-cell {
    line-height: 30px !important;
    padding: 20px;
  }

  .pl-grid {
    height: 100%;
  }
}
