@import "mat/styles/includes";

.create-kit-form {

  .vertical-input-column {
    vertical-align: top;
    margin: 0;
    width: 50%;
    display: inline-block;

    &:first-child {
      padding-right: 15px;
    }
    &:nth-child(2) {
      padding-left: 15px;
    }
  }

  .materials-section {
    margin-top: 20px;
    overflow-y: auto;
    max-height: 200px;

    .required-materials {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .list-materials-title {
        display: flex;
        width: 100%;

        .column {
          display: inline-block;
          margin-bottom: 4px;
          text-transform: uppercase;
          color: #707070;
          font-size: 12px;
          font-weight: bold;
          &:first-child {
            flex: 0 0 250px;
          }
          &:nth-child(2), &:nth-child(3){
            flex: 0 0 109px;
          }
          &:nth-child(4){
            flex: 0 0 81px;
          }
          &:last-child{
            width: 100%;
          }

          label {
            font-weight: bold;
          }
          .units {
            text-transform: none;
            margin-left: 3px;
          }
        }
      }
      .default-material {
        margin-bottom: 10px;
        .pl-combobox {
          pointer-events: none;

          .pl-select__value-container .pl-select__single-value {
            color:$FontColor2;
          }
        }

        .short-textfield {
          max-width: 153px;
        }
      }
    }

    .new-kit-material-list-item, .new-kittype-material-list-item {
      max-width: 612px;
      span {
        &:first-child {
          flex: 0 0 250px;
        }
        &:nth-of-type(2), &:nth-of-type(3){
          flex: 0 0 108px;
        }
        &:nth-of-type(4){
          flex: 0 0 80px;
        }
      }
    }

    .substitutable-materials {
      margin-top: 30px;
      .substitutable-materials-label {
        @include flex-row();

        .pl-substitute-material-mark:before {
          margin-right: 5px;
        }
        .substitute-material-details {
          margin-left: 5px;
          white-space: nowrap;
        }
      }
    }

    .add-remove-list-container-ext {
      .add-row {
        margin-top: 0;
      }

      .add-remove-list {
        padding-right: 0;
      }
    }
  }

  .pl-select__menu .pl-select__option {
    .mark-as-related-option {
      font-weight: bold;
    }
  }
}
