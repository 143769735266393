@import "infrastructure/styles/theme";

.overlay {
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  z-index: 2;
  background-color: $BGColor34;
}

.loading-overlay {
  font-size: 35px;
}

.text-overlay{
  color: $FontColor17;
}

.error-overlay {
  .error-body {
    text-transform: uppercase;
    color: $FontColor17;
    background: $BGColor35;
    padding: 20px;

    i {
      vertical-align: top;
      margin-right: 15px;
      margin-top: 5px;
      display: inline-block;
      font-size: 25px;
      color: $IconColor3;
    }
    .error-right {
      display: inline-block;
      text-align: center;
      .error-message{
        display: block;
      }
      button {
        color: $FontColor10;
      }
    }
  }
}

.success-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
}

