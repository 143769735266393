@import 'includes';

a{
  &, &:hover, &:active{
    color:$FontColor5;
    cursor:pointer;
  }
}
label, p{margin:0;font-weight:normal;}
hr {
  opacity: 1;    //overwrite the bootstrap 5.3.3 settings
}

@media screen and (max-width: $MobileMaxWidth) {
  body {
    overscroll-behavior: none;
  }

  html, body, body > #main, .providerContainer, .providerContainer>div, .app-container{
    height:100%;
    width:100%;
    min-width:360px;
  }

  .app-container{
    >.app-container-content{
      height:100%;
      //max-width:1300px;
      margin-left:auto;
      margin-right:auto;
      >div{height:100%;}
    }
  }
}
@media screen and (min-width: ($MobileMaxWidth + 1px)) {
  html, body, body > #main, .providerContainer, .providerContainer > div, .app-container {
    height: 100%;
    width: 100%;
    min-width: $G_MinimumAppWidth;
  }

  .app-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    & > header {
      height:64px;
      width: 100%;
    }


    .app-container-main {
      background-color: #ffffff;
      aside {
        z-index: 10;
        width: 64px;
      }

      main {
        width: calc(100% - 64px);
        overflow: auto;
      }

      height: calc(100% - 64px);
      display: flex;
    }

    .app-container-content{
      >div{height:100%;}
      //max-width:1300px;
      //margin-left:auto;
      //margin-right:auto;
      max-width:  none;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

@media (hover:none), (pointer:coarse) {
  html, body, body > #main, .providerContainer, .providerContainer > div, .app-container {
    min-width: 100dvw;
  }
}

body{
  font-family:$FontFamily1;
/*  -webkit-user-select: none;  !* Chrome all / Safari all *!
  -moz-user-select: none;     !* Firefox all *!
  -ms-user-select: none;      !* IE 10+ *!
  user-select: none;   */       /* Likely future */
}
 html {
  height: 100dvh;
}


//Style for browser scroller.
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color:  $BGColor3;
}

::-webkit-scrollbar-track {
  background-color:$BGColor2;
}

::-webkit-scrollbar-thumb {
  width: 5px;
  height: 60px;
  background-color:  $BGColor3;
}


.index-localization-wrapper{
  &.loading{cursor:wait;}
}
