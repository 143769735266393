@import 'mat/styles/includes';

.details-row {
  display: flex;
  align-items: center;
  height: 36px;

  &:not(:first-child) {
    margin-top: 10px;
  }

  span.label,
  .value {
    display: inline-block;
  }

  .value {
    flex-grow: 1;
  }

  .label {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    width: calc(100% - 210px);
    color: $FontColor2;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0px;
    padding-left: 5px;
    vertical-align: middle;
    .pl-info-icon-blue {
      color: #333;
      margin-left: 2px;
    }
    .popover-target {
      vertical-align: middle;
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  // Align editable and non-editable values.
  .value > span,
  .value > a,
  .value > div > span {
    margin-left: 13px;
    line-height: 36px;
  }
}
