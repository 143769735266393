@import 'infrastructure/styles/theme';

.organizations-grid {
  height: calc(100% - 53px);
  margin-top: 18px;
}








