@import "mat/styles/includes";

$widget-size: 4px;
$splitter-color: $BGColor15;

.split-panel {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;

  &.horizontal {
    flex-direction: row;
    .split-pane {
      overflow-x: auto;
      overflow-y: hidden;
      &.top.no-overflow {
        overflow: inherit;
      }
    }
  }

  &.vertical {
    flex-direction: column;
    .split-pane {
      overflow-x: hidden;
      overflow-y: hidden;
      &.top.no-overflow {
        overflow: inherit;
      }
    }
  }

  &.isDragging {
    &.horizontal {
      cursor: ew-resize;
    }
    &.vertical {
      cursor: ns-resize;
    }
    >.splitter {
      background-color: $splitter-color;
    }
  }

  .splitter {
    background-color: #ddd;
    flex-grow: 0;
    flex-shrink: 0;

    &.horizontal {
      height: 100%;
      width: $widget-size;
    }
    &.vertical {
      height: $widget-size;
    }

    &:hover  {
      background-color: $splitter-color;
      &.horizontal {
          cursor: ew-resize;
      }
      &.vertical {
          cursor: ns-resize;
      }
    }
  }

  .split-pane {
    display: flex;
    align-items: stretch;
    &>* {
      width: 100%;
    }
    &.bottom {
      flex:1;
    }
  }

  .dragging-overlay {
    //background-color: #fffbdc;
    //opacity: 0.5;
  }
}
