@import "mat/styles/includes";

.app-container > .app-container-content > .tool-maintenance-dashboard-page {
  height: 100%;
}

.tool-maintenance-dashboard-page {
  position: relative;
  padding: 0 0 0 0;
  overflow: scroll;

  .kpis-container {
    background-color: #EEEEEE;
    .common-kpi {
      margin-right: 20px;
    }
    .common-kpi:first-child {
      border-left: none;
    }
  }

  .charts-container {
    height: 470px;
    background-color: #EEEEEE;
    display: flex;
  }

  .bottom-comment {
    margin: 40px 0 0 20px;
  }
}
