.scheduler-progress-indicator {
  position: relative;

  .indicator-box {
    display: flex;
    justify-content: space-between;
    width: 197px;
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;

    .labels {
      display: flex;
      flex-flow: column;

      span {
        font-weight: bold;
        line-height: 1;
      }

      .title {
        font-size: 18px;
        line-height: 22px;
        text-transform: capitalize;
      }

      .status {
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;

        &.in-progress {
          color: #65a9b9;
        }

        &.completed {
          color: #40ce66;
        }
      }
    }

    .icon {
      display: flex;
      align-items: center;

      .in-progress {
        color: #65a9b9;
        font-size: 36px;
      }

      .completed {
        width: 36px;
        height: 36px;
      }
    }
  }

  .panel-container {
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 3;
  }
}
