.indicator-panel {
  width: 442px;
  border: 0px solid transparent;
  margin: 0;

  .panel-content {
    padding: 20px;

    .indicator-panel-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 22px;

      .indicator-panel-title {
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
      }

      .close-btn {
        all: unset; // remove default html button styles
        cursor: pointer;
        height: 22px;

        svg {
          fill: #65a9b9;
        }
      }
    }

    .indicator-panel-summary {
      font-size: 15px;
      margin-bottom: 27px;

      .summary-row {
        margin-bottom: 5px;
        
        span {
          margin-right: 5px;
          font-weight: bold;
        }

        .success {
          color: #40ce66;
        }

        .failure {
          color: #e03861;
        }
      }
    }

    .indicator-panel-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .view-details-link {
        margin-left: auto;
        color: #4ea5b5;
        text-transform: none;
        font-size: 16px;
        font-weight: 400;
        padding: 0;
      }
    }
  }
}
