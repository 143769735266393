//




.unselectable {
  user-select: none; /* For modern browsers */
  -webkit-user-select: none; /* Safari and Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10+ */
}




////////////////////////////
// Search Filters
//
.search-controls {
  .search-filters {

    // background-color: lightgreen;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: nowrap;
    
    padding-top: 16px;



    .search-filter {
      margin: 0;
      padding: 0;
      
      cursor: pointer;


      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      

      // width: 38px;
      // background-color: yellow;
      
      
      .search-filter-icon {
        border-radius: 8px;
        width: 38px;
        height: 38px;
        // background-color: lightslategray;
        
        svg {
          // display: none;
          width: 38px;
          height: 38px;
        }
        
      }
      
      
      .search-filter-text {
        
        padding-top: 2px;
        
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: center;
        color: #666566;
        
        
        
      }
      
      
      &:hover {
        .search-filter-icon {
          background-color: #F0F0F0;
        }
      }

      &.active {
        .search-filter-icon {
          background-color: #F5F5F5;
        }
      }
      &.active:hover {
        cursor: default;
        .search-filter-icon {
          background-color: #F5F5F5;
        }
      }
      
      
      
    } // End of .search-filter

  }
}

