
.weekly-shifts-view {
  position: relative;
  display: flex !important;
  flex-flow: column;

  .shift-scheduler-wrapper {
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    margin: 20px 0;

    .header-right > .btn {
      padding-left: 13px;
      margin-left: 8px;
    }

    .flex-grow-2 {
      flex:auto;
      flex-grow: 2;
    }
  }

  .day-header-title {
    display: flex;
    flex-flow: column;

    span {
      text-align: center;
    }
  }
}
