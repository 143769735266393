@import "../../../../../../../../styles/includes";

.create-mqtt-sensor-dialog {
  .modal-dialog {
    width: $dialogBig1ColumnWidth;

    .message-title {
      border-bottom: solid 2px $BGColor29;
      margin: 10px 0;
      font-size: 15px;
    }

    .topic-section {
      @include flex-row();
      justify-content: space-between ;

      .input-section.left-side {
          flex: 1;
        }
      .input-section.right-side {
        width: 100px;
      }
    }

    .PL-textAreaField {
      width: 100%;
      height: 36px;
    }
  }
}


