@import "mat/styles/includes";
.workorder-page {
  height:100%;
  font-size: 14px;

  .common-header {
    float: right;
    margin: 11px 35px 0 0;
  }
  .pl-topbar-tabs {
  }
  .tab-content {
    height: calc(100% - 59px);
    margin-left: 35px;
    margin-right: 35px;
    > div {
      height: 100%;
    }
  }
}
