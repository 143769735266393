@import "mat/styles/includes";

.nature-list-item {
  @include flex-row();

}






