@import "mat/styles/includes";


.create-sub-rolls-dialog {
  .modal-dialog {
    width: $dialog2ColumnsWidth;
  }
  .modal-body {
    max-height: 580px;
    .sub-rolls-list-container {
      position: relative;
      .sub-rolls-list-title {
        margin-top: 40px;
        .column {
          display: inline-block;
          width: 150px;
        }
        .column:first-child {
          width: 450px;
        }
      }
      .label-with-units-container {
        position: absolute;
        display: inline-block;
        left: 450px;
        bottom: -5px;
      }
      .label-with-units-container, .column {
        label {
          display: inline-block;
        }
        .units {
          margin-left: 3px;
          text-transform: none;
        }
      }
    }
  }

  //to limit the input expanding when typing
  .pl-combobox .pl-select__control .pl-select__input > input {
    max-width: $comboboxInputWidth4;
  }

  .pl-combobox.entity-value-select {
    .pl-select__option .select-option-row > :not(:first-child) {
      width: calc(100% - 20px - 15px);
    }
    .select-row.select-value-row, .select-row.select-option-row  {
      .column {
        height: 20px;
        line-height: 20px;
      }
    }
  }

  .title > div {
    max-width: 305px;
    &:first-child {
      margin-right: 15px;
    }
  }

  .error {
    color: $IconColor3;
  }

  .error-message {
    color: #333;
    font-size: 16px;
    vertical-align: top;
    line-height: 17px;

    .error-icon {
      margin-right: 10px;
    }
  }

}


.sub-rolls-status {
  margin-left: 20px;
}
.sub-rolls-status-title {
  display: block;
  margin-left: -6px;
  padding-left: 6px;
  background-color: white;
}

















