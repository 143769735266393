@import 'infrastructure/styles/theme';
.week-day-and-time{

  min-width: 318px;
  display: inline-block;
  .day-of-week {
    max-width: 208px;
    display: inline-block;
  }
  .hour-picker{
    max-width: 90px;
    //put the invalid icon on the datepicker's background image
    .invalid.pl-datepicker {
      .invalid-icon {
        right: 8px;
      }
    }
  }
  .pl-datepicker{
    margin-top: 18px;
    display: inline-block;
    .react-datepicker__time-box {
      width: 80px;
    }
    .react-datepicker__time-list{
      padding: 0;
      font-size: 12px;
    }
    .react-datepicker-time__header{
      font-size: 12px;
    }
    .react-datepicker__input-container input{
      background: $IconColor1;
      padding: 0px 10px 0px 11px;
    }
  }
}

