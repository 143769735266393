@import "infrastructure/styles/theme.scss";

.wizard-dialog {

  >.modal-dialog {
    width: 800px;
    height: 600px;

    > .modal-content {
      height: 100%;
      display: flex;
      align-items: stretch;
      flex-flow: column;

      > .modal-body {
        overflow: hidden;
        flex-grow: 1;
        padding: 0;
      }

      .validation-area {
        visibility: hidden;
      }

      .footer-validation-checkbox {
        visibility: hidden;
        margin-right: 20px;
      }
    }
  }
    &.wizard-dialog.wizard-last-step{
    .validation-area {
      visibility: visible;
    }
    .footer-validation-checkbox {
      visibility: visible;
    }
  }
}

.close-wizard-confirmation-dialog {

  .modal-content {
    .modal-body {
      border: none;
      padding: 20px 20px 0 20px;
      .line {
        margin: 0;
        .label {
          width: unset;
        }
      }
    }

    .modal-header {
      min-height: 66px;
      height: auto;
      border-bottom-width: 0;

      label.title {
        vertical-align: middle;
        line-height: 30px;
        max-width: calc(100% - 66px);
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .modal-footer {
      border: none;
      background-color: #F7F7F7;
    }
  }
}
