@import "mat/styles/includes";

.create-part-type-dialog {

  .modal-dialog {width: $dialog2ColumnsWidth;}

  .modal-body {
    .input-section:first-child {
      margin-top: 0;
    }
    .PL-textField > input {
      padding-right: 30px;
    }
  }
}
