.options-list {
  width: 100%;
  border: 1px solid #d1d1d1;
  position: relative;
  z-index: 9;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  .options-item {
    min-height: 30px;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: #4da5b5;
    }
  }

  .options-list-search {
    padding: 5px;
    .options-list-input {
      width: 100%;
      height: 30px;
      box-sizing: border-box;
    }
  }

  .options-list-message {
    padding: 0 5px;
    color: #707070;
  }
}
