@import "mat/styles/includes";

.scanned-assets-list-page {

  .scanned-assets-list {
    height: calc( 100% - 120px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .list-item {
    position: relative;

    span.remove-btn {
      display: inline-block;
      position: absolute;
      top: 18px;
      left: 20px;
      color: #cccccc;
      border: 2px solid #cccccc;
      padding: 5px;
      border-radius: 15px;
      font-size: 10px;
      font-weight: bold;
    }
  }
}





