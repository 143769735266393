@import "infrastructure/styles/generic";
@import "mat/styles/includes";

.afp-creel {
  .nav.nav-tabs {
    .active {
      a {
        background-color: #ccc;
      }
    }
  }
  .afp-creel {
    display: flex;
    flex-wrap: wrap;
    background-color: #ccc;
    padding: 5px 0 0 5px;
  }

}
