@import 'infrastructure/styles/theme';

.pl-grid {
  .search-filter {

    .text-field {
      width: 310px;
    }

    .filter-footer {
      margin-top: 20px;
    }
  }
}
