@import "mat/styles/includes";
.weight-wizard {

  .modal-dialog {width: $dialog2ColumnsWidth;}

  .modal-body {

    .body-title {
      display: block;;
      font-size: 18px;
    }

    .body-comment {
      margin-top: 10px;
      font-size: 14px;
    }
    .weight{
      display: block;
      font-size: 25px;
      text-align: center;
      margin-top: 50px;

      .label{
        background-color: $BGColor5;
      }
    }
  }
}
