@import "mat/styles/includes";

.add-replace-spools-dialog {

  .modal-dialog {
    width: $dialogWidth4;
    margin: 0;
  }
}

