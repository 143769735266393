@import "mat/styles/includes";

.device-purpose-cell {
  .app-monitor {
    span {
      display: block;
      line-height: 30px;
    }
    label {
      padding-left: 10px;
      margin-bottom: 5px;
      display: block;
      line-height: 10px;
      height: 10px;
      font-size: 11px;
      color: $FontColor16;
    }
  }

}
