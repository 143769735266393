@import "infrastructure/styles/generic";
@import "infrastructure/styles/theme";

.btn {
  transition:background 0.3s, border-color 0.3s, color 0.3s;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  height:17px;
  line-height:17px;
  padding-top:0;
  padding-bottom:0;
  background-image:none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  text-shadow: none;
  letter-spacing:0.8px;
  text-transform: uppercase;
  &.btn-default, &.btn-secondary {
    color:$FontColor5;
    padding: 0;
    background: none ;
    &:hover, &:active, &:focus{
      background: none ;
      box-shadow: none ;
      outline:none;
    }

    &:hover{
      color: $FontColor6;
      border-bottom:1px solid $BorderColor9;
    }
    &:focus, &:active{outline: none !important;}
    &:active{
      color: $FontColor7;
      border-bottom:1px solid $BorderColor10;
    }
    &:disabled{
      color:$FontColor14;
      border-bottom:0;
      &:hover, &:active{color:$FontColor14 !important;}
    }
  }

  span.pl {
    display: inline-block;

  }

  &.btn-primary {
    height:35px;
    line-height:35px;
    background-color: $BGColor5;
    color: $FontColor3;

    &:hover, &:focus{background-color: $BGColor6;}
    &:focus, &:active{outline: none !important;}
    &:active{
      background-color: $BGColor7 ;
    }
    &:disabled{
      background:$BGColor15;
      &:hover, &:active{background:$BGColor15 !important;}
    }
    &.with-icon {
      .pre-button {
        padding: 0 10px 0 0;
      }
    }
    &.label-only {
      .pre-button {
        margin-right: 8px;
      }
    }

    .pre-button {
      display: inline-block;
      min-width: 0;
    }
  }

  &.small-btn{
    line-height:27px;
    height: 27px;
    text-align: left;
    padding: 0 10px ;
    font-size: 12px;
    border: 1px solid $BorderColor6;

    &:hover {
      background: $BGColor11 !important;
      border-color: $BorderColor9;
    }
    &:focus, &:active {
      outline: none !important;
    }
    &:active {
      background-color: $BGColor12 !important;
    }

    .pre-button{
      font-size: 12px;
      padding: 0 5px ;
    }
    &:disabled,  &.disabled{
      border: 1px solid $BorderColor17;
    }
  }

  &.with-icon {
    .pre-button {
      display: inline-block;
      padding: 0 5px 0 0;

      vertical-align: top;
      margin-top: -1px;
    }
  }

  &.wide-icon {
    .pre-button {
      margin-right: 5px;
    }
  }

  &.btn-default.border-btn {
    height: 35px;
    line-height: 33px;
    padding-right: 13px;
    background-color: $BGColor2;
    color: $FontColor10;
    border: 1px solid $BorderColor17;


    &:hover {
      background: $BGColor11 !important;
      border-color: $BorderColor15;
    }
    &:focus, &:active {
      outline: none !important;
    }
    &:active {
      background-color: $BGColor12 !important;
    }


    $preButtonWidth :35px;
    $preButtonMargin : 10px;

    span.counter {
      background: $BGColor13;
      display: inline-block;
      text-align: center;
      min-width: $preButtonWidth;
      margin-right: $preButtonMargin;
      color: $FontColor3;
      font-size: $FontSize1;
    }

    .pre-button{
      display: inline-block;
      text-align: center;
      min-width: calc(#{$preButtonWidth} + #{$preButtonMargin});
      line-height:33px;

      &.svg-icon {
        line-height: unset;
      }
    }

    &.only-icon{
      padding-left:8px;
      padding-right:8px;
      min-width:33px;
      .pre-button{
        min-width: 0;
      }

      img {

      }
    }


    div.pre-button {
      span {
        margin-right: 0;
        font-size:23px;
        vertical-align:middle;
        line-height:33px;
      }
    }

    &.no-icon{
      padding-left:13px;
    }
  }

  &.btn-default.border-btn,  &.small-btn {
    &:disabled,  &.disabled{
      color:$FontColor12;
      border-color:$BorderColor17;
      span.counter{background:$BGColor14;  }
      &:hover, &:active{
        background:$BGColor2 !important;
        color:$FontColor12 !important;
      }
    }
  }

}








