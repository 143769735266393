@import "mat/styles/includes";


.print-pdf-labels-dialog{
  .modal-dialog {width: $dialogBig1ColumnWidth;}

  .modal-body{
    label {
      text-transform: uppercase;
    }
  }

  //to limit the input expanding when typing
  .select-destination .pl-combobox .pl-select__control .pl-select__input > input {
    max-width: $comboboxInputWidth8;
  }


}
