@import 'mat/styles/includes';

.lot-overview-tab {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background-color: $BGColor11;

  .column {
    width: 33%;
    height: 100%;
    vertical-align: top;
    display: table-cell;
    padding: 0px 17px;

    &:not(:last-child) {
      border-right: solid 1px $BGColor29;
    }
  }

  .lot-details {
    padding: 20px 30px;

    .lot-details-section {
      background-color: $BGColor2 !important ;
      display: flex;
      width: 100%;
      padding: 20px 0px;

      .batch-number-row {
        height: auto;
        min-height: 36px;

        .label {
          align-self: stretch;
        }

        .value {
          max-height: 200px;
          overflow-y: scroll;
        }

        .value > div {
          margin-left: 13px;
          height: 36px;
        }
      }
    }

    hr {
      margin-top: 20px;
      border-top: 1px dashed $BGColor29;
    }

    .value {
      > span:not(:last-child) {
        margin-right: 15px;
      }
    }
    .popover-target {
      display: inline-block;
    }
  }
}
