.navigator-menu {
  color: #fff;

  .navigator-menu-top-bar {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 10px;

    .navigator-menu-search {
      display: flex;
      flex: 1 0 auto;
      align-items: center;
      height: 36px;
      padding: 8px 7px 6px 12px;
      border-radius: 8px;
      background-color: #fff;

      input {
        flex: 1 0 auto;
        color: #403f40;
        font-size: 12px;
        font-weight: bold;
        outline: none;
        border: none;
      }

      .clear-icon {
        cursor: pointer;
      }
    }

    .close-menu-button {
      cursor: pointer;
      margin-right: 8px;
    }
  }
}
