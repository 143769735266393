@import "mat/styles/includes";

.cut-kit-station{
  &, >div {
  }
  .right-top-charts-main-container{
    float: right;
    height:55px;

    line-height: 55px;
    .right-top-component-container {
      margin: 0 20px;
    }
  }

  ul.nav-tabs {
    li[role=presentation] {
      min-width: 110px;
    }
  }

  .smart-selection-page {
    padding-top:23px;
    height:100%;
  }
  .pick-list-page {
    height:calc(100% - 23px);
    padding: 23px 0 0 0;
  }
}
