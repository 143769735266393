@import '../../../../../../styles/includes';

.scheduling-preferences-view {
  height: 100%;
  display: flex;
  flex-flow: column;

  .common-header {
    margin: 15px 0;
  }

  .scheduling-preferences-container {
    overflow-y: scroll;
  }

  .preferences-section,
  .goals-section {
    padding: 20px 0 20px 0;
    border-bottom: 2px solid #eee;

    .preferences-header {
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      padding: 17px 0 17px 0;

      .preferences-title {
        font-size: 18px;
        font-weight: 700;
      }
    }

    .preferences-input {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .PL-textField {
        width: $comboboxInputWidth1;
      }

      label {
        margin-right: 20px;
      }

      .additional-info {
        display: inline-block;
        color: #707070;
        font-size: 14px;
        line-height: 35px;
        font-weight: bold;
        margin-left: 6px;
        margin-right: 15px;
      }
    }
  }

  .pl-switch {
    display: flex;
    align-items: center;
  }

  .order-configuration-table {
    height: calc(100% - 55px);

    .header {
      @include flex-row();
      align-items: center;
      height: 35px;
      background: #ddd;
      font-size: 15px;

      .header-column {
        width: 200px;
        text-align: center;
      }
    }

    .body {
      @include flex-row();
      max-height: calc(100% - 35px);
      overflow: auto;
      .order-column {
        width: 200px;
        .order-cell {
          padding: 15px 0;
          text-align: center;
          border-bottom: solid 1px transparent;
        }
      }
    }
  }

  .hour-picker {
    width: 100px;
  }
}

.firstDayOfWeek-combobox,
.weekHorizonStart-combobox {
  width: 132px;
}

.tile-name-combobox {
  width: 195px;
}

.goals-item-cell {
  position: relative;
  padding: 15px 40px;
  background-color: #ffffff;
  border-bottom: solid 1px #e9e8e8;
  &:hover {
    background-color: #f9f9f9;
    cursor: pointer;
  }
  &.inactive {
    color: $FontColor12;
  }
  .popover-target {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
