@import "mat/styles/includes";


.report-operation-item {

  //.pl-combobox.pl-multi-select-field.invalid {
  //    height: auto;
  //}

    hr {
      border-color: $BGColor29;
    }
    label {
      text-transform: uppercase;
      color: $FontColor4;
      font-size: 12px;
      margin: 0 0 4px 0px;
      font-weight: bold;
    }

    .datepicker-section{
      margin-top: 20px;
      height: 60px;
    }
    .pl-datepicker{
      max-width: 245px;
      .react-datepicker-wrapper {
        .react-datepicker__input-container {
          .react-datepicker__close-icon {
            margin-right: 80px;
          }
        }
      }
    }

    .left .entities-multi-select {
        display: block;
      .select-row.select-value-row .column {
        //width: calc(100% - 30px);
        width: 100%;
      }
      .pl-select__option .select-option-row > :first-child {
        width: 0;
      }
      .pl-select__option .select-option-row > :not(:first-child) {
        width: calc(100% - 15px);
      }
      .select-row.select-value-row, .select-row.select-option-row  {
        .column {
          height: 20px;
          line-height: 20px;
        }
      }
    }

    .right .pl-checkbox {
      label.checkbox-label {
        margin-left: 5px;
      }
    }
    .instructions-title{
      display: inline-block;
      font-weight: bold;
      color: $FontColor4;
    }
    .instructions-text{
      display: inline-block;
      margin-left: 10px;
      color: $FontColor4;
    }
    .left, .middle, .right{
      display: inline-block;
      width:calc(33% - 10px);
      margin-right: 10px;

      .pl-combobox {
        //to limit the input expanding when typing
        .pl-select__control .pl-select__input > input {
          max-width: $comboboxInputWidth1;
        }
      }
      .pl-checkbox {
        display: inline;
        top: 9px;
        position: relative;
      }
    }
    .attach-assets-from-wo{
      display: inline-block;
      margin-top: 5px;
      margin-left: 3px !important;
    }


    .assets-section {
      margin-top: 20px;

      &.demolding {
        .select-row.select-value-row {
          .extra-data {
            width: 130px;
          }
          .custom-data {
            width: 60px;
          }
        }
      }

      .add-to-asset-container {
        @include flex-row();
        align-items: flex-start;

        .add-to-asset-section {
          width: 45%;
          display: inline-block;
          position: relative;

          .add-to-asset-label {
            display: inline-block !important;
            position: absolute;
            margin-right: 10px;
            top: 10px;
            left: 0;
          }

          .entities-multi-select {
            @include flex-column();

            .entity-type-select {
              margin: 0 0 2px 35px;
            }
            &.using-asset-selection {
              flex-direction: row;
            }

            .add-to-asset-selection {
              width: 100%;

              .pl-select__value-container {
                min-height: 90px;
                //justify-content: flex-start;
                align-items: flex-start;

                .select-value-row .extra-data{
                  width: 130px;
                }
                //to limit the input expanding when typing
                .pl-select__input > input {
                  max-width: $comboboxInputWidth5;
                }
              }
            }
          }
        }
        .add-to-asset-arrow {
          display: inline-block;
          //position: relative;
          width: 10%;
          //height: 100%;
          padding-left: 25px;
          font-size: 15px;
          margin-top: 50px;
        }
      }

    }

    .comments-section {
      margin-top: 28px;
      label {
        font-weight: bold;
      }

      .PL-textAreaField {
        width: 100%;
        height: 54px;
      }
    }

}

