@import "mat/styles/includes";

.import-files-dialog {
  .modal-dialog {
    width: 900px;
  }
}

.material-title{
  padding-bottom: 5px;
  display: block;
}




