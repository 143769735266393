.validation-messages-box {
  width: 100%;
  padding: 16px 35px;
  margin: 20px 0;

  .validation-messages_title {
    font-size: 19px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .validation-message_row {
    margin-bottom: 10px;
  }

  .icon {
    margin-right: 5px;
  }

  &.error-border {
    border: 1px solid red;
  }

  &.warning-border {
    border: 1px solid #ffc60a;
  }
}
