@import 'mat/styles/includes';

.common-pdf-label-settings-view {
  display: inline-block;
  width: 100%;
  height: 100%;
  //height:calc(100% - 65px);
  position: relative;

  .common-header {
    margin: 15px 0;
  }

}
