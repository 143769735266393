@import "mat/styles/includes";

.common-kpi {
  display: inline-block;
  margin: 10px 20px 10px 0;
  padding-left: 20px;
  border-left: solid 1px #ccc;
  min-width: 300px;

  &.cupsole{
    height: 60px;
    border-radius: 5px;
    min-width: 250px;
    &:first-child {
    margin-left: 21px;
    }
    &.blue{
      background-color: #70C6F7;
    }
    &.red{
      background-color: #C587F1;
    }
    &.green{
      background-color: #F7C070;
    }
  }

  .title {
    font-size: 15px;
    text-transform: capitalize;
  }

  .content {

    .item {
      display: inline-block;
      vertical-align: top;
    }
    .left-side {
      font-size: 28px;
      margin-right: 20px;
      .fa {
        margin-left: 8px;
      }
      .red {
        color: $FontColor20;
      }
      .green {
        color: #64AE52;
      }
      .orange {
        color: #F7C070;
      }
    }

    .right-side {
      font-size: 18px;
      .time-frame {
        font-size: 16px;
        margin-top: -5px;
        color: $FontColor16;
      }
    }
  }
}
