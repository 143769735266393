@import "infrastructure/styles/theme";

@keyframes waiting-pin {
  0% {
    border-color: white;
  }
  100% {
    border-color: $Color2;
  }
}
.afp-pin {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  background-color: white;
  width: calc(25% - 5px);
  margin: 0 5px 5px 0;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #cccccc;
  cursor: pointer;

  &.selected {
    border: 2px solid $Color2;
  }

  &:hover {
    .pl.has-asset {
      visibility: visible;
    }
  }
  &.waiting:hover {
    .pl.has-asset {
      visibility: hidden;
    }
  }

  &.waiting {
    animation: 350ms infinite alternate waiting-pin;
    .afp-pin-asset {
      color: #cccccc;
    }
  }

  .afp-pin-number {
    font-weight: bold;
    width: 25px;
    margin-right: 5px;
  }
  .afp-pin-asset {
    color: $Color2;
    flex: 1 1 0;
  }
  .pl {
    visibility: hidden;
    color: $IconColor2;
    font-size: 10px;
  }


}
