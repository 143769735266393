@import "mat/styles/includes";

.reported-cuts-custom-overlay {
  width: 450px;
  max-width: none;
  max-height: 590px;
  font-family: $FontFamily1;

  .popover-content {
    max-height: none;
    height: 100%;
    padding: 30px 20px;

    .popover-title {
      display: none;
    }
    .popoverNoButtons {
      margin-top: 0;
    }
    hr.hr-main {
      border: 1px solid $BorderColor6;
      margin: 5px 0 0 0;
    }
    hr.hr-secondary {
      border-color: $BorderColor24;
      margin: 10px 0 5px;
    }
  }


  .unkitted-cuts {
    padding-left: 10px;

    &.category {
      font-size: 16px;
    }

    .unkitted-cuts-list {
      max-height: 480px;
      overflow-y: auto;
      overflow-x: hidden;
      padding:  10px 10px 0 0;

      .unkitted-cut {
        vertical-align: top;
        .unkitted-cut-time {
          display: inline-block;
          width: 125px;
          vertical-align: top;
          line-height: 24px !important;
          font-size: 14px;
        }
        .material-businessid-name {
          display: inline-block;
          font-size: 14px;
          width: 200px;
          padding-right: 10px;
          .material-name-length {
            line-height: 24px !important;
            word-wrap: break-word;
          }
          .material-businessid {
            color: $FontColor14;
            font-size: 12px;
            line-height: 11px;
            word-wrap: break-word;
          }
        }
        .cancel-button {
          line-height: 44px;
          float: right;
          height: 30px;
          font-size: 11.5px;
          vertical-align: 5px;
        }
      }
    }
  }
}
