
.orgs-dashboard-header {
  .inline-section {

    display: inline-flex;
    align-items: center;
    label {
      margin-right: 20px;
    }
    .date-range-popover {
      width: 200px;
    }
  }
  button {
    height: 36px;
  }

  > :not(:first-child) {
    margin-left: 20px;
  }
}
