@import "mat/styles/includes";

.new-kit-material-list-item {
  @include flex-row();
  border: 1px solid #dddddd;

  margin-bottom: 10px;

  div.big-input-separator {
    border-right: 1px solid $BorderColor8;
    display: inline;
    font-size: 25px;
    line-height: 36px;
    vertical-align: middle;
  }
  .short-textfield {
    max-width: 152px;
  }

  .material-value {
    max-width: 275px;
  }

  .pl-substitute-material-mark:before {
    line-height: 19px;
    margin-right: 5px;
  }

  .pl-combobox {
    //to limit the input expanding when typing
    .pl-select__control .pl-select__input > input {
      max-width: $comboboxInputWidth5;
    }
  }
  input {
    border: 0px solid transparent;
    display: inline;
  }
}





