@import "mat/styles/includes";

.scan-header {
    background-color: $BGColor6;
    height: 60px;

  .scan-header-item {
      height: 60px;
      color: white !important;
      font-size: 23px;
      &:hover, &:active {
        border-bottom: none;
      }
    }

  .scan-header-item.btn-left {
      width: 60px;
      border-right: 2px solid #3B8492;
    }

  .scan-header-item.btn-middle {
      text-transform: none;
      width: calc(100% - 120px);
      opacity: 1;
      text-align: left;
      padding-left: 20px;
      font-weight: normal;
    }

  .scan-header-item.btn-right {
      width: 40px;
      height: 40px;
      font-size: 16px;
      background-color: #55C9DB;
      border-radius: 50%;
      margin: 10px;
    }
}





