@import 'infrastructure/styles/theme';

.side-nav-accordion-summary {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  user-select: none;
  cursor: pointer;
  background-color: #5d5d5d;

  &:hover {
    background-color: #888;
  }

  &.active, &.selected {
    background-color: $BGColor5;
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .accordion-summary-icon {
    height: 22px;
    width: 22px;
    margin-right: 8px;
  }

  .pl-arrow-down {
    margin-left: auto;
    font-size: 8px;
  }

  .pl-arrow-right {
    margin-left: auto;
    font-size: 14px;
  }

  a {
    display: flex;
    align-items: center;
    height: 100%;
    flex: 1 0 auto;
  }

  a,
  a:hover {
    text-decoration: none;
    color: #fff;
  }
}
