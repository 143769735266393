@import "mat/styles/includes";


.rfid-settings-page {

  .administration-view .administration-view-grid {
    height: calc(100% - 158px);
  }

  .short-textfield {
    width: 200px;
  }

  .input-section {
    &.inline {
      width: 50%;
      display: inline-block;

      &.right-side, &.left-side {
        width: calc(50% - 15px);
      }
      &.right-side {
        margin-left: 30px;
        float: right;
      }
    }
  }

  .rifd-view-loading-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: $BGColor34;
    z-index: 999999;

    i {
      color: $FontColor17;
      width: 100%;
      margin: 0 auto;
      margin-top: 200px;
      font-size: 35px;
    }
  }

}





