@import "mat/styles/includes";

.scan-asset-page {
  background-color: $BGColor2;
  position: relative;

  .content {
    font-size: 20px;
    min-height: calc( 100% - 120px);
    height: calc( 100% - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    .section {
      //margin-bottom: 30px;
    }
    //.barcode-input-section {
    //  margin-bottom: 10px;
    //  text-align: center;
    //  .barcode-input {
    //    margin: 30px 20px 0;
    //    border: 1px solid $BorderColor6;
    //    //border: 1px solid #e89430;
    //
    //    .PL-textField {
    //      width: calc(100% - 45px);
    //      height: 100%;
    //    }
    //    input {
    //      height: 45px;
    //      width: 100%;
    //      padding-left: 10px;
    //      font-size: 20px;
    //      border: none;
    //    }
    //    button {
    //      height: 45px;
    //      width: 45px;
    //      font-size: 18px;
    //      border: none;
    //
    //      color: $FontColor10;
    //      &:disabled {
    //        color: $BorderColor22;
    //      }
    //      .pre-button {
    //        border-left: 1px solid $BorderColor22;
    //      }
    //    }
    //  }
    //
    //  .barcode-input-error {
    //    color: $FontColor20;
    //    margin: 0 auto;
    //  }
    //}

    .scan-input-section {
      margin: 30px 20px 0;
    }

    .prompt-text {
      margin: 0 60px 30px;
      text-align: center;
    }

    .barcode-image {
      @media screen and (max-height: 550px) {
        display: none;
      }
      text-align: center;
      img {
        //width: 100%;
        height: 290px;
      }
    }
  }

  .scan-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

}





