@import "mat/styles/includes";

.create-external-notification-dialog {

  .modal-dialog {width: $dialog2ColumnsWidth;}

  .modal-body {
    .trigger-section {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      label {
        margin-right: 20px;
      }
    }

    .tab-pane {
      padding: 15px 0;
      .add-row {
        margin-top: 10px;
      }
    }

    .add-remove-list-container-ext {
      &.list-ext-overflow {
        max-height: 349px;
      }
      padding-right: 0;
      .add-remove-list {
        padding: 0 15px 5px 0;
        .list-item .item-btn.remove-btn {
          padding: 5px;
          width: 26px;
          height: 26px;
        }
      }
    }

    .item-index {
      flex: 0 0 25px;
      margin-right: 15px;
      text-align: center;
      font-weight: bold;
      color: $Color2;
    }
  }
}
