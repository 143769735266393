@import "mat/styles/includes";

.page-not-found-page {
  @include flex-row();
  justify-content: center;
  align-items: center;
  height: 100%;
  .page-not-found-content {
    @include flex-column();
    align-items: center;
    > div {
      margin-bottom: 30px;
    }

    .message-section span{
      display: block;
      text-align: center;
      font-size: 24px;
      color: $FontColor4;

      &.first-line {
        font-size: 34px;
      }
    }
  }
}
