@import 'infrastructure/styles/theme';

.manager-view-tree {
  .tree-item {
    &.leaf {
      cursor: inherit;
      opacity: 0.8;
      &:hover {
        background-color: transparent;
      }
    }
    &.branch {
      font-weight: bold;
    }

    .leaf-icon {
      margin-right: 6px;
      width: 20px;
      color: #4da5b5;
      font-size: 20px;
    }

    .branch-icon {
      margin-right: 4px;
      width: 20px;
      vertical-align: 0;

      &.pl-arrow-down {
        font-size: 8px;
      }
    }

    .pl:before {
      line-height: 34px;
    }
  }
}


