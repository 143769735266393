.pl-detail-grid {
  background-color: #f2f2f2;
  padding: 20px;

  .ag-body-viewport.ag-layout-normal {
    overflow-y: hidden;
  }

  .ag-header-cell {
    .ag-icon.ag-icon-menu {
      &:before {
        display: none;
      }
    }
  }
}
