@import "mat/styles/includes";

.location-value-cell {
  display: flex;
  align-items: center;
}












