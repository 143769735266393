@import "mat/styles/includes";

.search-container {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 10px;

  .search-input {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    height: 36px;
    padding: 8px 7px 6px 12px;
    border-radius: 8px;
    background-color: #fff;

    input {
      flex: 1 0 auto;
      color: #403f40;
      font-size: 12px;
      font-weight: bold;
      outline: none;
      border: none;
    }
    input::placeholder {
      font-weight: bold;
      opacity: 0.5;
      color: $FontColor11;
    }

    .clear-icon {
      cursor: pointer;
    }
  }
}
