@import 'mat/styles/includes';

.scheduler-operations-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 10px;

  .header-left-content {
    display: flex;

    .scheduler-progress-indicator {
      margin-left: 20px;
    }
  }

  .pl-completed {
    color: #4ea5b5;
  }

  .tasks-counters {
    display: flex;
    align-items: center;

    .counter {
      display: flex;
      align-items: center;
      margin-left: 15px;

      .pl-error-icon {
        display: inline-block;
        color: $FontColor20;
      }

      .value {
        display: inline-block;
        padding-left: 5px;
      }
    }
  }
}
