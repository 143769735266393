@import "mat/styles/includes";

// Explicit dialog width (for wide screens).
@media (min-width: 768px) {

  .report-kits-dialog .modal-dialog {
    width: 830px;
  }

  .report-kits-dialog .modal-footer {
    .floating-center-text {
      margin-left: 20px;
    }
  }

  // Specific dialog sizing. This dialog is not scrollable on this level.
  .report-kits-dialog .modal-dialog .modal-content .modal-body {
    padding: 23px 0px 0px 21px;

    max-height: 460px;
    overflow: hidden;

  }

}


.report-kits-dialog {

  $contentWidth: 780px;

  $headingTextColor:      $FontColor18;
  $headingUnderlineColor: $BorderColor6;

  $textColor:             $FontColor17;
  $lightTextColor:        $FontColor16;

  $itemSeparatorColor:    $BorderColor20;

  $fillIconBorderColor:   $FontColor17;



  /********************************/
  // Columns for heading and content items.

  .item-column {
    // background-color: lightGreen;

    width: 30px;
    display: inline-block;
    float: left;
    // border-left: 2px solid black;

    &.column-1 { width: 107px;  text-align: center; }
    &.column-2 { width: 264px;  text-align: left;     margin-left: 9px; }
    &.column-3 { width: 100px;  text-align: center; }
    &.column-4 { width: 90px;   text-align: center;   margin-left: 15px; }
    &.column-5 { width: 90px;  text-align: center; }
    &.column-6 { width: 90px;   text-align: center; }

    label {
      font-weight: bold;
    }
    .units {
      text-transform: none;
      margin-left: 3px;
    }

  }

  .item-column.substitute-material-section {
    margin-left: 116px;
    margin-top: 15px;
    span.pl {
      margin-right: 10px;
    }
  }
  .item-column.operation-validation-section {
    width: calc(100% - 85px);
    margin-top: 15px;
    margin-left: 45px;
    margin-right: 45px;

    .icon {
      display: inline-block;
      margin-right: 10px;
      width: 15px;
      font-size: 16px;

      &.pl-warning-icon {
        color: $IconColor4;
      }
    }
  }


  /********************************/
  // Static content header.
  .content-header {
    // background-color: aqua;
    height: 28px;
    border-bottom: 3px solid $headingUnderlineColor;
    margin-top: 25px;
    margin-right: 25px;
    margin-bottom: 6px;
    text-transform: uppercase;
    font-weight:bold;
    font-size: 12px;
    color: $headingTextColor;
    vertical-align: middle;
    line-height:28px;

  }


  /********************************/
  // Dialog Scrollable contents.
  .content-items-wrapper {
    // background-color: yellow;
    width: 100%;

    max-height: 430px;
    min-height: 200px;

    overflow-y: auto;
    overflow-x: hidden;

  }

  /********************************/
  // Dialog Items
  .dialog-item {
    // background-color: yellow;
    width: $contentWidth;
    margin-top: 12px;
    padding-bottom: 15px;
    border-bottom: 1px solid $itemSeparatorColor;

    &:last-child {
      border-bottom: none;
    }


    .PL-textField.plies-kitted {
      width: 64px;
      margin: 0 auto;

      input {
        width: 100%;
        text-align: center;
      }
    }

    .info-line {
      line-height: 25px;
      .info-label {font-size: 16px; color: $lightTextColor; margin-right: 4px;}
      .info-data {font-size: 16px; color: $textColor; }
    }

    .piles-needed-text {
      border: none;
      cursor: default;
      text-align: center;
    }



    /**********************/
    // Fill Icon.

    .fill-icon-text {
      margin-top: 2px;
      font-size: 16px;
    }

    .fill-icon-image {
      margin-top: 2px;
    }
  }
}









