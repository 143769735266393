@import 'infrastructure/styles/theme';

.tree-item {
  $item-border-width: 1px;

  display: flex;
  line-height: 34px;
  cursor: pointer;
  user-select: none;
  border: $item-border-width solid transparent;

  &:hover {
    background-color: #EEEEEE;
    .tree-item__actions {
      visibility: visible;
    }
  }
  &.selected {
    background-color: #B9D9DFFF;
    font-weight: bold;
  }
  &.dragged {
    opacity: 0.3;
  }
  &.droppable-before {
    border-top: $item-border-width solid $FontColor6;
  }
  &.droppable-after {
    border-bottom: $item-border-width solid $FontColor6;
  }
  &.droppable-inside {
    border: $item-border-width solid $FontColor6;
  }

  .tree-item__label {
    width: 100px;
    flex: 1;
  }
  .tree-item__actions {
    visibility: hidden;
    margin-left: auto;
  }
}
