@import 'infrastructure/styles/theme';

.side-nav-accordion-detail {
  .accordion-detail-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 34px;
    padding: 0 10px 0 15px;
    user-select: none;
    cursor: pointer;

    a {
      height: 100%;
      overflow: hidden;
      flex: 1
    }

    a,
    a::after,
    a:active {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #fff;
    }

    .accordion-detail-item-icon {
      width: 22px;
      height: 22px;
      margin-right: 8px;
      flex-shrink: 0;
      color: #4da5b5;

      path {
        fill: #4da5b5;
      }
    }

    .accordion-detail-label {
      font-size: 16px;
    }

    .accordion-detail-item-actions {
      display: flex;
      align-items: center;

      .icon-button {
        visibility: hidden;
        margin-left: 5px;

        &.active {
          visibility: visible;

          path {
            fill: #4da5b5;
          }
        }
      }
    }

    &:hover {
      background-color: #1e1e1e;

      .icon-button {
        visibility: visible;
      }
    }

    &:active,
    &.active {
      background-color: $BGColor5;

      .icon-button,
      .accordion-detail-item-icon {
        color: #fff;
        
        path:first-of-type {
          fill: #fff;
        }

        &.active {
          path {
            fill: #fff;
          }
        }
      }
    }

    .icon-button {
      &:hover {
        path {
          fill: #fff;
        }
      }
    }
  }
}
