@import 'mat/styles/includes';

.demo-org-management-page {
  padding: 23px 30px 10px 30px;

  .title {
    text-transform: none;
    .title-label {
      font-weight: normal;
    }
    > * {
      margin-right: 10px;
    }
  }
}
