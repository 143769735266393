@import "infrastructure/styles/theme";

.login-layout {
  $formInnerWidth : 309px;

  background: $BGColor2;
  text-align:center;
  .login-page-img-bg{
    //height:303px;
    background-image:url('../../../assets/images/login-bg.png');
    background-position:top center;
    background-repeat:no-repeat;
    background-size:cover;
  }

  @media screen and (max-width: $MobileMaxWidth) {
    .login-page-img-bg {
      height: 150px;
    }
    .login-body {
      width: 340px;
      height: 450px;
    }
    .login-body-inline {
      padding-bottom: 15px;
    }
  }
  @media screen and (min-width: ($MobileMaxWidth + 1px)) {
    .login-page-img-bg {
      height: 303px;
    }
    .login-body {
      width: 470px;
      height: 580px;
    }
    .login-body-inline {
      padding-bottom: 50px;
    }
  }
  .login-body {
    text-align:left;
    position:relative;
    margin: -135px auto 15px auto;
    background-color: $BGColor20;
    .login-top-gradient{
      display: block;
      height: 8px;
      background-image: linear-gradient(120deg, $BGColor5 0%, $BGColor5 82%, $BGColor13 82%, $BGColor13 90%, $BGColor19 90%, $BGColor19 100%);
    }

    .login-body-inline {
      padding-top: 40px;
      text-align: center;
      border: 1px solid $BorderColor3;
      border-top: 0px solid transparent;

      .logo {
        text-align: center;
      }
    }
  }


  .form {
    padding-top: 40px;
    text-align: center;
    display: inline-block;
    .input-section{
      margin-top: 20px;

    }

    .login-row{
      margin-top: 20px;
      text-align: left;

      label {
        color: $Color7;
        font-size: 12px;
        line-height: 30px;
        font-weight: 400;

        &.textfield-label{
          display: block;
          text-transform: uppercase;
        }
      }

      .PL-textField input {
        border-color: $BorderColor12;
      }

      &.checkbox-row {
        margin-top:15px;

        .checkboxField {
          font-size: 14px;
          >*{
            vertical-align: middle;}
        }

        .pl-checkbox{
          margin-right: 10px;
        }
      }

    }
  }


  .btn.btn-primary {
    display: inline-block;
    margin-top: 17px;
    width: $formInnerWidth;
  }

  .btn.btn-default {
    margin-top: 17px;
    width: 100%;
    font-weight: 500;
  }

  .footer {
    margin-top: 25px;
    display: inline-block;
    width: $formInnerWidth;

    a {
      color: $FontColor5;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
    }

    .links-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .or-divider {
      display:flex;
      justify-content:center;
      align-items: center;
      color: #a6a6a6;
      margin-top: 20px;

      span {
        margin: 0 10px;
      }
  
      &::after, &::before {
        content: "";
        display: block;
        background: #a6a6a6;
        width: 100%;
        height:1px;
      }
    }
  }
}
