@import "infrastructure/styles/generic";
@import "infrastructure/styles/theme";

.pl-tabs {

  &.nav-tabs {
    border-bottom: 1px solid $BorderColor6;

    li[role=presentation] {
      &:first-child {
        button {
          border-left-width: 1px;
        }
      }

      button {
        border:  1px solid  $BorderColor6;
        border-left-width: 0px;
        border-radius: 0px;

        background-color: $BGColor2;
        height: 32px;
        min-width: 90px;
        margin-right: 0px;

        text-align: center;
        font-family: $FontFamily1;
        color: $Color2;
        font-size: 12px;
        line-height: 12px;
        font-weight: 900;
        text-transform: uppercase;

        &:hover {
          background-color: $BGColor11;
          color: $FontColor6;
        }
        &.active {
          background-color: $BGColor11;
          color: $FontColor6;
          border-bottom: 0 solid transparent;
        }
      }

      & {
        button:active {
          background-color: $BGColor11;
          border-bottom: 0 solid $BGColor2;

          &:hover {
            background-color: $BGColor11;
            color: $Color2;
          }
        }
      }

      &:has(> button.disabled) {
        button {
          background-color: $BGColor2;
          color: $FontColor12;
          border-top-color: $BorderColor8;
          border-right-color: $BorderColor8;
        }
      }
    }


  }
  .tab-pane{background-color:$BGColor11;}
}

.pl-topbar-tabs {
  $fontColorRegularTab:  $FontColor11; // #849095;
  $fontColorActiveTab:   $FontColor13;
  $fontColorSelectedTab: $FontColor4;

  $tabHeight: 55px;
  $borderWidth: 4px;
  $finalTabHeight: calc(#{$tabHeight} + #{$borderWidth});


  $zIndex: 5; // Relative z-index for this component.

  + .tab-content {
    height: calc(100% - 59px);
    margin: 0 30px;

    .tab-pane.active {
      height: 100%;
    }
  }


  @mixin topbar-tabs-bottom-border($borderColor) {
    $borderWidthForTriangular1: calc(#{$borderWidth} + 1px);
    $borderWidthForTriangular2: calc(#{$borderWidth} + 2px);

    border-bottom: $borderColor solid $borderWidth;

    // Border Triangle Pin
    &:after {
      content: '';
      display: block;
      position: absolute;
      border-color: $BGColor2 transparent;
      border-style: solid;
      border-width: $borderWidthForTriangular1 $borderWidthForTriangular1 0;
      bottom: -$borderWidth;
      left: calc(0px - #{$borderWidthForTriangular1} );
      margin-left: 50%;
      width: 0;
    }
    // Border Triangle Inner Highlight
    &:before {
      content: '';
      display: block;
      position: absolute;
      border-color: $borderColor transparent;
      border-style: solid;
      border-width: $borderWidthForTriangular2 $borderWidthForTriangular2 0;
      bottom: calc(0px - #{$borderWidth} - #{$borderWidthForTriangular2});
      left: calc(0px - #{$borderWidthForTriangular2} );
      margin-left: 50%;
      width: 0;
    }
  }

  &.nav-tabs {
    border-width: 0;

    li[role=presentation] {
      position: relative;
      z-index: $zIndex;

      min-width: 133px;
      height: $finalTabHeight;
      padding: 0;
      background: $BGColor2;
      border-width: 0;
      font-weight: 400;

      &:has(> button.wide) {
        min-width: 160px;
      }

      button {
        width: 100%;
      }

      &:hover, &:active, &:focus {
        outline:none;
      }
      border-bottom: $BorderColor23 solid $borderWidth;
      &:after {
        content: '';
        display: block;
        border-top: 1px solid $BorderColor16;
        width: 100%;
      }

      button {
        font-size: 16px;
        line-height: $tabHeight;
        color: $fontColorRegularTab;
        border-width: 0;
        border-radius: 0;
        padding: 0;
        text-align: center;
        vertical-align: middle;
        margin-right:0;
        text-transform: uppercase;

        &:hover, &:focus, &:active {
          background: $BGColor2;
          border-width: 0;
        }
        &:hover, &:active, &:focus{outline:none;}
      }

      &:hover {
        @include topbar-tabs-bottom-border($BorderColor4);

        button {
          color: $fontColorSelectedTab;
          width: 100%;

          &:after {
              content: '';
              display: block;
              border-top: 1px solid $BorderColor16;
              width: 100%;
          }
        }
      }


      &.active ,  &:active , &:hover {
        button {
          font-weight: 900;
        }
      }

      &:active ,  &.active {  // the pressed tab (while clicking)
        button {
          color: $fontColorActiveTab;
        }
      }

      &:active, &:has(> button.active) {  // :active - the pressed tab (while clicking)
        @include topbar-tabs-bottom-border($BorderColor13);
      }

      &.active,  &:has(> button.active) {  // .active - the current active (selected) tab
        & , &:hover {
          @include topbar-tabs-bottom-border($BorderColor15);

          button:after {
            content: none;
          }
        }
      }

      &.disabled {
        button {
          color: $FontColor12;
        }
        &:active , &:hover {
           button {
             font-weight: 400;
           }

           &:after ,  &:before {
             content: none;
           }
        }
      }
    }
  }
}

.pl-content-tabs {

  $zIndex: 4; // Relative z-index for this component.
  $zIndexBackgroundStrip: $zIndex;
  $zIndexButtons:         $zIndex + 1;


  //background-color: $BGColor28;
  height: 45px;

  + .tab-content {
    height: calc(100% - 45px);
  }

  &:before {
    content: '';
    background-color: $BGColor28;
    display: block;
    position: absolute;
    left: 0;
    z-index: $zIndexBackgroundStrip;
    width: 100%;
    height: 45px;
  }

  // Bottom tab underline mixin.
  @mixin content-tabs-bottom-border($borderColor) {
    &:after {
      content: '';
      display: block;
      position: relative;
      width: 100%;
      height: 3px;
      background-color: $borderColor;
      border-top: 1px solid $BorderColor21;
    }
  }


  &.nav-tabs {
    border: none; background-color: transparent;

    >li[role="presentation"] {
      margin-left: 15px; margin-right: 15px; // SPACED BUTTONS
      &:first-child{margin-left:0;}
      z-index: $zIndexButtons;
      outline: none;
      &:hover, &:active, &:focus{outline:none;}
      button {

        border: none; background-color: transparent;
        border-radius: 0; margin: 0; outline: 0;

        color: $FontColor17;
        line-height: 25px; // (45 -10 -10)

        // padding-left: 30px; padding-right: 30px; // FULL WIDTH BUTTONS
        padding-left: 15px; padding-right: 15px; // SPACED BUTTONS



        span.tab-icon {
          margin-left: 0; font-size: 17px; color: $FontColor17;
          position: relative; top: 5px;
        }
        span.tab-counter {
          margin-left: 9px; font-size: 18px; color: $FontColor17;
          position: relative; top: 2px;
        }
        span.tab-text {
          margin-left: 5px; font-size: 15px; color: $FontColor17;
          position: relative; top: 2px;
        }
      }

      &:hover {
        // hover li bottom border
        @include content-tabs-bottom-border($BorderColor21);
      }


      &.active, &:has(> button.active) {
        // active li bottom border
        @include content-tabs-bottom-border($BGColor1);


        // links inside active li.
        button {
          border: none; background-color: transparent;
          outline: 0;
          color: $FontColor17;
          line-height: 25px; // (45 -10 -10)
        }

      }
      &:hover, &:active, &:focus{outline:none;}
    } // END li
  }
} // END .pl-content-tabs

.wizard-dialog-body {
  .pl-wizard-sidebar-tabs {

    &.pad {
      padding: 29px;
    }

    &.pad-inner {
      > ul.nav-tabs {
        margin-right: 15px;
      }

      > div.tab-content {
        width: calc(77% - 15px);
      }
    }

    height: 100%;
    //tab group
    > ul.nav-tabs {
      height: 100%;
      width: 23%;
      display: inline-block;

      padding-right: 2px;
      border-color: $Color2;
      border-width: 0 2px 0 0;
      border-style: solid;

      overflow-y: auto;

      //all tabs
      li[role=presentation] {

        width: 100%;
        margin: 5px 0;

        //link for all tabs
        > a {
          background-color: transparent;
          border: none;
          color: black;

          &::before {
            display: inline-block;
            content: url("infrastructure/assets/svg/rhombus_empty.svg");
            margin-right: 3px;
            transform: translateY(2px);
          }
        }

        &.completed > a::before {
          content: url("infrastructure/assets/svg/rhombus_checked.svg");
        }

        //active tab
        &.active {

          //link for active tab
          > a {
            font-weight: bold;

            &::before {
              content: url("infrastructure/assets/svg/rhombus_filled.svg");
            }
          }
        }

        //marked as filled
        &.filled > a::before {
          content: url("infrastructure/assets/svg/rhombus_filled.svg");
        }
      }
    }

    //tabs content container
    > div.tab-content {
      display: inline-block;
      vertical-align: top;
      width: 77%;
      height: 100%;
      overflow-y: auto;
    }
  }

  .pl-wizard-horizontal-tabs {

    &.nav-tabs {
      flex-shrink: 0;
      width: 100%;
      overflow: auto hidden;
      background-color: white;
      border-bottom: 2px solid $Color2;
      margin-bottom: 1px;

      li[role=presentation] {
        position: relative;

        &::before {
          position: absolute;
          height: 0;
          width: 45.5%;
          border-top: black 1px solid;
          content: " ";
          top: 17px;
          left: 0;
        }

        &::after {
          position: absolute;
          height: 0;
          width: 45.5%;
          border-top: black 1px solid;
          content: " ";
          top: 17px;
          right: 0;
        }

        &:first-child {
          &::before {
            display: none;
          }
        }

        &:last-child::after {
          display: none;
        }

        > button {
          background-color: transparent;
          border: none;
          color: black;
          z-index: 1;
          position: relative;

          &::before {
            z-index: 1;
            display: block;
            content: url("infrastructure/assets/svg/rhombus_empty_white.svg");
          }
          &.active {
            color: $Color2;
            font-weight: bold;

            &::before {
              content: url("infrastructure/assets/svg/rhombus_filled.svg");
            }
          }
        }

        &.completed > button::before {
          content: url("infrastructure/assets/svg/rhombus_checked.svg");
        }
      }
    }
  }

  .tab-content {
    flex-grow: 1;
    vertical-align: top;
    width: 100%;
    overflow-y: auto;
    height: 100%;

    > div[role=tabpanel].tab-pane.active {
      height: 100%;
    }
  }
}

.pl-form-tabs {
  margin-bottom: 35px;
  border: none;

  .nav-link {
    color: unset;
    border: none;
    padding-bottom: 0;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;

    &.active {
      border-bottom: 3px solid #4DA5BA;
    }

    &.disabled {
      color: #c7c7c7;
    }
  }
}

.pl-tabs-container {
  height: 100%;

  .pl-tabs-header {
    width: 100%;
    display: flex;
  }

  &.tabs-overflow {

    .pl-tabs {
      flex-wrap: nowrap;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      border: none;

    }
    
    &.overflow-active {
      .pl-tabs {        
        .nav-item:last-child .nav-link {
          border-right: none;
        }
      }
    }

    .pl-tabs-buttons {
      flex: 1 0 auto;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #4ea5b5;
      border-left: 1px solid #4ea5b5;
      padding-right: 14px;
    }

    .nav-item {
      border-bottom: 1px solid #4ea5b5;
      flex: 1 0 auto;
    }

    .tab-content {
      width: 100%;
    }

  }

  .tabs-scroll-buttons {

    button {
      background-color: #fff;
      border: none;
    }
  }
}