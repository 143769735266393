@import 'infrastructure/styles/theme';

.manager-view-page {
  display: flex;
  position: relative;
  height: 100%;

  .manager-view-page__tree {
    min-width: 340px;
    display: flex;
    flex-direction: column;
    background-color: $BGColor16;

    .title {
      padding: 15px 10px 10px 10px;
      font-weight: 700;
      font-size: 18px;
      text-transform: uppercase;
    }
  }
  .manager-view-page__details {
    flex: 1 0;
    position: relative;
  }
}
