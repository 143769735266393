
.promotions-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 18px;

  .input-section {
    display: inline-flex  ;
    align-items: center;
    .PL-textField {
      width: 60px;
      margin: 0 10px;
    }
    label {
      margin: 0;
    }
  }
}
