@import "mat/styles/includes";

.create-reason-dialog {

  .modal-dialog {width: $dialog2ColumnsWidth;}

  .modal-body {
    .PL-textField > input {
      padding-right: 30px;
    }
  }
}
