@import '../../../../../mat/styles/includes';
@import '../../../../styles/theme';

$DateBoxMargin: 5px;
.date-range-popover-container {
    width: 100%;
}

.pl-date-range-popover {
    max-width: unset;

    .popover-content {
        max-height: unset;
        padding: 5px 0 ;
    }

    .date-range-popover-content {
        display: flex;
        flex-flow: column;

        > .date-pickers-wrapper {
            display: flex;
        }

        > .date-range-popover-buttons {
            display: flex;
            justify-content: flex-end;
            padding-right: 20px;

            > .btn {
                height: 25px;
                line-height: 25px;
            }
        }

        .pl-datepicker {
            height: unset;

            .react-datepicker__time-container
                .react-datepicker__time
                .react-datepicker__time-box
                ul.react-datepicker__time-list {
                padding: 0;
            }

            .react-datepicker .react-datepicker__month-select,
            .react-datepicker .react-datepicker__year-select {
                height: 24px;
                line-height: 24px;
            }

            .react-datepicker {
                border: none;
                box-shadow: none;
                border-radius: 0;
                padding: 0 20px 0;

              .react-datepicker__navigation {
                &.react-datepicker__navigation--next, &.react-datepicker__navigation--previous {
                  margin-top: -5px;
                }
                &.react-datepicker__navigation--next {
                  right: 90px;
                }
              }

              .react-datepicker__month-container {
                .react-datepicker__header {
                  .react-datepicker__header__dropdown {
                    padding: 0 20px;
                    .react-datepicker__month-select, .react-datepicker__year-select {
                      width: 115px;
                    }
                  }
                  .react-datepicker__day-name {
                    margin: 0 3px;
                  }
                }
                .react-datepicker__month {
                  .react-datepicker__day, .react-datepicker__day-name {
                    margin: 0 3px;
                  }

                }
              }
            }
        }

        .datepicker-input-container {
            display: block;
            width: 240px;
            background-color: $BGColor12;
            padding-bottom: 2px;
            margin: 5px 0;

            input {
                height: 27px;
            }

            span {
                font-family: $FontFamily1;
                font-size: $FontSize3;
                font-weight: bold;
                margin-left: $DateBoxMargin;
            }

            .react-datepicker__close-icon {
              margin-top: 1px;
            }

          .invalid .invalid-icon {
            right: 60px;
            align-items: self-start;
            margin-top: 3px;
          }

            .pl-datepicker {
                margin: 0 2px;
            }
        }

        .picker-container {
            display: flex;
            flex-flow: column;
            align-items: center;
            margin-right: 3px;
        }

        .picker-container:first-child {
            border-right: 1px solid #aeaeae;
        }

        .picker-container:nth-child(2) {
            border-left: 1px solid #aeaeae;
        }
    }

    .d-none {
        display: none;
    }
}
