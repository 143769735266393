@import "infrastructure/styles/theme";


.PL-phoneField {
  display: inline-block;
  height: 36px;
  vertical-align: top;
  .react-tel-input .form-control {
    border: 1px solid rgba(136, 144, 148, 0.26);
    box-sizing: border-box;
    background-color: #ffffff;
    width: inherit;
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    color: #707070;
    height: 36px;
    padding-left: 42px;

    &:focus, &:active {
      border-color: $BorderColor6;
      outline: none;
    }
    .flag-dropdown {
      border: none;
    }
  }
}
