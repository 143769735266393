@import "infrastructure/styles/generic";
@import "mat/styles/includes";

.assets-to-asset-section {

  .using-asset-section {
    .entities-multi-select {
       flex-direction: row !important;
       .entity-type-select {
         margin: 0 !important;
       }
     }
  }

  .mark-as-consumed-section {
    label.checkbox-label {
      margin-left: 5px;
    }
  }

  .assets-to-asset-selection {
    width: 100%;
  }
  .assets-to-asset-selection, .using-asset-selection {
    .select-row.select-value-row {
      .column {
        height: 20px;
        line-height: 20px;
      }
    }
  }
  .assets-to-asset-selection {
    .select-value-row .column.extra-data {
        max-width: 130px;
    }
  }
  .using-asset-selection {
    .select-value-row .column.extra-data {
      max-width: 60px;
    }
  }
}
