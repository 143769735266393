@import "mat/styles/includes";

.kit-type-location-list-item {
  @include flex-row();
  align-items: center;
  margin-right: 40px;

  .column {
    width: 100px;
    &.column-1 {
      text-align: center;
    }

    &.column-2 {
      flex-grow: 1;
      line-height: 1pc;
      //&.pl-combobox .pl-select__multi-value .pl-select__multi-value__label {
      //  width: 150px;
      //}
    }
  }


  .pl-combobox .pl-select__menu {
    .pl-select__menu-list {
      max-height: 150px;
    }
  }
}






