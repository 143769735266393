
.work-order-async-editor {
  width: 210px;
  .single-line-multi-select {
    .selected-options {
      width: 100%;
    }

    .single-line-multi-select-item {
      align-items: normal;
      min-width: 0;

      .item-name {
        white-space: nowrap;
        width: calc(100% - 10px);
      }
    }
  }
}
