.cutting-grid {
  height: calc(100% - 53px);
  margin-top:18px;

  .ag-cell {
    line-height: 24px !important;
    padding: 20px;
  }
}




