@import "mat/styles/includes";

.create-task-dialog {

  .modal-dialog {width: $dialogBig1ColumnWidth;}

  .modal-body {
    .time-value-wrapper{
      width: calc(40px + 35px + 40px);
    }
  }
}
