@import '../../../../../../styles/includes';

.shift-settings-dialog {
    .body-header {
        font-weight: bold;
        color: $FontColor4;
    }

    .short-textfield {
        width: 50px;
    }

    .shift-settings-inputs-wrapper {
        display: flex;
      align-items: flex-end;
    }

  .cycle-scheduling-section {
    margin-top: 10px;
    .cycle-scheduling-item-index {
      margin-right: 10px;
    }
    .time-value-wrapper{
      width: 105px;
    }
  }
}
