@import "mat/styles/includes";

.create-reader-dialog {

  .modal-dialog {width: $dialog2ColumnsWidth;}

  .modal-body {
    .input-section {
      margin-top: 0;
    }
  }

  .reader-params-section{
    .input-section.inline.small {
      width: 206px;
      font-size: 14px;

      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }


  .instructions-textField {
    width: 100%;
  }

  .short-textfield {
    max-width: 170px;
  }

  .antennas-wrapper {

    width: 100%;
    margin-top: 15px;

    .antennas-header {
      height: 35px;
      background: #ddd;;
      font-size: 15px;
      padding-right: 30px;
      margin: 20px 0 10px 0;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      label {
        margin-left: auto;
        margin-right: 10px;
      }
      label:last-of-type {
        margin-left: 20px;
      }
      span{
        padding-right: 5px;
      }

      .popover-target .alert-icon {
        line-height: 21px;
      }

    }

    .add-remove-list-container .add-remove-list {
      padding: 0 0 5px 0;
    }

    .antennas-content {

    }
  }
}
