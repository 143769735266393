@import "mat/styles/includes";

.print-rfid-tags-dialog {
  .modal-dialog {width: $dialog2ColumnsWidth;}

  .entities-multi-select .entity-type-select {
    width: 105px;
  }
  .custom-data {
    width: 125px;
  }
}
