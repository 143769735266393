@import "../../../../../../styles/includes";

.station-settings-form {
  height: 100%;

  .common-header {
    margin: 0 !important;
    border-bottom: 2px solid #eeeeee;
    .title {
      border: none;
    }
  }

  .dialog {
    height: calc(100% - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 5px;
  }

  .modal-body {
    display: block;
    padding: 0;
  }

  .input-section {
    input[type='checkbox'][disabled]{
      opacity: 0.9;
    }
  }

  .input-section.two-items-section {
      padding-top: 18px;
    > *:not(:last-child) {
      margin-right: 15px;
    }
    > * {
      width: calc(50% - 10px);
    }
    .over-time {
      width: 105px;
    }
    .cycle-duration {
      width: 155px;
    }
    &.resource-section {
      padding-top: 28px;
    }
    &.capacity-section {
      padding-top: 0;
      > label:first-child {
        display: none;
      }
    }
    &.section-with-checkbox {
      .pl-checkbox {
        margin-top: 30px;
      }
    }

  }

  .input-section .many-items-section {
    display: flex;
    label {
      line-height: 36px;
      margin-bottom: 0;
      &:not(:first-child) {
        margin-left: 30px;
      }
    }
    > * {
      flex: 1 0 0;
      &:nth-child(2) {
        margin-right: auto;
      }
    }
  }

    .list-operations-title {
      margin-top: 30px;
    }

    .add-remove-list-container .add-remove-list {
      .list-item:last-child {
        margin-bottom: 10px;
      }
      .operation-list-item {
        .pl-combobox {
          flex: 1;
        }
      }
    }

    .primary-operations-list {
      .pl-combobox {
        width: 100%;
      }
      .operation-list-item {
        margin-bottom: 10px;
        flex: 1;
      }
      .add-remove-list {
        width: calc(100% - 24px);
      }
    }

    .pl-combobox .pl-select__value-container {
      max-height: 85px;
      overflow-x: hidden;
      overflow-y: scroll;
    }
    .pl-select__multi-value__label{
      width: 100%;
    }
    .pl-select__multi-value{
      width: 90%;
    }
}
