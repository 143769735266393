@import "mat/styles/includes";


.report-cuts-dialog {
  .modal-dialog {width: $dialog2ColumnsWidth;}
  .modal-body{
   max-height: $modalBodyMaxHeight;
  }

  .explanation{
    width: 48% !important;
  }
  //to limit the input expanding when typing
  .pl-combobox .pl-select__control .pl-select__input > input {
    max-width: $comboboxInputWidth7;
  }

  .title > div {
    max-width: 305px;
    &:first-child {
      margin-right: 15px;
    }
  }

}


















