@import "mat/styles/includes";

.demolding-section {
  .select-row.select-value-row {
    .column:first-child {
      width: 200px;
    }
    .extra-data {
      width: 130px;
    }
    .custom-data {
      width: 160px;
    }
  }
}
