.mMapviewHoverLabel {
  background: rgba(255, 255, 255, .9);
  color: #333;
  padding: 10px;
  font-size: 14px;
  font-weight: 700;
  transition: opacity .3s;
  font-family: sans-serif
}

.mappedin-map {
  position: relative;
  overflow: hidden
}

.mappedin__floating-label {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 200px;
  height: 200px;
  will-change: transform;
  transition: opacity .3s linear
}

.mappedin__floating-label__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px
}

.mappedin__floating-label__icon>svg {
  position: absolute;
  top: -50%;
  left: -50%
}

.mappedin__floating-label__text_holder {
  font-family: InterUI-Regular, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-weight: 500;
  position: absolute;
  pointer-events: none;
  word-break: keep-all
}

.mappedin__floating-label__text_legacy {
  text-shadow: 1px 1px 0px #ffffff, -1px -1px 0px #ffffff, 1px -1px 0px #ffffff, -1px 1px 0px #ffffff
}

.mappedin__floating-label__text_back {
  -webkit-text-stroke: 4px white;
  color: #fff
}

.mappedin__floating-label__text_front {
  color: #000;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0
}

.mappedin-tooltip__wrapper {
  display: flex;
  width: 1px;
  height: 1px;
  align-items: center;
  justify-content: center;
  z-index: 1
}

.mappedin-tooltip {
  font-family: InterUI-Regular, Helvetica, Arial, sans-serif;
  font-weight: 400;
  top: 0;
  transition: transform .3s linear;
  font-weight: 700;
  color: #000;
  position: absolute;
  z-index: 1;
  transition: opacity .3s;
  display: flex;
  flex-direction: row;
  will-change: transform, opacity;
  justify-content: center
}

.mappedin-tooltip>div {
  z-index: 2;
  flex-shrink: 0;
  max-width: 500px;
  background: white;
  border-radius: 20px;
  box-shadow: 0 2px 11px #0003
}

.mappedin-tooltip:before {
  content: "";
  width: 0;
  height: 0;
  top: calc(50% - 10px);
  left: -10px;
  z-index: 1;
  position: absolute;
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent
}

.mappedin-tooltip.tooltip--anchor-top {
  left: 0;
  top: 0
}

.mappedin-tooltip.tooltip--anchor-top:before {
  left: calc(50% - 10px);
  top: calc(100% - 5px);
  right: auto;
  border-bottom: 10px solid transparent;
  border-top: 10px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent
}

.mappedin-tooltip.tooltip--anchor-bottom {
  left: 0;
  top: 0
}

.mappedin-tooltip.tooltip--anchor-bottom:before {
  left: calc(50% - 10px);
  top: -15px;
  right: auto;
  border-bottom: 10px solid white;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent
}

.mappedin-tooltip.tooltip--anchor-right {
  justify-content: flex-start;
  left: 0;
  top: 0
}

.mappedin-tooltip.tooltip--anchor-right:before {
  left: -5px;
  right: auto;
  border-right: 10px solid white
}

.mappedin-tooltip.tooltip--anchor-left {
  left: 0;
  top: 0
}

.mappedin-tooltip.tooltip--anchor-left:before {
  left: auto;
  right: -5px;
  border-left: 10px solid white
}

.mappedin-tooltip.tooltip--anchor-top-left {
  left: 7px;
  top: 7px
}

.mappedin-tooltip.tooltip--anchor-top-left:before {
  left: auto;
  right: -7px;
  top: calc(100% - 13px);
  border-bottom: 10px solid transparent;
  border-top: 10px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  transform: rotate(-45deg)
}

.mappedin-tooltip.tooltip--anchor-top-right {
  left: -6px;
  top: 7px
}

.mappedin-tooltip.tooltip--anchor-top-right:before {
  left: -7px;
  right: auto;
  top: calc(100% - 13px);
  border-bottom: 10px solid transparent;
  border-top: 10px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  transform: rotate(45deg)
}

.mappedin-tooltip.tooltip--anchor-bottom-left {
  left: 7px;
  top: -6px
}

.mappedin-tooltip.tooltip--anchor-bottom-left:before {
  left: auto;
  right: -7px;
  top: -7px;
  border-bottom: 10px solid white;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  transform: rotate(45deg)
}

.mappedin-tooltip.tooltip--anchor-bottom-right {
  left: -6px;
  top: -6px
}

.mappedin-tooltip.tooltip--anchor-bottom-right:before {
  left: -7px;
  right: auto;
  top: -7px;
  border-bottom: 10px solid white;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  transform: rotate(-45deg)
}

.mappedin-tooltip.tooltip--visible {
  opacity: 1
}

.mappedin-tooltip.pointer-events-none {
  pointer-events: none
}

.mappedin-tooltip.pointer-events-auto {
  pointer-events: auto
}

.mappedin-marker {
  font-family: InterUI-Regular, Helvetica, Arial, sans-serif;
  font-weight: 400;
  pointer-events: none;
  top: 0;
  transition: transform .3s linear;
  position: absolute;
  font-weight: 700;
  color: #000;
  z-index: 1;
  transition: opacity .3s;
  display: flex;
  flex-direction: row;
  will-change: opacity;
  justify-content: center
}

.mappedin__smart-collision-engine__container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  transform-style: preserve-3d;
  z-index: 1;
  font-family: InterUI-Regular, Helvetica, Arial, sans-serif
}

.mappedin__smart-collision-engine-debug__container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transition: opacity .3s ease-in-out;
  pointer-events: none;
  will-change: opacity
}

.mappedin__smart-collision-engine-debug__container.interacting {
  opacity: .2
}

.outdoor-ctrl-attrib {
  display: block;
  position: absolute;
  visibility: visible;
  pointer-events: auto;
  font: 9px/11px InterUI-Regular, Helvetica, Arial, sans-serif;
  transform: translate(0);
  padding: 3px 20px 1px 5px;
  background-color: #ffffff80;
  margin: 0;
  min-width: 11px;
  min-height: 11px
}

.outdoor-ctrl-attrib.outdoor-ctrl-attrib-hide {
  display: none
}

.outdoor-ctrl-attrib-top-left {
  top: 0;
  left: 1px;
  float: left;
  border-bottom-right-radius: 4px
}

.outdoor-ctrl-attrib-top-right {
  top: 0;
  right: 1px;
  float: right;
  border-bottom-left-radius: 4px
}

.outdoor-ctrl-attrib-bottom-left {
  bottom: 0;
  left: 1px;
  float: left;
  border-top-right-radius: 4px
}

.outdoor-ctrl-attrib-bottom-right {
  right: 0;
  bottom: 1px;
  float: right;
  border-top-left-radius: 4px
}

.outdoor-ctrl-attrib-bottom-left,
.outdoor-ctrl-attrib-top-left {
  padding: 3px 5px 1px 20px
}

.outdoor-ctrl-attrib.outdoor-compact {
  background-color: transparent
}

.outdoor-ctrl-attrib.outdoor-compact-show {
  min-width: 15px;
  min-height: 15px;
  box-sizing: border-box;
  background-color: #ffffff80
}

.outdoor-ctrl-attrib-inner,
.outdoor-ctrl-attrib-inner a {
  color: #000000bf;
  text-decoration: none
}

.outdoor-ctrl-attrib-inner a:hover {
  color: #017ef1;
  text-decoration: underline;
  cursor: pointer
}

.outdoor-ctrl-attrib.outdoor-compact .outdoor-ctrl-attrib-inner {
  display: none
}

.outdoor-ctrl-attrib-button {
  display: none;
  cursor: pointer;
  position: absolute;
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill-rule='evenodd' viewBox='0 0 20 20'><circle cx='10' cy='10' r='5.9' style='fill:rgba(255,255,255,1);'/><path style='fill:rgba(0,0,0,0.7);' d='M4 10a6 6 0 1 0 12 0 6 6 0 1 0-12 0m5-3a1 1 0 1 0 2 0 1 1 0 1 0-2 0m0 3a1 1 0 1 1 2 0v3a1 1 0 1 1-2 0'/></svg>");
  background-size: 15px;
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  border-radius: 50%;
  outline: none;
  top: 0;
  right: 2px;
  border: 1px
}

.outdoor-ctrl-attrib summary.outdoor-ctrl-attrib-button {
  -webkit-appearance: none;
  appearance: none;
  list-style: none
}

.outdoor-ctrl-attrib summary.outdoor-ctrl-attrib-button::-webkit-details-marker {
  display: none
}

.outdoor-ctrl-attrib-top-left .outdoor-ctrl-attrib-button,
.outdoor-ctrl-attrib-bottom-left .outdoor-ctrl-attrib-button {
  left: 2px
}

.outdoor-ctrl-attrib.outdoor-compact .outdoor-ctrl-attrib-button,
.outdoor-ctrl-attrib.outdoor-compact-show .outdoor-ctrl-attrib-inner {
  display: block
}