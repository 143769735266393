@import "mat/styles/includes";

.entity-range-list-item {
  @include flex-row();
  align-items: center;

  .column {
    margin-left: 15px;

    &:first-child {
      width: 30px;
    }

    .short-textfield {
      width: 60px
    }

  }

  .pl-combobox {
    .more-options {
      background: #e9ebed;
      line-height: 35px;
      font-weight: bold;
      cursor: default;
      //margin: 0 -20px 10px -20px;
      padding-left: 5px;
    }
    .pl-select__menu {
      .pl-select__menu-list {
        max-height: 150px;
      }
    }
  }
}






