@import "infrastructure/styles/theme";

.circle-progress-bar {

  .circle-background {
    stroke: #ddd;
    fill: none;
  }

  .circle-progress {
    fill: none;
    stroke: $IconColor5;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

}
