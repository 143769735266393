.date-range-view {
  display: grid;
  grid-template-columns: auto auto;
  justify-items: end;
  column-gap: 8px;
  width: 120px;
  height: 35px;

  margin-right: 26px;
  font-size: 12px;
  color: #333;
  text-transform: capitalize;

  span {
    font-weight: bold;
  }
  &.single-date {
    font-size: 18px;
  }
}
