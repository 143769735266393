@import "mat/styles/includes";

.edit-preference-dialog{
  .modal-dialog {
    width: $dialog2ColumnsWidth;
  }
  .org-default-value{
    overflow-wrap: break-word
  }
  .PL-textAreaField {
    height: 250px;
  }
}
