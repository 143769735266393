

.collapse-root {
  //display: flex;
  //flex-flow: column;
  margin: 10px 0;

  .collapse-header {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    max-width: 270px;

    .collapse-indicator {
      font-size: 18px;
      margin-right: 10px;
      transition: transform 100ms ease-out;
      cursor: pointer;
      color: #4EA5B5;

      &.in {
        transform: rotate(90deg);

      }
    }

    .title {
      font-size: 18px;
      line-height: 18px;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      user-select: none;
    }
  }

}
