@import 'infrastructure/styles/theme';

.pl-grid {
  .multi-sections-filter {
    width: 250px;

    .selectAll{
      margin-top: 0px;
    }

    .filter-body {

      span{
        margin-top: 10px;
        display: inline-block;
      }

      .pl-checkbox {
        display: block;
        margin: 5px 0px;
      }
    }

    .filter-footer {
      margin-top: 20px;
    }
  }
}

