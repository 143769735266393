@import 'infrastructure/styles/theme';

.login-page {
  .logo {
    margin-bottom: 70px;
  }

  .marketing-layout-left {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  .login-form {
    width: 274px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .input-section {
      margin-top: 20px;
    }

    .login-row {
      width: 100%;

      &:not(:first-child) {
        margin-top: 24px;
      }
      
      text-align: left;

      label {
        color: $Color7;
        font-size: 12px;
        line-height: 30px;
        font-weight: 400;

        &.textfield-label {
          display: block;
          text-transform: uppercase;
        }
      }

      .PL-textField {
        width: inherit;
        input {
          border: 1px solid #acacac;
          border-radius: 8px;
          height: 36px;
        }
      }

      &.checkbox-row {
        margin-top: 15px;

        .checkboxField {
          font-size: 14px;
          > * {
            vertical-align: middle;
          }
        }

        .pl-checkbox {
          margin-right: 10px;
        }
      }
    }

    .footer {
      width: 100%;

      margin-top: 25px;
      display: inline-block;

      a {
        color: $FontColor5;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 12px;
        font-weight: 500;
      }

      .links-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .or-divider {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #a6a6a6;
        margin-top: 20px;

        span {
          margin: 0 10px;
        }

        &::after,
        &::before {
          content: '';
          display: block;
          background: #a6a6a6;
          width: 100%;
          height: 1px;
        }
      }
    }

    .login-error,
    .sso-error {
      text-align: left;
      height: 0;
      opacity: 0;
      color: $FontColor20;
      transition: margin 0.3s, height 0.3s, opacity 0.3s;
    }

    .btn.btn-primary {
      transition: margin 0.3s;
      margin-top: 30px;
      width: 100%;
      border-radius: 17px;
    }

    .btn.btn-default {
      margin-top: 25px;
      width: 100%;
      // font-weight: 500;
      border-radius: 17px;
    }

    .sso-button {
      height: 35px;
      color: #D71775;
      border: 2px solid #D71775;

      &:hover {
        background-color: #D71775;
        color: white;
      }

      &:active {
        background: linear-gradient(90deg, #C73354, #A5005B);
      }
    }

    &.has-error {
      .login-error {
        margin-top: 17px;
        height: auto;
        opacity: 1;
      }
    }

    &.has-sso-error {
      .sso-error {
        margin-top: 17px;
        height: auto;
        opacity: 1;
      }
    }
  }
}
