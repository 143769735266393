@import "mat/styles/includes";

.edit-pm-terminology-dialog{
  .modal-dialog {
    width: $dialog2ColumnsWidth;
    .value-container {
      @include flex-row();
    }

  }
  .org-default-value{
    overflow-wrap: break-word;
    display: block;
    padding: 6px 24px 6px 0;
    border: 1px solid transparent;
  }

  .key-value {
    overflow-wrap: break-word;
  }
  .PL-textAreaField {
    height: calc(100% - 10px);
    width: 100%;
  }
}
