@import "mat/styles/includes";

.new-kittype-material-list-item {
  @include flex-row();
}





