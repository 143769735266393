@import "infrastructure/styles/theme";

.kitting-header{
  .title{
    font-size: 14px;
    color: $FontColor11;
    margin-top: 5px;
    line-height:26px;
    //&.second-line{
    //  margin-top:6px;
    //}
  }
  .cuts-dropdown{
    $dropdownWidth: 800px;
    margin-left:13px;
    width: $dropdownWidth;
  }
  .pl-select__menu{
    width: 100%;
  }

  .select-reported-cut{
    margin-top: 20px;
    display: block;
  }
  .title-second-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .cut-data-row{
    //padding: 7px 8px 6px;

    >label > span{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
    }
    span {
      display: inline-block;
      color: $FontColor15;
    }

    .material {
      width: 200px;
      //vertical-align: middle;
      //span {
      //  vertical-align: middle;
      //  color: $FontColor10
      //}
    }

    .roll-business-id, .cut-id, .time {
      margin-left: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
    }
    .roll-business-id {
      width: 200px;
    }
    .cut-id {
      width: 200px;
    }
    .time {
      width: 120px;
    }

    .show-all-option {
      cursor: default;
      color: $FontColor5;
      &.disabled {
        color: $FontColor12;
      }
    }
  }

  .pl-select__option .cut-data-row .time{
    width: 115px;
  }

}

