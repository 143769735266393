@import "mat/styles/includes";

.wizard-dialog {

  .wizard-dialog-body {
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  .import-files-common-grid {
    height: 345px;
  }
}
