@import "mat/styles/includes";

.operation-status-cell  {
  @include flex-row();
  align-items: center;
  width: 100%;

  .operation-status-text {
    margin-left: 10px;
  }

  .alert-icon{
    display: inline-block;
    vertical-align: baseline !important;
    font-size: 16px;
  }
}
