@import "infrastructure/styles/theme";

.smart-selection-more-info-custom-cell {

  .pl-warning-icon{
    line-height: 2;
    overflow: unset !important;
  }
  label {
    @include flex-row();
    color: $FontColor16;

    :first-child {
      margin-left: 5px;
      vertical-align: top;
      line-height: 31px;
    }

    &:not(:first-child) {
      :first-child {
        color: $FontColor17;
      }
    }
  }
}
